import { mathutils } from "../mathutils";
import { PlanLayout } from "./PlanLayout";


export class PlanPointBase {

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.z = 0;
  }

  protected x: number;
  protected y: number;
  protected z: number;

  public get X(): number {
    return this.x;
  }

  public get Y(): number {
    return this.y;
  }

  public get Z(): number {
    return this.z;
  }

  public ToString() {
    return mathutils.round(this.x, 1) + " x " + mathutils.round(this.y, 1);
  }
}

export class RealPoint extends PlanPointBase {

  constructor(x: number, y: number) {
    super(x, y);
  }

  public GetPlanPoint(layout: PlanLayout): PlanPoint {
    return new PlanPoint(
      this.x * layout.real_ratio.X,
      this.y * layout.real_ratio.Y);
  }
}

export class PlanPoint extends PlanPointBase {

  constructor(x: number, y: number) {
    super(x, y);
  }

  public GetZoomPoint(layout: PlanLayout): ZoomPoint {
    return new ZoomPoint(
      this.x * layout.zoom_ratio + layout.zoom_shift.X,
      this.y * layout.zoom_ratio + layout.zoom_shift.Y);
  }

  public GetRealPoint(layout: PlanLayout): RealPoint {
    return new ZoomPoint(
      this.x / layout.real_ratio.x,
      this.y / layout.real_ratio.y);
  }
}

export class ZoomPoint extends PlanPointBase {

  constructor(x: number, y: number) {
    super(x, y);
  }

  public GetPlanPoint(layout: PlanLayout): PlanPoint {
    return new PlanPoint(
      (this.x - layout.zoom_shift.x) / layout.zoom_ratio,
      (this.y - layout.zoom_shift.y) / layout.zoom_ratio)
  }
}

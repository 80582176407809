import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";

import { TranslationService } from "../../services/translation.service";
import { dateutils } from '../dateutils';
import { logicutils } from "../logicutils";
import { TimeFormat } from "../enums";


@Component({
  selector: "app-history-location",
  templateUrl: './history-location.component.html',
})
export class HistoryLocationComponent extends BaseWorkComponent {

  @Output() onSelectEvent = new EventEmitter();

  public is_admin: boolean;
  public rights: any;

  public event_list: any[] = [];
  private current_events: any[] = [];
  private current_event: any = null;

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any) {
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setItems(events: any[]) {
    if (!events || events.length == 0)
      return;
    let out_location_string = this.translation_service.translate("Out of location");
    let current_events = [];    
    for (let event of events) {
      let current_event = {
        out_location: event.out_location,
        sensor_last_update: event.sensor_last_update,

        building_id: event.out_location ? out_location_string : event.building_id,
        floor_id: event.out_location ? "" : event.floor_id,
        department_id: event.out_location ? "" : event.department_id,
        room_id: event.out_location ? "" : event.room_id,

        building_name: event.out_location ? out_location_string : event.building_name,
        floor_name: event.out_location ? "" : event.floor_name,
        department_name: event.out_location ? "" : event.department_name,
        room_name: event.out_location ? "" : event.room_name,
        coordinates: event.coordinates?.posX?.toFixed(1) + " x " + event.coordinates?.posY?.toFixed(1),
        event: event,
      };
      current_events.push(current_event);
    }

    let fields: string[] = this.is_admin ? ["building_id", "floor_id", "department_id", "room_id", "coordinates"] : ["building_id", "floor_id", "department_id"];

    this.current_events = current_events;
    let list = logicutils.getUniqueList(current_events, fields);
    if (list.length > 0)
      list[0]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(new Date(), list[0]["sensor_last_update"]));
    for (var i = 1; i < list.length; i++) {
      list[i]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(list[i - 1]["sensor_last_update"], list[i]["sensor_last_update"]));
      list[i - 1]["change_day"] = dateutils.formatLocale(list[i - 1]["sensor_last_update"], TimeFormat.None) != dateutils.formatLocale(list[i]["sensor_last_update"], TimeFormat.None);
      list[i - 1]["change_building"] = list[i - 1]["building_id"] != list[i]["building_id"];
      list[i - 1]["change_floor"] = list[i - 1]["floor_id"] != list[i]["floor_id"];
      list[i - 1]["change_department"] = list[i - 1]["department_id"] != list[i]["department_id"];
      if (this.is_admin) {
        list[i - 1]["change_room"] = list[i - 1]["room_id"] != list[i]["room_id"];
        list[i - 1]["change_coordinates"] = list[i - 1]["coordinates"] != list[i]["coordinates"];
      }
    }
    this.event_list = list;
  }

  public exportHistoric() {
    let name = this.getExportName("Historic");
    this.exportList(name);
  }


  public selectEvent(event) {
    if (this.current_event)
      this.current_event.selected = false;
    event.selected = true;
    this.current_event = event;
    this.onSelectEvent.emit(event);
  }

  public exportList(name) {
    let headers: string[] = this.is_admin ?
      ["Date", "Duration", "Building", "$Floor$", "Department", "Room", "Coordinates"] :
      ["Date", "Duration", "Building", "$Floor$", "Department"];

    let fields: string[] = this.is_admin ?
      ["sensor_last_update", "duration", "building_name", "floor_name", "department_name", "room_name", "coordinates"] :
      ["sensor_last_update", "duration", "building_name", "floor_name", "department_name"];

    let list = this.event_list;

    let records = logicutils.getCSV(this.translation_service, headers, list, fields);
    logicutils.downloadFileCsv(document, records, name);
  }

  private getExportName(label: string) {
    let last_event = this.current_events[this.current_events.length - 1];
    let now: string = dateutils.formatLocale(last_event["sensor_last_update"])
    return this.translation_service.translate(label) + " " + last_event["event"]["sensor_name"] + " " + now;
  }
}

<div [ngClass]="[initialized ? 'shown' : 'hidden']" class="dash-card-back dash-medium">
  <div *ngIf="!loaded" class="float-top-left-spinner">{{'Loading...'|locale}}</div>
  <div style="height: 200px;">
    <div id="chart_input_output_monitoring" class="dash-chart"></div>
  </div>
  <div *ngIf="loaded" style="display: flex; justify-content: space-between; padding-top: 3px">
    <div class="dash-trend-ticket" [style.color]=cards[0].color>
      <div class="dash-trend-label">{{ 'Day' | locale }}</div>
      <i class="fa fa-{{cards[0].icon}}" [style.color]=cards[0].color aria-hidden="true">&nbsp;&nbsp;{{cards[0].title}}</i>
    </div>
    <div class="dash-trend-ticket" [style.color]=cards[1].color>
      <div class="dash-trend-label">{{ 'Week' | locale }}</div>
      <i class="fa fa-{{cards[1].icon}}" [style.color]=cards[1].color aria-hidden="true">&nbsp;&nbsp;{{cards[1].title}}</i>
    </div>
    <div class="dash-trend-ticket" [style.color]=cards[2].color>
      <div class="dash-trend-label">{{ 'Month' | locale }}</div>
      <i class="fa fa-{{cards[2].icon}}" [style.color]=cards[2].color aria-hidden="true">&nbsp;&nbsp;{{cards[2].title}}</i>
    </div>
    <div class="dash-trend-ticket" [style.color]=cards[2].color>
      <div class="dash-trend-label">{{ '3 Months' | locale }}</div>
      <i class="fa fa-{{cards[2].icon}}" [style.color]=cards[2].color aria-hidden="true">&nbsp;&nbsp;{{cards[3].title}}</i>
    </div>
    <div class="dash-trend-ticket" [style.color]=cards[2].color>
      <div class="dash-trend-label">{{ '6 Months' | locale }}</div>
      <i class="fa fa-{{cards[2].icon}}" [style.color]=cards[2].color aria-hidden="true">&nbsp;&nbsp;{{cards[4].title}}</i>
    </div>
  </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, StaticClassProvider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';


import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

registerLocaleData(localeFr, localeFrExtra);
registerLocaleData(localeEn, localeEnExtra);
registerLocaleData(localeDe, localeDeExtra);
registerLocaleData(localeEs, localeEsExtra);


import { EntriesModule } from "./common/entries.module";
import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers/layout.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { ForgotPasswordComponent } from './views/authentication/forgot-password/forgot-password.component';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

// Import routing module
import { AppRoutingModule } from './app.routing';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ComponentsModule } from './common/components.module';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { TranslationService } from './services/translation.service';
import { EditorsModule } from './common/editors.module';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  toString() {
    return this.service.currentLang;
  }
}

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService]
};


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    EntriesModule,
    EditorsModule,
    ComponentsModule,
    DashboardModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    LocaleProvider,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    TranslationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
import { mathutils } from '../../../common/mathutils';
import { FindResult } from '../../../common/plan/common/FindResult';
import { PathVector } from '../../../common/plan/common/PathVector';
import { AngularPoint, Point2D } from '../../../common/plan/common/Point2D';
import { PointFloor } from '../../../common/plan/common/PointFloor';
import { FigureBase } from '../../../common/plan/FigureBase';
import { PlanComponent } from '../../../common/plan/plan.component';
import { IRoom, ISpot, SpotKind } from '../../../models/Spot';
import { DisplayBase } from './DisplayBase';
import { FindComponent } from './find.component';


export class PathDisplay extends DisplayBase {

  private static start: Point2D = new Point2D(200, 350);
  private static scale: number = 20;


  constructor(result: FindResult, plan: PlanComponent, floors: any[], rooms: any[]) {
    super(result, plan, floors, rooms);
  }


  protected override async OnDraw(index: number) {
    this.plan.Clear("map");
    let start = PathDisplay.start
    let prev_angle = 0;
    let entry: PathVector = this.Result.Entries[index];
    let floor_id: string = entry.start.floor_id;

    let angle = 0;
    for (var i = 0; i <= index; i++) {
      let entry: PathVector = this.Result.Entries[i];
      angle += entry.angle;
    }
    this.plan.Fill("green");

    this.DrawRooms(floor_id, entry, angle);

    for (var i = index; i < this.Result.Entries.length; i++) {
      let entry: PathVector = this.Result.Entries[i];
      // keep only the current floor
      if ((entry.start.floor_id != floor_id) && (entry.end.floor_id != floor_id))
        continue;

      let angle = i == index ? 0 : entry.angle + prev_angle;

      let pt: AngularPoint = new AngularPoint(angle - mathutils.Ang90, entry.distance * PathDisplay.scale);
      let target = start.Add(pt.GetCartesianPoint());
      //this.Adjust(target);

      // draws the specials spot (lift, stairs ...)
      this.DrawSpecial(entry.start_spot, start, floor_id, angle)
      this.DrawSpecial(entry.end_spot, target, floor_id, angle)

      if ((entry.start.floor_id != floor_id) || (entry.end.floor_id != floor_id))
        continue;
      this.plan.AddRealLine("map", start, target, "green", 4);
      prev_angle = angle;
      start = target;
    }
    this.plan.AddRealPoint("map", PathDisplay.start, FigureBase.LineColor, "yellow", 8);
    this.plan.Redraw();
  }

  private DrawSpecial(spot: ISpot, point: Point2D, floor_id: string, angle: number) {
    if (!spot || spot.floor_data != floor_id)
      return;
    if (spot.kind == SpotKind.Lift)
      this.plan.AddRealPoint("map", point, FigureBase.LineColor, "red", 16)
    if (spot.kind == SpotKind.Stair) {
      let points: Point2D[] = [];
      points.push(point.Shift(10, 10));
      points.push(point.Shift(10, -10));
      points.push(point.Shift(-10, -10));
      points.push(point.Shift(-10, 10));
      this.plan.AddRealPolygon("map", points, FigureBase.LineColor, "red")
    }
  }

  private DrawRooms(floor_id: string, entry: PathVector, angle: number) {
    let rooms = this.rooms.filter(f => f.floor_data == floor_id);
    if (!rooms)
      return;
    let start = PathDisplay.start;
    for (let room of rooms)
      this.DrawRoom(room, entry, angle, start);
  }

  private DrawRoom(room: IRoom, entry: PathVector, angle: number, start: Point2D) {
    let current: Point2D[] = [];
    for (let point of room.walls || []) {
      let relative: PointFloor = PointFloor.FromJson(point, room.floor_data).Substract(entry.start);
      let angular: AngularPoint = relative.GetAngularPoint();
      angular.angle -= angle + mathutils.Ang90;
      angular.distance *= PathDisplay.scale;
      let pt = angular.GetCartesianPoint().Add(start);
      current.push(pt);
    }
    this.plan.AddRealPolygon("map", current, FigureBase.FillColor, room.type != FindComponent.Corridor ? "gainsboro" : FigureBase.FillColor, room.name);
  }
}

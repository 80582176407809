
<div *ngIf="point && floors" style="padding: 4px; height: 28px; background: #CBE0F0;">

    <select [(ngModel)]="point.floor_id" (ngModelChange)="onValueChanged($event)" style="width:220px; font-size:10px; padding:2px 4px; float:left;">
      <option *ngFor="let record of floors" value={{record._id}}>{{record.name}}</option>
    </select>

  <input type="number" [(ngModel)]="point.x" (ngModelChange)="onValueChanged($event)" style="border: 1px solid; padding: 0px; width: 50px; height: 21px; margin: 0px 2px 0px 8px; text-align: right; font-size: 10px; float: left; " />
  <div style="float:left;">x</div>
  <input type="number" [(ngModel)]="point.y" (ngModelChange)="onValueChanged($event)" style="border: 1px solid; padding: 0px; width: 50px; height:21px; margin: 0px 0px 0px 2px; text-align: right; font-size: 10px; float: left; " />
  <app-list-button kind="Ctm" (click)="selectPoint();" text="" small="true" icon="map-marker-alt" style="float:right; margin-top:2px;"></app-list-button>



</div>

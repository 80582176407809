import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";
import { ItemKinds, Precision, PrecisionColor, PrecisionText } from "../../models/common";
import { AppConfig } from "../../app.config";
import { TranslationService } from "../../services/translation.service";
import { EventsListRibbonComponent } from './events-list-ribbon.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { logicutils } from "../logicutils";


@Component({
  selector: "app-events-list-list",
  templateUrl: './events-list-list.component.html'
})
export class EventsListListComponent extends BaseWorkComponent {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() NavigateLocation = new EventEmitter();
  @Output() NavigateHistory = new EventEmitter();

  public is_admin: boolean;
  public rights: any;
  public building_view: boolean;
  public details_mode: boolean = false;
  public header1_cols = 4;
  public header2_cols = 6;

  // current filters selection
  public filters = EventsListRibbonComponent.getFilters();

  public data_source: MatTableDataSource<any>;
  public records: any[] = [];
  public displayed_columns: string[];


  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translateService: TranslateService,
    protected translation_service: TranslationService) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any, building_view: boolean, filters: any) {
    this.is_admin = is_admin;
    this.rights = rights;
    this.building_view = building_view;
    this.filters = filters;

    if (!AppConfig.front_settings || !AppConfig.front_settings.views.use_inventory)
      this.details_mode = true;
    this.changeItemKind(filters.item_kind);

    const sort_state: Sort = { active: 'sensor_last_update', direction: 'desc' };
    this.sort.active = sort_state.active;
    this.sort.direction = sort_state.direction;
    this.sort.sortChange.emit(sort_state);
    this.data_source = logicutils.getListDataSource(this.records, this.paginator, this.sort, this.translateService);
  }

  public changeItemKind(item_kind: ItemKinds) {
    this.item_kind = item_kind;
    let displayed_columns = [];
    if (this.item_kind == ItemKinds.Sensor) {
      this.header1_cols = 3;
      this.header2_cols = 6;
      displayed_columns = ['sensor_name', 'sensor_state_label', 'equipment_department_name', 'building_name', 'floor_name', 'department_name', 'room_name', 'sensor_last_update', "precision_text", 'actions'];
    }else if (this.item_kind == ItemKinds.Sensor_and_equipment) {
      this.header1_cols = 5;
      this.header2_cols = 6;
      displayed_columns = ['sensor_name', 'equipment_name', 'equipment_kind_name', 'equipment_department_name', 'equipment_status', 'building_name', 'floor_name', 'department_name', 'room_name', 'sensor_last_update', "precision_text", 'actions'];
    } else if (this.item_kind == ItemKinds.Equipment) {
      this.header1_cols = 4;
      this.header2_cols = 6;
      displayed_columns = ['equipment_name', 'equipment_kind_name', 'equipment_department_name', 'equipment_status', 'building_name', 'floor_name', 'department_name', 'room_name', 'sensor_last_update', "precision_text", 'actions'];
    }
    // hide actions column
    if (!this.details_mode)
      displayed_columns.splice(displayed_columns.indexOf('actions'), 1);
    // hide flr column
    if (!this.building_view)
      displayed_columns.splice(displayed_columns.indexOf('floor_name'), 1);

    this.displayed_columns = displayed_columns;
  }

  public setItems(event_infos: any[]) {
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() - 30);
    //let x = 16;
    for (let event of event_infos) {
      event.row_color = "#000088";
      let precision = event.out_site ? Precision.OutOfSite : Precision.Recent;
      if (event.coordinates.out_of_range)
        precision = Precision.OutOfRange;
      event.precision_color = PrecisionColor[Precision[precision]];
      event.precision_text = this.translation_service.translate(PrecisionText[Precision[precision]]);
      // if we don"t have enough precision, we don't indicate department or room which would be hazardous
      if ("coordinates" in event && "type" in event.coordinates && (event.coordinates.type == 1 || event.coordinates.type == 2 || event.coordinates.out_of_range)) {
        event.department_name = "";
        event.room_name = "";
        event.coordinates.stacked = true;
      }
    }
    this.records = event_infos;
    this.data_source.data = this.records;
    this.data_source.paginator._intl.itemsPerPageLabel = '[' + this.translation_service.translate('Total') + ' : ' + event_infos.length + '] | ' + this.translation_service.translate('Items per page :');
  }

  public filterList(filters) {
    this.data_source.filterPredicate = ((data: any, filterValue: string) => {
      return EventsListRibbonComponent.matchFilter(filters, data,);
    });
    this.data_source.filter = "a";
    if (this.data_source.paginator)
      this.data_source.paginator.firstPage();
  }

  public getFilteredData(): any[] {
    return JSON.parse(JSON.stringify(this.data_source.filteredData));
  }

  // Events methods
  // **************

  protected NavigatedLocation(event) {
    this.NavigateLocation.emit(event);
  }

  protected NavigatedHistory(event) {
    this.NavigateHistory.emit(event);
  }

  // User interaction methods
  // ************************

  protected onNavigatedLocation(event) {
    this.NavigatedLocation(event);
  }

  protected onNavigatedHistory(event) {
    this.NavigatedHistory(event);
  }
}
import { Component, EventEmitter, Input, Output, inject, InjectFlags } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NgForm, NgModel } from '@angular/forms';


@Component({ template: '' })
export abstract class EditorBase<T> implements ControlValueAccessor {

  @Output() public Changed = new EventEmitter();

  protected form: NgForm | null = inject(NgForm, InjectFlags.Self | InjectFlags.Optional /*{ self: false, optional: true }*/);
  //protected model: NgModel | null = inject(NgModel, { self: false, optional: true });

  protected _disabled: boolean = false;
  protected _value: T | null = null;

  protected onChange: Function;
  private onTouched: Function;

  private static counter: number = 0;

  @Input()
  public label: string = "";
  @Input()
  public name: string = "editor_" + ++EditorBase.counter;
  @Input()
  public width: string | null = "";
  @Input()
  public label_width: string | null = "";
  @Input()
  public required: boolean = false;


  constructor() {
    this.onChange = (_: any) => { };
    this.onTouched = () => { };
  }


  get control(): AbstractControl<any, any> | null {
    return this.form?.controls[this.name] || null;
  }

  get required_error() {
    return !!this.control && this.control.touched && this.control.hasError('required');
  }

  get maxlength_error() {
    return !!this.control && this.control.touched && this.control.hasError('maxlength');
  }

  get minlength_error() {
    return !!this.control && this.control.touched && this.control.hasError('minlength');
  }

  get value() {
    return this._value;
  }
  @Input() set value(val) {
    if (this._value == val)
      return;
    this._value = val || null;
    this.onChange(val);
    this.onTouched();
    this.OnChange();
  }

  writeValue(val: T): void {
    if (this._value == val)
      return;
    this._value = val || null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this._disabled = disabled;
  }

  protected OnChange() {
    this.Changed.emit();
  }
}

import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { StorageFields } from '../common/enums';


@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnInit {

  token: string;

  constructor(private storage_service: StorageService){}

  ngOnInit() {
    this.token = this.storage_service.getValue(StorageFields.CurrentToken);//localStorage.getItem('currentToken');
  }

  getToken(): string {
    return this.storage_service.getValue(StorageFields.CurrentToken); //localStorage['currentToken'];
  }

  buildHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    if (this.getToken()) {
      headersConfig['authorization'] = `Bearer ${this.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

  buildBlobHeaders(): HttpHeaders {
    const headersConfig = {
      "Content-Type": "application/octet-stream",
      "Access-Control-Expose-Headers": "Content-Disposition"
    };
    if (this.getToken()) {
      headersConfig['authorization'] = `Bearer ${this.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

  buildUploadHeaders(): HttpHeaders {
    const headersConfig = {
    };
    if (this.getToken()) {
      headersConfig['authorization'] = `Bearer ${this.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router' ;
import { StorageFields } from '../common/enums';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor( private router: Router,
    private storage_service: StorageService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Un utilisateur est connecté
    if ( this.storage_service.getValue(StorageFields.CurrentUserId) && this.storage_service.getValue(StorageFields.CurrentToken) ){//localStorage.getItem('currentUserId') && localStorage.getItem('currentToken')) {
      return true ;
    }

    // Aucun utilisateur connecté
    this.router.navigate(['/login'],{queryParams:{'redirect_url':state.url}}) ;
    return false ;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class CriticalService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "critical";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * To find all critcal in database
   */
  getCriticals() {
    return this.getData("getCriticals", {}, (response) => { return response.data; });
  }

  /**
   * Request a specific critcal by id from API
   * @param critcal_id id of the critcal
   */
  getCritical(critical_id: string) {
    return this.getData("getCritical", { critical_id: critical_id }, (response) => { return response.data; });
  }

  /**
    * Updates the given critcal to the database
    * @param critical
    */
  updCritical(critical: any, subscribeMethod) {
    return this.updData("updCritical", { critical: critical }, subscribeMethod);
  }

  /**
  * Deletes the critcal matching with the given id
  * @param critical_id
  */
  delCritical(critical_id: string, subscribeMethod) {
    return this.updData("delCritical", { critical_id: critical_id }, subscribeMethod);
  }
}
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldType, FieldConfig, Validator } from '../../models/common';


@Component({
  selector: "app-checkbox",
  template: `
    <div class="form-group" [formGroup]="group">
      <div class="row row-center">
        <div class="col-md-3">      
          <label for="field.name">{{field.label}} :</label>
        </div>
        <div class="col-md-5">
          <input class="form-control" style="width:24px;" [formControlName]="field.name" type="checkbox">
        </div>
      </div>
    </div>`,
  styles: []
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() {}
  ngOnInit() {}
}

import { OnInit, Directive } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

import { ItemKinds } from "../../models/common";
import { TranslationService } from "../../services/translation.service";


@Directive()
export abstract class BaseWorkComponent implements OnInit {

  public item_kind: ItemKinds = ItemKinds.Sensor;


  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService) {
  }

  ngOnInit() {
  }
}

<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">

  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>
  <div style="margin-right:8px;">
    <div>
      <button type="button" (click)="fileInput.click()" style="width:38px;"><mat-icon matSuffix>upload</mat-icon></button>
    </div>
    <div>
      <button type="button" (click)="ClearImage()" style="width:38px;"><mat-icon matSuffix>delete</mat-icon></button>
    </div>
  </div>
  <div>


    <img *ngIf="value" class="img" style="padding:8px; max-width:100%" [src]="value | safe: 'img'">
    <div style="padding:4px; color:darkred;">{{message}}</div>
    <input hidden type="file" accept="image/png" (change)="OnFileSelected($event)" #fileInput>
  </div>
</div>

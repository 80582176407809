import { mathutils } from "../mathutils";
import { Point2D } from "./common/Point2D";
import { FigureBase } from "./FigureBase";
import { FullLine } from "./FullLine";
import { FullPoint } from "./FullPoint";
import { PlanLayout } from "./PlanLayout";


export class FullPolyLine extends FigureBase {

  public closed: boolean;
  public points: FullPoint[] = [];

  protected constructor(layout: PlanLayout) {
    super(layout);
  }

  protected override OnGetFullPoints(): FullPoint[] {
    return this.points;
  }

  protected override  OnGetFullLines(): FullLine[] {
    let lines: FullLine[] = [];
    if (this.points.length > 1)
      for (var i = 1; i < this.points.length; i++)
        lines.push(FullLine.GetFromFull(this.layout, this.points[i - 1], this.points[i]))
    return lines;
  }

  protected override OnUpdate() {
    for (let point of this.points)
      point.Update();
  }

  protected override OnDraw(construction: boolean): void {
    let points = this.GetZoomPoints();
    if (points.length < 2)
      return;
    if (this.closed)
      this.drawer.DrawPolygon(points, construction ? FigureBase.ConstructionColor : this.fore_color, this.back_color, this.size);
    else
      this.drawer.DrawPolyline(points, construction ? FigureBase.ConstructionColor : this.fore_color, this.size);
    if (this.draw_points)
      for (let point of this.GetFullPoints())
        this.drawer.DrawPoint(point.GetZoomPoints()[0], construction ? FigureBase.ConstructionColor : point.fore_color, this.back_color);
    if (this.label)
      this.drawer.DrawText(Point2D.FromJson(mathutils.getCenter(points)), this.label, this.fore_color);
  }

  public AddPoint(point: FullPoint) {
    this.points.push(point);
  }

  public GetLastPoint(): FullPoint | null {
    if (!this.points.length)
      return null;
    return this.points[this.points.length - 1];
  }

  public RemoveLast() {
    if (!this.points.length)
      return;
    this.points.splice(this.points.length - 1, 1);
  }

  public static GetFromReal(layout: PlanLayout, points: Point2D[], closed: boolean) {
    let full = new FullPolyLine(layout);
    for (let point of points)
      full.points.push(FullPoint.GetFromReal(layout, point));
    full.closed = closed;
    return full;
  }

  public static GetFromFull(layout: PlanLayout, points: FullPoint[]) {
    let full = new FullPolyLine(layout);
    for (let point of points)
      full.points.push(point);
    return full;
  }

  public static GetRectangleFromFull(layout: PlanLayout, points: FullPoint[]) {
    let full = new FullPolyLine(layout);
    full.points.push(FullPoint.GetFromReal(layout, points[0].GetPoint2D()));
    full.points.push(FullPoint.GetFromReal(layout, new Point2D(points[0].GetPoint2D().x, points[1].GetPoint2D().y)));
    full.points.push(FullPoint.GetFromReal(layout, points[1].GetPoint2D()));
    full.points.push(FullPoint.GetFromReal(layout, new Point2D(points[1].GetPoint2D().x, points[0].GetPoint2D().y)));
    return full;
  }
}

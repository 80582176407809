import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { colorutils } from '../../../../common/colorutils';
import { workutils } from '../../../../common/workutils';
import { DashboardService } from '../../../../services/dashboard.service';
import { TranslationService } from '../../../../services/translation.service';
import { DashCardLiteInfo } from '../../dashboard';


@Component({
  selector: "app-dashboard-counters",
  templateUrl: './dashboard-counters.component.html',
})
export class DashboardCountersComponent extends BaseDashboardWidgetComponent {

  public cards: DashCardLiteInfo[] = [];
  public card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardCounters(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let cards = [];
    let behaviours = data.behaviours;
    cards.push(new DashCardLiteInfo(data.total_equipments, this.translationService.translate('Total'), null, colorutils.Default));
    let statuses = workutils.getAllowedStatuses();
    for (let status of statuses)
      cards.push(new DashCardLiteInfo(behaviours[status["behaviour"]], status["label"], null, colorutils.getStatusColor(parseInt(status["behaviour"])), status["behaviour"]));
    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;
  }
}

<div class="dialog-header">
  <app-page-title title="{{title}}" mode="popup_mode"></app-page-title>
</div>

<div class="dialog-body">

  <form [formGroup]="editForm" style="display: flex;">

    <input type="hidden" formControlName="_id" name="_id" id="_id">

    <div class="col-md-4 no-padding">

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Building' | locale }} :</label></div>
          <div class="col-md-7">
            <app-form-select [field]="form.building_data" [list]="buildings" id="_id" text="name" (change)="onChangeBuilding($event)" [submitted]="submitted"></app-form-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ '$Floor$' | locale }} :</label></div>
          <div class="col-md-7">
            <app-form-select [field]="form.floor_data" [list]="floors" id="_id" text="name" [submitted]="submitted"></app-form-select>
          </div>
        </div>
      </div>

      <div formGroupName="labels">
        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>{{ 'Name' | locale }} :</label></div>
            <div class="col-md-7">
              <app-form-input [field]="editForm.get('labels.name')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>X (m) :</label></div>
            <div class="col-md-7">
              <app-form-input type="number" [field]="editForm.get('labels.x')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>Y (m) :</label></div>
            <div class="col-md-7">
              <app-form-input type="number" [field]="editForm.get('labels.y')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>Z (m) :</label></div>
            <div class="col-md-7">
              <app-form-input type="number" [field]="editForm.get('labels.z')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>

      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>N :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.n_rssi" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row">
          <div class="col-md-4"><label>N multi floor (RSSI MF) :</label></div>
          <div class="col-md-7">
            <textarea class="form-control" rows="5" readonly value="{{n_multi_floors}}"></textarea>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-4 no-padding">

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Cloud Id' | locale }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.cloud_id" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Disruptive Id' | locale }} (DT):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.name" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>



      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Type' | locale }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.type" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div formGroupName="labels">
        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>{{ 'Kit' | locale }} (DT):</label></div>
            <div class="col-md-7">
              <app-form-input [field]="editForm.get('labels.kit')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>
      </div>

      <div formGroupName="connection">

        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>{{ 'Update' | locale }} :</label></div>
            <div class="col-md-7">
              <app-form-date [field]="editForm.get('connection.update_time')" [submitted]="submitted"></app-form-date>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-4"><label>{{ 'Cellular power' | locale }} (%) :</label></div>
            <div class="col-md-7">
              <app-form-input type="number" [field]="editForm.get('connection.cellular_strength')" [submitted]="submitted"></app-form-input>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-md-4 no-padding">
      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'XIG gateway' | translate }} (XI):</label></div>
          <div class="col-md-8" style="float:none">
            <input type="checkbox" formControlName="is_bridge" class="form-control" style="float:left">
            <div style="float:left; padding:4px;" class="legend">This router is an XIG gateway<br /> (think to fill the IP)</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'IP address' | locale }} (XI):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.ip_address" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Port' | locale }} (empty = 8000) (XI):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.port" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>


      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Username' | locale }} (XI):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.username" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'Password' | locale }} (XI):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.password" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-4"><label>{{ 'MAC address' | locale }} (Navigation):</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.mac_address" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>

<div class="dialog-footer">
  <button class="btn-rounded btn-add" (click)="onSubmit()">{{ 'Save' | locale }}</button>
  <button class="btn-rounded btn-close" (click)="close(false)">{{ 'Close' | locale }}</button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="navigateFloor()" text="$Floor$" icon="edit"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="navigateAddGateways()" text="Gateways positioning" icon="edit"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="navigateLocationPicker()" text="Location picker" icon="edit"></app-form-button>
</div>


export enum DrawMode {
  None = 0,

  SelectPoint = 11,
  MovePoint = 12,

  Line = 21,
  Polyline = 22,
  Polygon = 23,
  Rectangle = 24,
}

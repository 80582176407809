

<div *ngIf="list && mode==1" class="search-filters" style="margin:16px;">
  <div class="select-list-title">{{title | locale}}</div>

  <div class="select-list">

    <div style="display:flex;  margin:0px; padding:0px;">
      <span class="select-list" style="width:50%;" [style.background]="all_selected ? colors.DefaultSelection : 'white'" [style.color]="all_selected ? 'white' : colors.DefaultSelection" (click)="onSelectAll(true)">
        <img class="select-list-icon" style="margin:2px 8px;" src="../../assets/img/items/all40.png" [style.filter]="(all_selected ? colors.CssFilterWhite : colors.CssFilterBlueWhereit) | safe: 'style'">
        <span [style.color]="all_selected ? 'white' : colors.DefaultSelection">{{'All' | locale}}</span>
      </span>
      <span class="select-list" style="width: 50%;" [style.background]="none_selected ? colors.DefaultSelection : 'white'" [style.color]="none_selected ? 'white' : colors.DefaultSelection" (click)="onSelectAll(false)">
        <img class="select-list-icon" style="margin:2px 8px;" src="../../assets/img/items/none40.png" [style.filter]="(none_selected ? colors.CssFilterWhite : colors.CssFilterBlueWhereit) | safe: 'style'">
        <span [style.color]="none_selected ? 'white' : colors.DefaultSelection">{{'None' | locale}}</span>
      </span>
    </div>
    <div style="height: 4px; background: #EEEEEE;"></div>

    <div *ngFor="let item of filtered_list" style="display:flex; margin:8px:0px;" class="select-list" [style.background]="item.selected ? colors.DefaultSelection : 'white'" [style.color]="item.selected ? 'white' : colors.DefaultSelection" (click)="onClickItem(item)">
      <img *ngIf="item_icon" class="select-list-icon" style="margin:2px 8px;" [src]="item_icon" [style.filter]="(item.selected ? colors.CssFilterWhite : colors.CssFilterBlueWhereit) | safe: 'style'">
      <img *ngIf="!item_icon" class="select-list-icon" style="margin:2px 8px;" [src]="item.icon | safe: 'img'" [style.filter]="(item.selected ? colors.CssFilterWhite : colors.CssFilterBlueWhereit) | safe: 'style'">
      <span [style.color]="item.selected ? 'white' : colors.DefaultSelection">{{item.name}}</span>
    </div>

  </div>
</div>

<div *ngIf="list && mode==2" class="search-filters" style="width: 100%; display: inline-block;" data-toggle="tooltip" title="">

  <div class="select-list-title">{{title | locale}}</div>

  <div class="row select-card" style="margin-right:15px; margin-left:15px; padding:0px;">
    <div class="row d-flex justify-content-center" style="width:100%; overflow-y: scroll; max-height:240px;">
      <div *ngFor="let item of filtered_list" class="select-card-item text-center" [style.background]="item.selected ? colors.DefaultSelection : 'white'" [style.color]="item.selected ? 'white' : colors.DefaultSelection" (click)="onClickItem(item)">
        <div>
          <img class="select-card-icon" [src]="item_icon ? item_icon : (item.icon | safe: 'img')" [style.filter]="(item.selected ? colors.CssFilterWhite : colors.CssFilterBlueWhereit) | safe: 'style'">
        </div>
        <div [style.color]="item.selected ? 'white' : colors.DefaultSelection">{{item.name}}</div>
      </div>
    </div>
  </div>

</div>
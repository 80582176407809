
export enum NavIcon {
  Straight = 0,
  Right = 90,
  Back = 180,
  Left = 270,

  Target = 1001,
  Stair = 1002,
  Lift = 1003
}

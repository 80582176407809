
  <div *ngIf="submitted && field && field.errors" class="msg-error">
    <div class="msg-error" *ngIf="field.errors.required">{{ 'This field is mandatory' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.used">{{ 'This value is already used' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.minlength">{{ 'The password must contain ' + field.errors.minlength.requiredLength + ' characters' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.mustMatch">{{ 'The two passwords are not identical' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.invalidPassword">{{ 'The password must contain at least one upper case, one lower case and one number' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.invalidMail">{{ 'The mail address is not valid' | translate}}</div>
    <div class="msg-error" *ngIf="field.errors.invalidDateOrder">{{ 'Start date must be older or equal to end date' | translate}}</div>
  </div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class RoomService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "room";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * Gets all the rooms of the project
   */
  getRooms() {
    return this.getData("getRooms", {}, (response) => { return response.data; });
  }

  /**
   * Gets the list of rooms, for a given flr
   * @param floor_id id of the flr for which to fetch rooms
   */
  getRoomsForFloor(floor_id: string) {
    return this.getData("getRoomsForFloor", { floor_id: floor_id }, (response) => { return response.data; });
  }

  /**
   * Gets a room by id
   * @param room_id the id of the room
   */
  getRoom(room_id: string) {
    return this.getData("getRoom", { room_id: room_id }, (response) => { return response.data; });
  }

  /**
   * Adds a new Room to the database
   * @param room the room to add
   */
  addRoom(room: any, subscribeMethod) {
    return this.updData("addRoom", { room: room }, subscribeMethod);
  }

  addRoomNoSubscribe(room: any) {
    return this.addDataNoSubscribe("addRoom", { room: room });
  }

  /**
   * Updates the given room to the database
   * @param room the room to update
   */
  updRoom(room: any, subscribeMethod) {
    return this.updData("updRoom", { room: room }, subscribeMethod);
  }

  /**
   * Deletes the room matching with the given id
   * @param room_id the id of the room
   */
  delRoom(room_id: string, subscribeMethod) {
    return this.updData("delRoom", { room_id: room_id }, subscribeMethod);
  }

  updRoomsFromRms(rooms: any, floor_id: string, subscribeMethod) {
    return this.updData("updRoomsFromRms", { rooms: rooms, floor_id: floor_id }, subscribeMethod);
  }
}
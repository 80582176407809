import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

// Highcharts
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts)
HC_exporting(Highcharts);

import { BaseWorkComponent } from "./base.work.component";
import { MeasureTypesText } from "../../models/common";
import { colorutils } from "../colorutils";
import { logicutils } from "../logicutils";
import { HistoricChartInfo, SerieKind, ChartAxis, MarkerKind } from '../../views/dashboard/dashboard';
import { TranslationService } from "../../services/translation.service";
import { workutils } from "../workutils";


@Component({
  selector: "app-historic-chart",
  templateUrl: './historic-chart.component.html',
})
export class HistoricChartComponent extends BaseWorkComponent {

  @Output() onItemClick = new EventEmitter();

  // chart fields
  public Highcharts = Highcharts;
  public updateFromInput = false;
  public chartConstructor = "chart";
  public chartCallback;
  public chart_options: any = null;
  public show_chart: boolean = false;

  // items diplayed in the plan (item can be sensor equipment)
  public events: any[] = [];
  public templates: any[] = [];
  private statuses: any[] = [];
  private customs: any[] = [];
  private equipment_name: string;
  private equipment_statuses: any[] = [];

  public filter: any = { "show_states": true, "show_statuses": false, "show_customs": false };


  constructor(protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  public initialize() {
    Highcharts.setOptions({ lang: logicutils.getChartLangOptions(this.translation_service) });
    this.chart_options = {
      title: false,
      credits: { enabled: false },
      series: []
    };
    this.show_chart = true;
  }

  // User interaction Methods
  // ******************************

  public setItems(event_infos: any[], refresh: boolean = false) {
    this.events = event_infos;
    let templates = [];
    for (var event of this.events)
      templates.push(event["events"][0]);
    this.templates = templates;
    if (refresh)
      this.updateData();
  }

  public setStatuses(statuses: any[], customs: any[], equipment_statuses: any[], equipment_name: string, refresh: boolean = false) {
    this.statuses = statuses;
    this.customs = customs;
    this.equipment_statuses = equipment_statuses;
    this.equipment_name = equipment_name;
    if (refresh)
      this.updateData();
  }

  public changeFilter(evt: any, refresh: boolean = false) {
    this.filter = evt;
    if (refresh)
      this.updateData();
  }

  public onChartClick(clicked_point) {
    if (clicked_point.point == null)
      return;
    let event = clicked_point.point.event;
    this.onItemClick.emit(event);
  }


  // Tools Methods
  // ****************

  public updateData() {
    let chart = new HistoricChartInfo(this.translation_service.translate('History'));
    // display sensor states historic
    if (this.filter["show_states"]) {
      // add y axes
      let axes = {}
      for (var template of this.templates) {
        let measure_types = workutils.getSensorMeasureTypes(template);
        for (var measure_type of measure_types) {
          let unit_type = this.getGetMeasureUnit(measure_type);
          if (!axes.hasOwnProperty(unit_type))
            axes[unit_type] = chart.addYChartAxis(this.getStateYAxe(unit_type));
        }
      }

      // add data
      let counter = 0;
      for (var item of this.events) {
        let evts = item["events"];
        let sensor_name = evts[0]["sensor_name"];
        //let sensor_type = evts[0]["sensor_type"];
        let measure_types = workutils.getSensorMeasureTypes(evts[0]);
        for (var measure_type of measure_types) {
          let values = []
          for (var evt of evts) {
            var x = new Date(evt.sensor_last_update).getTime();
            let y = this.getDataForMeasure(evt, measure_type);
            if (y != null) {
              values.push({ x: x, y: y, event: evt, tootip_info: this.getTooltipInfoForMeasure(evt, measure_type) });
              // for the touches immediatly set to 0 to have only an impulsion
              if (measure_type == "touch" && y > 0) {
                x = x + 1000;
                let data = {
                  "name": evt.sensor_name,
                };
                data["value"] = this.translation_service.translate("Waiting");
                values.push({ x: x, y: 0, event: evt, tootip_info: data });
              }
            }
          }
          let type = this.getGetMeasureUnit(measure_type);
          chart.addSerie(sensor_name + " (" + this.translation_service.translate(measure_type) + ")", values, this.getStateLineType(measure_type), colorutils.getColor(counter), false, axes[type], MarkerKind.Circle);
          counter++;
        }
      }
    }

    // display equipment status historic
    if (this.filter["show_statuses"] && (this.statuses.length > 0)) {
      // add y axe
      let labels = []
      for (var status of this.equipment_statuses)
        labels.push(status["label"]);
      let axe_index = chart.addYAxeForList(this.translation_service.translate("Status"), colorutils.Blue, labels, "{value}", true);

      // add data
      let values = [];
      for (var status of this.statuses) {
        var x = new Date(status.update_time).getTime();
        let y = labels.indexOf(workutils.getStatusLabelFromStringNumber(status.field_value));
        let tootip_info = { "name": this.equipment_name, "value": (workutils.getStatusLabelFromStringNumber(status.field_value)) };
        values.push({ x: x, y: y, status: status, tootip_info: tootip_info });
      }
      chart.addSerie(this.equipment_name + " (" + this.translation_service.translate("Status") + ")", values, SerieKind.SquareLine, colorutils.Blue, false, axe_index, MarkerKind.Circle);
    }

    // display equipment custom fields historic
    if (this.filter["show_customs"]) {
      let counter = 0;
      for (var custom of this.customs) {
        let key = custom._id;
        let histories = custom.histories;
        let color = colorutils.getColor(counter);
        // add y axe
        let axe_index
        if (key.field_type == 1 || key.field_type == 2) // number and integers
          axe_index = chart.addYAxeForNumber(key.field_name, color, "{value}", true);
        else if (key.field_type == 3) // boolean
          axe_index = chart.addYAxeForBoolean(key.field_name, color, this.translation_service.translate("No"), this.translation_service.translate("Yes"), "{value}", true);
        else if (key.field_type == 5) // list
          axe_index = chart.addYAxeForList(key.field_name, color, custom.labels, "{value}", true);
        else
          axe_index = chart.addYAxeForNumber(key.field_name, color, "{value}", true);

        // add data
        let values = []
        let line_kind: SerieKind;
        if (key.field_type == 1)
          line_kind = SerieKind.Spline;
        else if (key.field_type == 2)
          line_kind = SerieKind.Line;
        else if (key.field_type == 3)
          line_kind = SerieKind.SquareLine;
        else if (key.field_type == 5)
          line_kind = SerieKind.SquareLine;
        else
          line_kind = SerieKind.Line;

        for (var entry of histories) {
          var x = new Date(entry.update_time).getTime();
          let y;
          if (key.field_type == 1 || key.field_type == 2)
            y = entry.field_value;
          else if (key.field_type == 3)
            y = entry.field_value ? 1 : 0;
          else if (key.field_type == 5) {
            y = custom.labels.indexOf(entry.field_value);
          }
          else
            y = 0;
          let value = (key.field_type == 3) ? (entry.field_value ? this.translation_service.translate("Yes") : this.translation_service.translate("No")) : entry.field_value;
          let tootip_info = { "name": this.equipment_name, "value": value };
          values.push({ x: x, y: y, status: status, tootip_info: tootip_info });
        }
        chart.addSerie(this.equipment_name + " (" + key.field_name + ")", values, line_kind, color, false, axe_index, MarkerKind.Circle);
        counter++;
      }
    }
    this.updateChart(chart);
  }

  private updateChart(chart: HistoricChartInfo) {
    this.chart_options = chart.getOptions();
    this.updateFromInput = true;
  }


  // Data methods
  // ****************

  private getGetMeasureUnit(measure) {
    if (measure == "temperature_internal")
      return "temperature"
    return measure;
  }

  private getTooltipInfoForMeasure(item: any, measure_type): any {
    let value = workutils.getReadableMeasure(this.translation_service, measure_type, item);
    return {
      "name": item.sensor_name,
      "value": value
    };
  }

  private getDataForMeasure(item: any, measure_type): number {
    let type = measure_type;
    if (type == 'temperature')
      return item.sensor_temperature == null || (item.sensor_temperature == undefined) ? null : parseFloat(item.sensor_temperature);
    if (type == 'temperature_internal')
      return (item.sensor_temperature_internal == null) || (item.sensor_temperature_internal == undefined) ? null : parseFloat(item.sensor_temperature_internal);
    if (type == 'humidity')
      return parseFloat(item.sensor_humidity);
    if (type == 'touch')
      return item.touch_count;
    if (type == 'proximity')
      return item.sensor_proximity ? 1 : 0;
    if (type == 'water')
      return item.sensor_water ? 1 : 0;
    if (type == 'movement')
      return item.sensor_movement ? 1 : 0;
  }

  private getStateYAxe(measure_type: string): ChartAxis {
    let text = this.translation_service.translate(MeasureTypesText[measure_type]);
    let color = colorutils.Blue;
    if ((measure_type == 'temperature') || (measure_type == 'temperature_internal'))
      return ChartAxis.getYAxeForNumber(text, color, "{value} °C", false);
    if (measure_type == 'humidity')
      return ChartAxis.getYAxeForNumber(text, color, "{value} %", false);
    if (measure_type == 'touch')
      //return ChartAxis.getYAxeForBoolean(text, color, "0", "1");
      return ChartAxis.getYAxeForNumber(text, color, "{value}", false);
    if (measure_type == 'proximity')
      return ChartAxis.getYAxeForBoolean(text, color, this.translation_service.translate("Closed"), this.translation_service.translate("Opened"));
    if (measure_type == 'water')
      return ChartAxis.getYAxeForBoolean(text, color, this.translation_service.translate("No water"), this.translation_service.translate("Water present"));
    if (measure_type == 'movement')
      return ChartAxis.getYAxeForBoolean(text, color, this.translation_service.translate("Static"), this.translation_service.translate("Moving"));
    return ChartAxis.getYAxeForNumber(text, color);
  }

  private getStateLineType(state_type: string): SerieKind {
    if ((state_type == 'temperature') || (state_type == 'temperature_internal'))
      return SerieKind.Spline;
    if (state_type == 'humidity')
      return SerieKind.Spline;
    if (state_type == 'touch')
      return SerieKind.SquareLine;
    if (state_type == 'proximity')
      return SerieKind.SquareLine;
    if (state_type == 'water')
      return SerieKind.SquareLine;
    if (state_type == 'movement')
      return SerieKind.SquareLine;
  }
}
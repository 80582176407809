<div class="dashboard" style="background-color:#2B526F; margin:0px;">

  <app-breadcrumb [links]="links"></app-breadcrumb>
  <app-page-title title="{{title}}" [initialized]="initialized" style="background-color:#2B526F"></app-page-title>

  <div *ngIf="rights" style="margin: 0px auto;" [ngStyle]="{'width':bar_width+'px' }">
    <button *ngIf="canEquipments()" id="equipments_button" class="btn-rounded btn-add" (click)="navigateDashboard()">{{'Equipments'|locale}}</button>
    <button *ngIf="canMaintenance()" id="maintenance_button" class="btn-rounded btn-add" (click)="navigateMaintenanceDashboard()">{{'Maintenance'|locale}}</button>
    <button *ngIf="canBattery()" id="battery_button" class="btn-rounded btn-close" (click)="navigateBatteryDashboard()">{{'Battery'|locale}}</button>
  </div>

  <div class="msg-error">{{error}}</div>

  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">
    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <span class="current_date" style="color:white">{{ 'Update time' | locale }} : {{current_date | localizedDate:'medium'}}</span>
    </div>
  </div>

  <div *ngIf="show_widget_counters" class="row col-md-12 dashboard-container">
    <div class="row col-md-12 nopadding">
      <div class="centered" style="width: 1024px;">
        <div style="color:white; font-weight: bold;">
          {{'Battery percentage ' | locale}}
        </div>
        <app-dashboard-counters-battery id="widget_counters" #widget_counters (Select)="onSelect($event)"></app-dashboard-counters-battery>
      </div>
    </div>
    <div class="row col-md-12 nopadding">
      <div class="centered" style="width: 1024px;">
        <div style="color:white; font-weight: bold;">
          {{'Battery autonomy' | locale}}
        </div>
        <app-dashboard-counters-battery-months id="widget_counters_years" #widget_counters_years (Select)="onSelect($event)"></app-dashboard-counters-battery-months>
      </div>
    </div>
  </div>
</div>
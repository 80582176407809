import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MapComponent } from './map/map.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardReportComponent } from './dashboard/dashboard-report.component';
import { DashboardMaintenanceComponent } from './dashboard-maintenance/dashboard-maintenance.component';
import { DashboardConsumerComponent } from './dashboard-consumer/dashboard-consumer.component';
import { DashboardBatteryComponent } from './dashboard-battery/dashboard-battery.component';
import { FindComponent } from './find/find.component';


const routes: Routes = [
  { path: 'map', component: MapComponent },
  { path: 'find', component: FindComponent },
  { path: 'dashboard/:building_id/:equipment_family_id/:department_id/:is_first_open', component: DashboardComponent, },
  { path: 'dashboard-report/:building_id/:equipment_family_id/:department_id/:is_first_open', component: DashboardReportComponent, },
  { path: 'dashboard-maintenance/:building_id/:equipment_kind_id', component: DashboardMaintenanceComponent, },
  { path: 'dashboard-consumer', component: DashboardConsumerComponent, },
  { path: 'dashboard-battery/:building_id', component: DashboardBatteryComponent, }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }

import { OnInit, Input, Directive } from "@angular/core";
import { FormGroup } from '@angular/forms';


@Directive()
export abstract class FormEditorBase implements OnInit {

  /**
   * The field to bind the editor
   * @example [field]="form.name"
   * if the field is nested in a group, .controls must bet specified after the group name
   * @example [field]="form.address.controls.city"
   */
  @Input() field: any;

  /**
   * The submitted stated of the form, which must be bound to a boolean field
   * @example [submitted]="submitted"
   */
  @Input() submitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  public getFieldName(): string | null {
    let group = <FormGroup>this.field.parent;
    if (!group)
      return null;
    for (let key of Object.keys(group.controls))
      if (group.get(key) == this.field)
        return key;
    return null;
  }
}
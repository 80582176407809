import { Component, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from "@angular/material/dialog";

import { BaseWorkComponent } from "./base.work.component";
import { GatewayUpdComponent } from "../../views/forms/gateway/gateway-upd/gateway-upd.component";
import { TranslationService } from "../../services/translation.service";
import { GatewayService } from "../../services/gateway.service";

import { mathutils } from '../mathutils';
import { logicutils } from '../logicutils';
import { colorutils } from "../colorutils";
import { ColorIntensity } from "../enums";


@Component({
  selector: "app-debug-ribbon",
  templateUrl: './debug-ribbon.component.html',
})
export class DebugRibbonComponent extends BaseWorkComponent implements AfterViewInit {

  public is_admin: boolean;
  public rights: any;

  public current_event: any;
  public reference_keys: any[] = null;
  public oor_info: any;
  public position_point: any;
  public collect_point: any;

  private all_gateways: any[];
  public event_gateways: any[] = [];
  public event_gateways_header: any[] = [];
  public event_gateways_header_level: any[] = [];

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService,
    private gatewayService: GatewayService,
    public dialog: MatDialog,
  ) {
    super(router, domSanitizer, translation_service);
  }

  async ngOnInit() {
    this.all_gateways = await this.gatewayService.getAllGateways().toPromise();
  }

  ngAfterViewInit() {
  }

  public async initialize(is_admin: boolean, rights: any) {
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setEvent(current_event: any) {
    // clone the event
    let event = JSON.parse(JSON.stringify(current_event));
    // check the matched gateways
    if (event && ("all_gateways" in event) && ("gateways" in event))
      for (var gateway of event["all_gateways"]) {
        let found = event["gateways"].find(g => g["cloud_id"] == gateway["cloud_id"])
        gateway["match"] = found != null;
        gateway["name"] = gateway["name"] ? gateway["name"].substr(0, 24) : "";
        gateway["n_rssi"] = mathutils.round(found ? found["n_rssi"] : gateway["n_rssi"], 1);
        //gateway["n_multi_floor"] = mathutils.round(found ? found["n_multi_floor"] : gateway["n_multi_floor"], 1);
        //gateway["n_multi_floor_extender"] = mathutils.round(found ? found["n_multi_floor_extender"] : gateway["n_multi_floor_extender"], 1);
      }
    if (event && "fprt_reference_points" in event && (event["fprt_reference_points"].length > 0))
      this.reference_keys = Object.keys(event["fprt_reference_points"][0]);
    if (event && "oor_info" in event)
      this.oor_info = event["oor_info"];
    this.current_event = event;
    this.fillRssis();
  }

  public async fillRssis() {
    let event_gateways = [];
    let event_gateways_header = ["x","y"];
    let event_gateways_header_level = [0,0];
    let points = this.current_event.fprt_reference_points;
    // the first point is the current one
    for (let point of points) {
      let event_gateway = [];
      for (let gateway_cloud_id of Object.keys(point)) {
        if (gateway_cloud_id == "current")
          continue;
        let index = event_gateways_header.indexOf(gateway_cloud_id);
        if (index < 0) {
          event_gateways_header.push(gateway_cloud_id);
          event_gateways_header_level.push(0);
          index = event_gateways_header.length - 1;
        }
        if ((gateway_cloud_id == "x") || (gateway_cloud_id == "y"))
          event_gateway[index] = mathutils.round(point[gateway_cloud_id], 2);
        else
          event_gateway[index] = point[gateway_cloud_id];
      }
      event_gateways.push(event_gateway);
    }

    for (let i = 0; i < event_gateways_header.length; i++) {
      let gateway = this.all_gateways.find(g => g.cloud_id == event_gateways_header[i]);
      let gateway_level = ("all_gateways" in this.current_event) ? this.current_event["all_gateways"].find(g => g.cloud_id == event_gateways_header[i]): null;
      event_gateways_header[i] = gateway ? gateway.labels.name : event_gateways_header[i];
      event_gateways_header_level[i] = gateway_level ? gateway_level.level : "";
    }
    for (let event_gateway of event_gateways)
      for (let i = 0; i < event_gateways_header.length; i++)
        if (!event_gateway[i])
          event_gateway[i] = null;
    this.event_gateways_header = event_gateways_header;
    this.event_gateways_header_level = event_gateways_header_level;
    this.event_gateways = event_gateways;
  }

  public sortTable(event) {
    logicutils.sortTable(event);
  }

  public onNavigateGateway(cloud_id: string) {
    GatewayUpdComponent.openDialog(this.dialog, cloud_id, () => { });
  }

  public getRssiColor(value) {
    return colorutils.getRssiColor(value, ColorIntensity.Mid);
  }
}
import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-select-editor label="Enregistrements" [records]="list" [empty]="''" [(ngModel)]="record_id" (Changed)="OnChanged()" width="100px" disabled></app-select-editor>
 */
@Component({
  selector: 'app-select-editor',
  templateUrl: 'select.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEditor),
      multi: true
    }
  ]
})
export class SelectEditor extends EditorBase<any> {

  // the list of records { id:number, name:string}
  @Input() private _records: any[] = [];

  // if not null an ampty entry is added
  @Input() empty: string | null = null;


  constructor() {
    super();
  }


  get records() {
    return this._records;
  }
  @Input() set records(recs) {

    if ((recs?.length || 0) == 0) {
      this._records = [];
    } else {      
      // ensures the record have a key which is named id
      let copy = [...recs];
      if (!copy[0].hasOwnProperty("id") && copy[0].hasOwnProperty("key"))
        for (let rec of copy)
          rec.id = rec.key;
      if (!copy[0].hasOwnProperty("id") && copy[0].hasOwnProperty("_id"))
        for (let rec of copy)
          rec.id = rec._id;
      this._records = copy;
    }
  }
}


<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">

  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>

  <mat-slide-toggle class="form-field-full-width" style="height: 32px; margin:2px 4px;" labelPosition="after" color="primary" [name]="name" [(ngModel)]="value" [disabled]="_disabled">
  </mat-slide-toggle>

</div>

import { Point2D } from "./common/Point2D";
import { FigureBase } from "./FigureBase";
import { FullPoint } from "./FullPoint";
import { PlanLayout } from "./PlanLayout";


export class FullLine extends FigureBase {

  public point1: FullPoint;
  public point2: FullPoint;

  protected override OnGetFullPoints(): FullPoint[] {
    return [this.point1, this.point2];
  }

  protected override OnGetFullLines(): FullLine[] {
    return [this];
  }

  protected override OnUpdate() {
    this.point1.Update();
    this.point2.Update();
  }

  protected override OnDraw(construction: boolean): void {
    this.drawer.DrawLine(this.point1.GetZoomPoints()[0], this.point2.GetZoomPoints()[0], construction ? FigureBase.ConstructionColor : this.fore_color, this.size);
    if (this.draw_points)
      for (let point of this.GetFullPoints())
        this.drawer.DrawPoint(point.GetZoomPoints()[0], construction ? FigureBase.ConstructionColor : point.fore_color, point.back_color);
  }

  public static GetFromReal(layout: PlanLayout, point1: Point2D, point2: Point2D) {
    let full = new FullLine(layout);
    full.point1 = FullPoint.GetFromReal(layout, point1);
    full.point2 = FullPoint.GetFromReal(layout, point2);
    return full;
  }

  public static GetFromFull(layout: PlanLayout, point1: FullPoint, point2: FullPoint) {
    let full = new FullLine(layout);
    full.point1 = point1;
    full.point2 = point2;
    return full;
  }
}

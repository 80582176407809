
<div class="multiselect form-control" style="position: relative; top:0; z-index: 100; width:100%">
  <div class="selectBox" (click)="toggleList($event)">
    <select style="height:28px; opacity:1;" [disabled]="true">
      <option>{{label}}</option>
    </select>
    <div class="overSelect"></div>
  </div>
  <div [ngStyle]="{'display':expanded ? 'block' : 'none' }" style="position: absolute; top: 28px; z-index:100; background-color: #F2F7FD; padding: 5px; border: 1px solid black; overflow-y:auto">
    <select style="position: relative; z-index: 100;" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (click)="onClick($event)" name="main-list" class="select-checkbox search-select-mat filter-select form-control" [(ngModel)]="_filter" (ngModelChange)="onChanged()" (scroll)="onScroll($event)"  multiple>
      <option *ngFor="let item of list" value={{item._id}}>{{item.name}}</option>
    </select>
  </div>
</div>


<div class="plan-container">

  <div class="plan-container" (window:resize)="OnResize($event)" style="background: #BCD6BC; ">
    <img #plan_main_image style="display: none" [src]="bitmap | safe: 'img'">
    <canvas #plan_draw_canvas oncontextmenu="return false" style="display:block"
            (wheel)="OnWheel($event)" (mousemove)="OnMouseMove($event)"
            (mousedown)="OnMouseDown($event)" (mouseup)="OnMouseUp($event)" (dblclick)="OnDoubleClick($event)"></canvas>
  </div>

  <app-plan-command #plan_command [ngStyle]="{ 'display': layout && show_command ? 'block' : 'none' }"></app-plan-command>

</div>

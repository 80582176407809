import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class ConsumerService extends ServiceBase {

  // service part of the url (specified in each real service)
  protected serviceUrlPart = "consumer";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }


  /**
   * Finds all the records of the collection.
   */
  public getConsumers() {
    return this.getData("getConsumers", {}, (response) => { return response.data; });
  }

  /**
  * Finds the record matching with the given id in the collection.
  * @param {any} consumer_id : the id of the record to find
  */
  public getConsumer(consumer_id: string) {
    return this.getData("getConsumer", { consumer_id: consumer_id }, (response) => { return response.data; });
  }

  /**
  * Adds a new record in the collection.
  * @param {any} consumer: the json object containing the data of the record to add.
  */
  public addConsumer(consumer: any, subscribeMethod) {
    return this.updData("addConsumer", { consumer: consumer }, subscribeMethod);
  }

  /**
   * Updates an existing record in the collection.
   * @param {any} consumer: the json object containing the data of the record to update.
   */
  public updConsumer(consumer: any, subscribeMethod) {
    return this.updData("updConsumer", { consumer: consumer }, subscribeMethod);
  }

  /**
  * Deletes an existing record in the collection.
  * @param {any} consumer_id: the id of the record to delete.
  */
  public delConsumer(consumer_id: string, subscribeMethod) {
    return this.updData("delConsumer", { consumer_id: consumer_id }, subscribeMethod);
  }

  /*
   * Attach an equipment to the given customer
   */
  public attachEquipment(consumer_id: string, equipment_id: string, subscribeMethod) {
    return this.updData("attachEquipment", { consumer_id: consumer_id, equipment_id: equipment_id }, subscribeMethod);
  }

  /*
   * Detach the equipment from the given customer
   */
  public detachEquipment(consumer_id: string, equipment_id: string, subscribeMethod) {
    return this.updData("detachEquipment", { consumer_id: consumer_id, equipment_id: equipment_id }, subscribeMethod);
  }
}

<div class="card-container" style="background-color:#F2F7FD; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);">
  <div class="card-back" *ngIf="current_event && current_event.equipment_id" style="width:100%; max-height:148px;">
    <div class="col-md-12 card-core" style="background-color: #F2F7FD; border:none; min-height:auto; max-height:132px;">
      <div class="card-head">
        <b class="info-ribbon-text">{{ '$Equipment$' | locale }}</b>
        <app-list-button kind="Ctm" *ngIf="rights['right_equipments']" (click)="navigateEquipment()" icon="edit" tooltip="Modify_the_record"></app-list-button>
      </div>
      <div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Name' | locale }} :</label>{{current_event.equipment_name}}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Type' | locale }} :</label>{{current_event.equipment_kind_name}}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Status' | locale }} :</label>{{current_event.equipment_status}}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Last update' | locale }} :</label>{{current_event.equipment_status_last_update | date:'dd/MM/yyyy HH:mm:ss'}}</div>
      </div>
      <div>
        <div *ngFor="let custom of current_event.equipment_customs | keyvalue" class="card-detail"><label class="card-label">{{custom.key}} :</label>{{custom.value}}</div>
      </div>
    </div>
  </div>

  <div class="card-back" *ngIf="current_event" style="width:100%; max-height:148px;">
    <div class="col-md-12 card-core" style="background-color: #F2F7FD; border:none; border-top:1px #ccc solid; min-height:auto; max-height:132px;">
      <div class="card-head">
        <b class="info-ribbon-text">{{ 'Sensor' | locale }}</b>
        <app-list-button kind="Ctm" (click)="navigateSensor()" *ngIf="rights['right_devices']" icon="edit" tooltip="Modify_the_record"></app-list-button>
      </div>
      <div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Name' | locale }} : </label>{{ current_event.sensor_name }}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Type' | locale }} : </label>{{ current_event.sensor_type | locale}}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Data' | locale }} : </label>{{ current_event.sensor_state_label }}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Last update' | locale }} : </label>{{ current_event.sensor_last_update | date:'dd/MM/yyyy HH:mm:ss' }}</div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Battery level' | locale }} : </label>{{ current_event.sensor_current_battery_percent }}{{ current_event.sensor_current_battery_percent ? ' %' : ''}} </div>
        <div class="info-ribbon-text"><label class="card-label">{{ 'Battery update time' | locale }} : </label>{{ current_event.sensor_current_battery_update_time | date:'dd/MM/yyyy HH:mm:ss' }}</div>
      </div>
    </div>
  </div>

  <div class="card-back" *ngIf="current_event" style="width:100%; max-height:148px;">
    <div class="col-md-12 card-core" style="background-color: #F2F7FD; border:none; border-top:1px #ccc solid; min-height:auto; max-height:132px;">
      <div class="card-head">
        <b class="info-ribbon-text">{{ 'Location' | locale }}</b>
      </div>

      <div class="info-ribbon-text"><label class="card-label">{{ 'Building' | locale }} : </label>{{ current_event.building_name }}</div>
      <div class="info-ribbon-text"><label class="card-label">{{ '$Floor$' | locale }} : </label>{{ current_event.floor_name}}</div>
      <div *ngIf="current_event.department_id" class="info-ribbon-text">
        <label class="card-label" style="width: 80px">{{ 'Department' | locale }} :</label>{{current_event.department_name}}
      </div>
      <div *ngIf="current_event.room_id" class="info-ribbon-text">
        <label class="card-label" style="width: 80px">{{ 'Next to' | locale }} :</label>{{current_event.room_name}}
      </div>
    </div>
  </div>

  <div class="card-back" style="width:308px;">

    <div class="col-md-12 card-core" style="background-color: #F2F7FD; border:none; border-top:1px #ccc solid; min-height:auto; max-height:148px; overflow-x: hidden">
      <div class="card-head">
        <div style="margin:0px;">
          <b class="info-ribbon-text">{{ 'Filtering' | locale }}</b>
          <app-list-button kind="Ctm" [enabled]="!isloading" (click)="fillHistory()" icon="check-circle" tooltip="Apply_the_filter"></app-list-button>
          <app-list-button kind="Ctm" [enabled]="!isloading" (click)="resetDateRange()" icon="sync-alt" tooltip="Reset_the_filter"></app-list-button>
          <app-list-button kind="Ctm" *ngIf="is_location_view" (click)="playHistory()" icon="play" tooltip="Play_the_history"></app-list-button>
          <span *ngIf="isloading">{{'Loading' | locale}} ...</span>
          <span *ngIf="!disable_refresh" style="background-color:darkblue; float:right;" class="image-circle"></span>
        </div>
      </div>

      <div class="card-detail">
        <label class="card-label" style="width:80px; float:left; margin-top:5px;">{{ 'Start date' | locale }} :</label>
        <div style="width:172px; float:left;">
          <app-date-time [(date)]="start_date" (DateChanged)="start_date = $event"></app-date-time>
        </div>
      </div>
      <div class="card-detail">
        <label class="card-label" style="width:80px; float:left; margin-top:5px;">{{ 'End date' | locale }} :</label>
        <div style="width:172px; float:left;">
          <app-date-time [(date)]="end_date" (DateChanged)="end_date = $event"></app-date-time>
        </div>

      </div>

      <div class="card-detail" *ngIf="is_location_view">
        <label class="card-label" style="width:80px; float:left; margin-top:5px;">{{ 'Location' | locale }} :</label>
        <div style="float:left;">
          <select class="search-selectequip-single" [ngModel]="location_mode" (change)="locationModeChanged($event)" style="height:26px; font-size:10px;">
            <option *ngFor="let mode of location_modes" [ngValue]=mode.key>{{mode.name | locale}}</option>
          </select>
        </div>
      </div>

      <div class="row col-md-12 nopadding" *ngIf="!is_location_view">
        <div class="col-md-3 nopadding" style="display: flex; align-items: center">
          <label class="card-label" style="width:auto; margin: 4px 6px 4px 0px">{{ 'States' | locale }} :</label>
          <span style="float:left;"><input type="checkbox" class="form-control" [(ngModel)]="show_states" (ngModelChange)="filterChanged()" /></span>
        </div>
        <div class="col-md-3 nopadding" style="display: flex; align-items: center">
          <label class="card-label" style="width:auto; margin: 4px 6px 4px 0px">{{ 'Statuses' | locale }} :</label>
          <span style="float:left;"><input type="checkbox" class="form-control" [(ngModel)]="show_statuses" (ngModelChange)="filterChanged()" /></span>
        </div>
        <div class="col-md-6 nopadding" style="display: flex; align-items: center">
          <label class="card-label" style="width:auto; margin: 4px 6px 4px 0px">{{ 'Customs' | locale }} :</label>
          <span style="float:left;"><input type="checkbox" class="form-control" [(ngModel)]="show_customs" (ngModelChange)="filterChanged()" /></span>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldType, FieldConfig, Validator } from '../../models/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: "app-date",
  template: `
    <div class= "form-group" [formGroup]="group">
      <div class="row row-center">
        <div class="col-md-3">      
          <label for="field.name">{{field.label}} :</label>
        </div>
        <div class="col-md-5">
          <div style="width:100%" class="datePicker">
            <input style="width:160px; float:left;" class="form-control" matInput [matDatepicker]="picker" [formControlName]="field.name">
            <mat-datepicker-toggle matSuffix style="float:left;" [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker style="float:left;"></mat-datepicker>          
          </div>
          <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
            <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
          </ng-container>
        </div>
      </div>
    </div>`,
  styles: []
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(private _adapter: DateAdapter<any>) {}
  ngOnInit() {
    this._adapter.setLocale('fr');
  }
}



<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">
  <div [ngStyle]="{ 'width': label_width ?? ''}">
    <label *ngIf="label">{{label}} : </label>
  </div>
  <input [type]="type" [name]="name" [(ngModel)]="value"
         [disabled]="_disabled" [required]="required" [minlength]="minlength" [maxlength]="maxlength" autocomplete="new-password"
         [ngClass]="required_error ? 'invalid' : ''" [ngStyle]="{ 'text-align': align ?? 'left' }" />
</div>

<div class="msg err" *ngIf="form?.submitted && required_error">Le champ {{label}} est obligatoire.</div>
<div class="msg err" *ngIf="form?.submitted && maxlength_error">Le champ {{label}} ne doit pas dépasser {{maxlength}} caractères.</div>
<div class="msg err" *ngIf="form?.submitted && minlength_error">Le champ {{label}} doit contenir au moins {{minlength}} caractères.</div>

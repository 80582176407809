import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { colorutils } from '../../../../common/colorutils';
import { DashboardService } from '../../../../services/dashboard.service';
import { TranslationService } from '../../../../services/translation.service';
import { DashCardLiteInfo } from '../../dashboard';


@Component({
  selector: "app-dashboard-counters-battery",
  templateUrl: './dashboard-counters-battery.component.html',
})
export class DashboardCountersBatteryComponent extends BaseDashboardWidgetComponent {

  public cards: DashCardLiteInfo[] = [];
  public card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardCountersBattery(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let cards = [];
    let data_levels = data.levels;
    cards.push(new DashCardLiteInfo(data.total_sensors, this.translationService.translate('Total'), null, colorutils.Default));
    let levels = [
      {
        level: "0",
        label: "0 - 10%",
        color: "rgb(235, 101, 106)"
      },
      {
        level: "1",
        label: "10 - 25%",
        color: "rgb(242,120,96)"
      },
      {
        level: "2",
        label: "25 - 50%",
        color: "rgb(255,161,80)"
      },
      {
        level: "6",
        label: this.translationService.translate("Unknown"),
        color: "rgb(208, 191, 169)"
      },
      {
        level: "3",
        label: "50 - 75%",
        color: "rgb(230,170,94)"
      },
      {
        level: "4",
        label: "75 - 100%",
        color: "rgb(180,180,116)"
      },
      {
        level: "5",
        label: "100%",
        color: "rgb(144, 193, 130)"
      },
    ];
    for (let level of levels)
      cards.push(new DashCardLiteInfo(data_levels[level["level"]] || 0, level["label"], null, level["color"], level["level"]));
    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;
  }
}

import { AppConfig } from "../app.config";
import { StatusBehaviours } from "../models/common";

import { TranslationService } from '../services/translation.service';
import { EventService } from '../services/event.service';
import { BuildingService } from '../services/building.service';
import { colorutils } from './colorutils';
import { dateutils } from "./dateutils";


export class workutils {


  public static async getBuildingOrSiteName(building_id: any, buildingService: BuildingService) {
    let building = building_id ? await buildingService.getBuilding(building_id).toPromise() : null;
    let building_name = building ? building.name : (AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site");
    return building_name;
  }

  /**
   * Gets the label matching with the according status
   * @param string_status the number of the behaviour in string form such as "0" "1" "2" ect...
   * @remarks : this is similar to getStatusLabel(StatusBehaviours[value]) assuming value is string representation of a number
   */
  public static getStatusLabelFromStringNumber(string_status: string): string {
    let parameter = AppConfig.back_settings.statuses["status_" + string_status];
    return parameter ? parameter.label : "";
  }

  public static getStatusLabel(status: StatusBehaviours): string {
    return workutils.getStatusLabelFromStringNumber(StatusBehaviours[status]);
  }

  public static isStatusActive(status: StatusBehaviours): boolean {
    let parameter = AppConfig.back_settings.statuses["status_" + StatusBehaviours[status]];
    return parameter ? parameter.active : false;
  }

  public static getAllowedStatuses(add_empty: boolean = false, key_name: string = "behaviour", label_name: string = "label"): any[] {
    let statuses = AppConfig.back_settings.statuses;
    let alloweds = [];
    for (let key in statuses) {
      let id = key.replace("status_", "");
      if (id == "0") {
        if (add_empty)
          alloweds.push({ [key_name]: id, [label_name]: "" });
      }
      else if (statuses[key].active)
        alloweds.push({ [key_name]: id, [label_name]: statuses[key].label });
    }
    return alloweds;
  }

  public static getReceptionLevels(): any[] {
    let levels = AppConfig.front_settings.reception_levels.levels;
    let alloweds = [];
    for (let level of levels)
      alloweds.push({ key: level.level, name: " [" + (level.level + "").padStart(2, "0") + "]  " + level.label });
    return alloweds;
  }

  /**
   * Fills the UI data such as translations, icons or colors
   * @param events the events to fill data for
   * @param translation_service the translation service
   */
  public static fillEventsData(events: any[], translation_service: TranslationService) {
    for (let event of events) {
      // fills the events states labels
      let type = event["sensor_type"];
      let state = event["sensor_state"];
      let label = translation_service.translate("Unknow");
      if (type == "temperature")
        label = state + " °C";
      else if (type == "humidity")
        label = state + " %";
      else
        label = type && state && state != "" ? translation_service.translate(state) : "";
      event["sensor_state_label"] = label;
      // fills the equipments colors and icons
      event["equipment_status_color"] = colorutils.getStatusColor(event["equipment_status_behaviour"]);
    }
  }

  /**
   * Gets the timeline for the event durations
   * @param duration_records
   */
  public static getTimeline(duration_records: any[]) {
    let timeline = [];
    for (let record of duration_records) {
      if ("report" in record) {
        let start_sec = new Date(record.report.start_time).getTime();
        let end_sec = new Date(record.report.end_time).getTime();
        let entry = workutils.getTimeLineEntry(record.report, start_sec, end_sec, 0, end_sec - start_sec);
        timeline.push(entry);
      }
    }
    return timeline.length > 0 ? timeline : null;
  }

  private static getTimeLineEntry(entry: any, start_sec, end_sec, level, main_local) {
    let total = end_sec - start_sec;
    let local = new Date(entry.end_time).getTime() - new Date(entry.start_time).getTime();
    let timeline_entry = {
      name: entry.name,
      start: new Date(entry.start_time).getTime() - start_sec,
      end: new Date(entry.end_time).getTime() - start_sec,
      level: level
    }
    timeline_entry["memory"] = (entry["end_mem"] - entry["start_mem"]);
    timeline_entry["duration"] = (timeline_entry["end"] - timeline_entry["start"]);
    timeline_entry["start_percent"] = 100 * timeline_entry["start"] / total;
    timeline_entry["end_percent"] = 100 * timeline_entry["end"] / total;
    timeline_entry["duration_percent"] = 100 * timeline_entry["duration"] / total;
    timeline_entry["local_percent"] = 100 * timeline_entry["duration"] / main_local;
    timeline_entry["duration_string"] = Math.floor(timeline_entry["duration"] / 1000) + " s " + Math.floor(timeline_entry["duration"] % 1000).toString().padStart(3, "0") + " ms ";
    timeline_entry["children"] = []
    if ("children" in entry)
      for (let child of entry.children)
        timeline_entry["children"].push(workutils.getTimeLineEntry(child, start_sec, end_sec, level + 1, local))
    return timeline_entry;
  }

  static getSensorImage(device_type) {
    switch (device_type) {
      case "gateway":
        return "../../../assets/img/items/gateway40.png";
      case 'touch':
        return "../../../assets/img/items/touch40.png";
      case 'proximity':
        return "../../../assets/img/items/proximity40.png";
      case 'temperature':
        return "../../../assets/img/items/temperature40.png";
      case 'humidity':
        return "../../../assets/img/items/humidity40.png";
      case 'waterDetector':
        return "../../../assets/img/items/water40.png";
      case 'movement':
        return "../../../assets/img/items/movement40.png";
      case 'sensor':
        return "../../../assets/img/items/sensor40.png";
      case 'precision':
        return "../../../assets/img/items/precision40.png";
      case 'badFloor':
        return "../../../assets/img/items/badfloor40.png";
      default:
        return "../../../assets/img/items/unknow40.png";
    }
  }

  static getSensorMeasureTypes(template) {
    let sensor_type = template["sensor_type"];
    // all sensors can be touched
    let measure_types = ["touch"]
    if (sensor_type == "temperature") {
      measure_types.push("temperature");
      if (template["calculate_temperature_internal"])
        measure_types.push("temperature_internal");
    }
    else if (sensor_type == "humidity") {
      measure_types.push("temperature");
      measure_types.push("humidity");
      if (template["calculate_temperature_internal"])
        measure_types.push("temperature_internal");
    }
    else if (sensor_type == "touch") {
      // already managed
    }
    else if (sensor_type == 'proximity') {
      measure_types.push("proximity");
    }
    else if (sensor_type == 'waterDetector') {
      measure_types.push("water");
    }
    else if (sensor_type == 'movement') {
      measure_types.push("movement");
    }
    return measure_types;
  }

  private static setMessage(document, label_name, message) {
    let span = document.getElementById(label_name);
    if (span != null)
      span.innerHTML = message;
  }

  /**
   * Sets the permanent touch for the specified sensor
   * @param eventService { EventService } an instance of EventService
   * @param sensor_cloud_id { string } the id of the specified sensor
   */
  public static setPermanentTouch(eventService: EventService, sensor_cloud_id: string) {
    eventService.setPermanentTouch(sensor_cloud_id, 3600)
  }

  /**
   * Gets the permanent touch of a sensor if it is
   * @param eventService { EventService } an instance of EventService
   * @returns the permanent touch of a sensor or null if it is'nt
   */
  public static async getPermanentTouch(eventService: EventService) {
    let start_time = new Date();
    let touch = await eventService.getLastTouchSensor(start_time).toPromise();
    if (!touch || !touch.update_time)
      return null;
    touch["delay"] = dateutils.formatDurationHHMM(new Date(touch.update_time).getTime() - start_time.getTime());
    return touch;
  }

  /**
   * Listen to the last sensor touched
   * @param eventService { EventService } an instance of EventService
   * @param document { Document } the entry point into the web page's content
   * @param label_name { string } the label to used
   * @param onTouched { function } a function that detects if the sensor is touched
   */
  public static listenLastTouchSensor(eventService: EventService, document: Document, label_name: string, onTouched) {
    let counter = 20;
    let start_time = new Date();
    this.setMessage(document, label_name, "...");
    // iterates every 1 s to check last touch
    let interval = setInterval(() => {
      this.setMessage(document, label_name, counter);
      // after 20 s we stop the watching
      if (counter <= 0) {
        clearInterval(interval)
        this.setMessage(document, label_name, "---");
      }
      eventService.getLastTouchSensor(start_time).subscribe((last_touch) => {
        if (last_touch) {
          clearInterval(interval);
          onTouched(last_touch);
        }
      });
      counter--;
    }, 1000);
  }

  /**
   * Listen to the last touched touched
   * @param eventService { EventService } an instance of EventService
   * @param document { Document } the entry point into the web page's content
   * @param label_name { string } the label to used
   * @param onTouched { function } a function that detects if the sensor is touched
   */
  public static listenLastTouchGateway(eventService: EventService, document: Document, label_name: string, onTouched) {
    let counter = 20;
    let start_time = new Date();
    this.setMessage(document, label_name, "...");
    // iterates every 1 s to check last touch
    let interval = setInterval(() => {
      this.setMessage(document, label_name, counter);
      // after 20 s we stop the watching
      if (counter <= 0) {
        clearInterval(interval)
        this.setMessage(document, label_name, "---");
      }
      eventService.getLastTouchGateway(start_time).subscribe((last_touch) => {
        if (last_touch) {
          clearInterval(interval);
          onTouched(last_touch);
        }
      });
      counter--;
    }, 1000);
  }

  public static getReadableMeasure(translationService: TranslationService, type: string, event: any) {
    let state = type == "touch" ? event["touch_count"] : event["sensor_" + type];
    if (state == null)
      return translationService.translate("Unknown");
    let value = state;
    if ((type == 'temperature') || (type == 'temperature_internal'))
      value = state + " °C";
    else if (type == 'humidity')
      value = state + " %";
    else if (type == 'touch')
      value = translationService.translate(state > 0 ? "Touched" : "Waiting");
    else if (type == 'proximity')
      value = translationService.translate(state ? "Closed" : "Opened");
    else if (type == 'water')
      value = translationService.translate(state ? "Water present" : "No water");
    else if (type == 'movement')
      value = translationService.translate(state ? "Moving" : "Static");
    return value;
  }
}
<!--<div class="editor" [ngStyle]="{ 'width': width ?? '' }">

  <label *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">{{label}} : </label>

  <input type="text" [name]="name" [(ngModel)]="value" [disabled]="_disabled" style="width:100%;" [ngStyle]="{ 'text-align': align ?? 'left' }" />

</div>-->

<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">
  <div [ngStyle]="{ 'width': label_width ?? '' }">
    <label *ngIf="label">{{label}} : </label>
  </div>
  <textarea type="text" [name]="name" [(ngModel)]="value" [disabled]="_disabled" [ngStyle]="{ 'height': height ?? '' }"></textarea>

  <!--<textarea type="text" matInput name="stack" [(ngModel)]="record.stack" #stack="ngModel" style="height: 160px; color:darkred;" disabled></textarea>-->

</div>

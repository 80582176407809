import { Size, Point } from '../../../models/types';
import { AppConfig } from '../../../app.config';
import { colorutils } from '../../colorutils';
import { GlyphColor, Glyph } from '../../../models/common';
import { FloorPlanComponent } from '../floor-plan.component';
import { mathutils } from '../../mathutils';


/**
 * Manage the layout values to scale the points for drawing
 * */
export class FloorLayout {

  public image_size: Size;
  public floor_size: Size;
  private aspect_ratio: Point;
  private zoom_ratio: number;
  private zoom_shift: Point;

  private precision1: number = 7.5;
  private precision2: number = 15;

  private plan_component: FloorPlanComponent = null;

  public get ZoomRatio(): number {
    return this.zoom_ratio;
  }

  public get ZoomShift(): Point {
    return this.zoom_shift;
  }

  public get AspectRatio(): Point {
    return this.aspect_ratio;
  }

  public get Image(): HTMLImageElement {
    return this.plan_component.Image;
  }

  public get MapImage(): HTMLImageElement {
    return this.plan_component.MapImage;
  }

  public get Canvas(): HTMLCanvasElement {
    return this.plan_component.Canvas;
  }

  public get CommandBar(): HTMLDivElement {
    return this.plan_component.CommandBar;
  }

  public get Context(): CanvasRenderingContext2D {
    return this.Canvas.getContext("2d");
  }

  /**
   * Initialize the layout sizes to determinates the ratios.
   * @param floorWidth the x coordinate in meters.
   * @param floorHeight the y coordinate in meters.
   */
  constructor(/*document: Document,*/ floorWidth: number, floorHeight: number, plan_component: FloorPlanComponent = null) {
    // this.document = document;
    this.plan_component = plan_component;

    if (AppConfig.front_settings && AppConfig.front_settings.precision.gateway1)
      this.precision1 = AppConfig.front_settings.precision.gateway1;
    if (AppConfig.front_settings && AppConfig.front_settings.precision.gateway2)
      this.precision2 = AppConfig.front_settings.precision.gateway2;

    this.floor_size = new Size(floorWidth, floorHeight);
    this.setZoom(0, 0, 1);
    this.updateImageSize();
  }

  /**
   * Recalculate the ratios when the image is resized.
   * @param imageWidth the image width in pixels.
   * @param imageHeight the image height in pixels.
   */
  public updateImageSize() {
    var image = this.Image;
    if (!image)
      return;
    this.image_size = new Size(image.clientWidth, image.clientHeight);
    var xRatio = this.image_size.width / this.floor_size.width;
    var yRatio = this.image_size.height / this.floor_size.height;
    this.aspect_ratio = new Point(xRatio, yRatio);
    // updates the canvas size
    var canvas = this.Canvas;
    var command = this.CommandBar;
    if (canvas) {
      canvas.width = this.image_size.width;
      canvas.height = this.image_size.height;
      command.style.width = this.image_size.width + 4 + "px";
    }
    this.drawPlan();
  }

  /**
   * Sets the zooming parameters
   * @param shift_x the x shift in meter
   * @param shift_y the y shift in meter
   * @param zoom the zoom ratio
   */
  public setZoom(shift_x: number, shift_y: number, zoom: number) {
    this.zoom_ratio = zoom;
    this.zoom_shift = new Point(shift_x, shift_y);
  }

  public getDrawshift() {
    return new Point(this.zoom_shift.x * this.aspect_ratio.x, this.zoom_shift.y * this.aspect_ratio.y)
  }

  public drawPlan() {
    var canvas = this.Canvas;
    if (!canvas || canvas.width < 20 || canvas.height < 20)
      return;
    let context = this.Context;
    context.scale(this.zoom_ratio, this.zoom_ratio)
    let shift = this.getDrawshift();
    try {
      context.drawImage(this.Image, shift.x / this.zoom_ratio, shift.y / this.zoom_ratio, (canvas.width), (canvas.height));
    } catch (e) {
    }

    if (!!this.MapImage && this.MapImage.naturalWidth > 0) {
      context.globalAlpha = 0.5;
      context.drawImage(this.MapImage, shift.x / this.zoom_ratio, shift.y / this.zoom_ratio, (canvas.width), (canvas.height));
      context.globalAlpha = 1;
    }
  }

  /**
   * Gets the point to draw from the real location (in meter).
   * @param realX the x coordinate in meters.
   * @param realY the y coordinate in meters.
   * @param for_div gets if the point should be limited to the drawn region (for the devices)
   */
  public getDrawPoint(realX: number, realY: number, for_div: boolean): Point {
    var point;
    // calculate pixels from meters
    point = new Point(realX * this.aspect_ratio.x, realY * this.aspect_ratio.y);
    // zoom the view
    let shift = this.getDrawshift();
    point = new Point(point.x * this.zoom_ratio + shift.x, point.y * this.zoom_ratio + shift.y)
    // for the drawing we must divide by ratio
    if (!for_div)
      point = new Point(point.x / this.zoom_ratio, point.y / this.zoom_ratio)
    // exclude the objects which are outside the draw
    var canvas = this.Canvas;
    if (!!canvas)
      if (for_div && (point.x < 0 || point.x > canvas.width || point.y < 0 || point.y > canvas.height))
        return null;
    // center the image of the object
    if (for_div)
      point = new Point(point.x - 20, point.y - 20);

    return point;
  }

  getRealPoint(drawX: number, drawxY: number): Point {
    let point;
    // un-zoom
    let shift = this.getDrawshift();
    point = new Point(((drawX - shift.x) / this.zoom_ratio), (drawxY - shift.y) / this.zoom_ratio);
    // calculate meters from pixels
    point = new Point(point.x / this.aspect_ratio.x, point.y / this.aspect_ratio.y);
    return point;
  }

  /**
   * Gets the length x and y to draw an ellipse (as both ratios are not identical).
   * @param length
   */
  getDrawLength(length: number): Point {
    return new Point(length * this.aspect_ratio.x /** this.zoom_ratio*/, length * this.aspect_ratio.y /** this.zoom_ratio*/);
  }

  /**
   * Gets a point which is in the layout and on the circle.
   * @param realX the x coordinate of the center of the circle.
   * @param realY the y coordinate of the center of the circle.
   * @param realRadius the radius of the circle where the device can be located.
   */
  getAllowedPoint(realX: number, realY: number, realRadius: number): Point {
    for (let angle = 0; angle < Math.PI * 2; angle += Math.PI / 16) {
      let point = new Point(realX + realRadius * Math.cos(angle), realY + realRadius * Math.sin(angle));
      if (this.isInLayout(point))
        return point;
    }
    return new Point(realX + realRadius * Math.cos(0), realY + realRadius * Math.sin(0));
  }

  isInLayout(point: Point): boolean {
    return (point.x <= this.floor_size.width) && (point.x >= 0) &&
      (point.y <= this.floor_size.height) && (point.y >= 0);
  }

  public clear() {
    this.Context.clearRect(0, 0, this.Canvas.width, this.Canvas.height);
  }

  public drawWalls(room: any, line_color: string, selected: boolean = false, fill: boolean = true, draw_title: boolean = false, close: boolean = true, draw_walls: boolean = true) {
    let fill_color = selected ? "rgba(255, 200, 200, 0.5)" : "rgba(200, 200, 255, 0.5)";
    let context = this.Context;
    if (room.walls.length < 2)
      return;
    if (draw_walls) {
      if (room.percent)
        context.fillStyle = colorutils.getLigthPercent(1 - room.percent, colorutils.HGreen, 0.3, 1, 1);
      else
        context.fillStyle = fill_color;
      context.strokeStyle = line_color;
      context.lineWidth = selected ? 2 : 2;
      context.beginPath();
      let intial_point = this.getDrawPoint(room.walls[0].x, room.walls[0].y, false);
      context.moveTo(intial_point.x, intial_point.y);
      for (let i = 1; i < room.walls.length; i++) {
        let point = this.getDrawPoint(room.walls[i].x, room.walls[i].y, false);
        context.lineTo(point.x, point.y);
      }
      if (close || fill)
        context.closePath();
      if (fill)
        context.fill();
    }

    if (draw_title) {
      let center = mathutils.getCenter(room.walls);
      let intial_point = this.getDrawPoint(center.x, center.y, false);
      context.fillStyle = line_color;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.font = "bold 10px sans-serif";
      context.fillText(room.name, intial_point.x, intial_point.y);
    }
    context.stroke();
  }

  public drawPaths(room: any, line_color: string, selected: boolean = false) {
    let fill_color = selected ? "rgba(255, 200, 200, 0.5)" : "rgba(200, 200, 255, 0.5)";
    let context = this.Context;
    if (room.paths.length < 2)
      return;

    context.fillStyle = fill_color;
    context.strokeStyle = line_color;
    context.lineWidth = selected ? 2 : 2;
    context.beginPath();
    let intial_point = this.getDrawPoint(room.paths[0].x, room.paths[0].y, false);
    context.moveTo(intial_point.x, intial_point.y);
    for (let i = 1; i < room.paths.length; i++) {
      let point = this.getDrawPoint(room.paths[i].x, room.paths[i].y, false);
      context.lineTo(point.x, point.y);
    }

    context.stroke();
  }

  public getLeftPoint(walls: []): any {
    let point = null
    for (var i = 0; i < walls.length; i++) {
      if (!point || point.x > walls[i]["x"] || ((point.x == walls[i]["x"]) && (point.y > walls[i]["y"]))) {
        point = walls[i];
      }
    }

    return { "x": point.x, "y": point.y };
  }

  public getTopLeft(walls: []): any {
    let x = Math.min(...walls.map(wall => wall["x"]));
    let y = Math.min(...walls.map(wall => wall["y"]));
    return { "x": x, "y": y };
  }

  public getBottomRight(walls: []): any {
    let x = Math.max(...walls.map(wall => wall["x"]));
    let y = Math.max(...walls.map(wall => wall["y"]));
    return { "x": x, "y": y };
  }

  /**
  * Draws the line between 2 point to render a wall.
  */
  public drawWall(color, posX1, posY1, posX2, posY2) {
    let context = this.Context;
    context.beginPath();
    let point1 = this.getDrawPoint(posX1, posY1, false);
    let point2 = this.getDrawPoint(posX2, posY2, false);
    context.lineCap = 'round';
    context.moveTo(point1.x + 0, point1.y + 0);
    context.lineTo(point2.x + 0, point2.y + 0);
    context.lineWidth = 2;
    context.strokeStyle = color;
    context.stroke();
  }

  /**
   * Draws the unilataration circle wich is the presence probality area of the device.
   */
  drawUnilaterationCircle(fill_color, stroke_color, posX, posY, radius, precision) {
    let context = this.Context;
    context.beginPath();
    let point = this.getDrawPoint(posX, posY, false);
    let length = this.getDrawLength(radius);
    let size = this.getDrawLength(precision * 2).x;
    context.ellipse(point.x, point.y, length.x, length.y, 0, 0, 2 * Math.PI, true);
    // the size musn't the be superior to the diameter
    context.lineWidth = Math.min(size, length.x * 2);
    context.strokeStyle = stroke_color;
    context.fillStyle = fill_color;
    context.stroke();
  }

  /**
   * Draws the line between the 2 potentials locations of the device for bilateration.
   */
  drawBilaterationLine(fill_color, stroke_color, posX1, posY1, posX2, posY2) {
    let context = this.Context;
    context.beginPath();
    let point1 = this.getDrawPoint(posX1, posY1, false);
    let point2 = this.getDrawPoint(posX2, posY2, false);
    context.lineCap = 'round';
    context.moveTo(point1.x + 0, point1.y + 0);
    context.lineTo(point2.x + 0, point2.y + 0);
    context.setLineDash([30, 30]); // dashes are 30px and spaces are 30px*/
    context.lineWidth = 20;
    context.strokeStyle = stroke_color;
    context.fillStyle = fill_color;
    context.stroke();
  }

  /**
   * Draws the trilateration circle to show accuracy uncertitude arbitrary set to five metter
  */
  drawTrilaterationCircle(fill_color, stroke_color, posX, posY, radius) {
    let context = this.Context;
    context.beginPath();
    let point = this.getDrawPoint(posX, posY, false);
    let length = this.getDrawLength(radius);
    context.beginPath();
    context.ellipse(point.x, point.y, length.x, length.y, 0, 0, 2 * Math.PI, true);
    context.lineWidth = 1;
    context.strokeStyle = stroke_color;
    context.fillStyle = fill_color;
    context.fill();
  }

  public drawElement(device: any, color: GlyphColor, draw_event_count: boolean = false) {
    let position = this.getDrawPoint(device.x, device.y, false);
    let glyph = device.glyph ? device.glyph : Glyph.Circle;
    var context: CanvasRenderingContext2D = this.Context;
    context.lineWidth = 1;
    context.beginPath();
    this.drawItem(context, position, glyph, color);

    if ("draw_coverage" in device && device["draw_coverage"]) {
      let level1_color = '#1B7D5642';
      this.drawTrilaterationCircle(level1_color, level1_color, device.x, device.y, this.precision2);
    }
    // if the field plan_label is setted, we draw it
    if ("plan_label" in device)
      this.drawCount(context, position, device.plan_label, color);

    if ("label" in device)
      this.drawCount(context, position, device.label, color);
    if ("label_ext" in device)
      this.drawCount(context, position, device.label_ext, color, true);

    // draw event count is used in survey
    if (draw_event_count && device.event_count != undefined)
      this.drawCount(context, position, device.event_count, color);

    context.stroke();
  }

  private drawItem(context: CanvasRenderingContext2D, position: any, glyph: Glyph, color: GlyphColor) {
    // position and sizes are in pixels
    context.beginPath();
    context.strokeStyle = color;
    //draws the glyph
    let length = new Point(8, 8);
    if (glyph == Glyph.Square)
      context.strokeRect(position.x - length.x, position.y - length.y, length.x * 2, length.y * 2);
    else if (glyph == Glyph.Circle)
      context.ellipse(position.x, position.y, length.x, length.y, 0, 0, 2 * Math.PI, true);
    else if (glyph == Glyph.Diamond)
      this.drawDiamond(context, position);
    else if (glyph == Glyph.Triangle)
      this.drawTriangle(context, position);
    context.stroke();
    if (color == GlyphColor.YellowRed)
      context.strokeStyle = GlyphColor.Red;
    // draws the cross
    if (glyph == Glyph.Circle) {
      context.beginPath();
      let left = new Point(position.x - 6, position.y);
      let right = new Point(position.x + 6, position.y);
      let top = new Point(position.x, position.y - 6);
      let bottom = new Point(position.x, position.y + 6);
      context.moveTo(left.x, left.y);
      context.lineTo(right.x, right.y);
      context.moveTo(top.x, top.y);
      context.lineTo(bottom.x, bottom.y);
      context.stroke();
    }
  }

  private drawDiamond(context: CanvasRenderingContext2D, position: any) {
    // draws the cross
    let left = new Point(position.x - 8, position.y);
    let right = new Point(position.x + 8, position.y);
    let top = new Point(position.x, position.y - 8);
    let bottom = new Point(position.x, position.y + 8);

    context.moveTo(left.x, left.y);
    context.lineTo(top.x, top.y);
    context.lineTo(right.x, right.y);
    context.lineTo(bottom.x, bottom.y);
    context.lineTo(left.x, left.y);
  }

  private drawTriangle(context: CanvasRenderingContext2D, position: any) {
    // draws the cross
    let point_1 = new Point(position.x, position.y - 8);
    let point_2 = new Point(position.x + 8, position.y + 6);
    let point_3 = new Point(position.x - 8, position.y + 6);
    context.moveTo(point_1.x, point_1.y);
    context.lineTo(point_2.x, point_2.y);
    context.lineTo(point_3.x, point_3.y);
    context.lineTo(point_1.x, point_1.y);
  }

  private drawCount(context: CanvasRenderingContext2D, position: any, count: string, color: GlyphColor, ext: boolean = false) {
    context.beginPath();
    context.fillStyle = !!count ? color : GlyphColor.Red;
    context.fillText(count + "", position.x + 9, position.y + (ext ? -1 : 8));
    context.stroke();
  }
}
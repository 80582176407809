import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";
import { ItemKinds, LocationModes, LocationModesText } from "../../models/common";
import { AppConfig } from "../../app.config";
import { logicutils } from "../logicutils";
import { TranslationService } from "../../services/translation.service";
import { EquipmentUpdComponent } from "../../views/forms/equipment/equipment-upd/equipment-upd.component";
import { MatDialog } from "@angular/material/dialog";
import { SensorUpdComponent } from "../../views/forms/sensor/sensor-upd/sensor-upd.component";


@Component({
  selector: "app-info-ribbon",
  templateUrl: './info-ribbon.component.html',
})
export class InfoRibbonComponent extends BaseWorkComponent {

  @Output() onDateRangeApply = new EventEmitter();
  @Output() onDateRangePlay = new EventEmitter();
  @Output() onLocationModeChange = new EventEmitter();
  @Output() onFilterChange = new EventEmitter();

  public disable_refresh = false;
  public isloading = true;

  public is_admin: boolean;
  public rights: any;

  public is_location_view: boolean;
  public start_date: Date;
  public end_date: Date;
  public location_modes: LocationModes[];
  public location_mode: LocationModes;

  public date_range = 1;//365;
  public show_states: boolean = false;
  public show_statuses: boolean = true;
  public show_customs: boolean = false;

  public current_event: any;


  get loading(): boolean { return this.isloading; }
  @Input() set loading(value: boolean) { this.isloading = value; }

  constructor(
    protected router: Router,
    protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService,
    public dialog: MatDialog
  ) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
    this.location_modes = logicutils.getEnumNames(LocationModes, LocationModesText);
    this.location_mode = LocationModes.Sensors;
    if (AppConfig.front_settings && AppConfig.front_settings.views.default_location_mode)
      this.location_mode = AppConfig.front_settings.views.default_location_mode;
    this.onLocationModeChange.emit({ "location_mode": this.location_mode });
  }

  public initialize(is_admin: boolean, rights: any, is_location_view: boolean, item_kind: ItemKinds) {
    this.is_location_view = is_location_view;
    this.item_kind = item_kind;
    this.is_admin = is_admin;
    this.rights = rights;
    this.resetDateRange();
    this.filterChanged();
  }

  public setEvent(current_event: any) {
    this.current_event = current_event;
    //this.setItemImage(this.current_event);
  }

  // User events and routing
  // ***********************

  public filterChanged() {
    this.onFilterChange.emit({ "show_states": this.show_states, "show_statuses": this.show_statuses, "show_customs": this.show_customs });
  }

  public locationModeChanged(evt) {
    this.location_mode = parseInt((evt.target.value.split(":")[1].trim()));
    this.onLocationModeChange.emit({ "location_mode": this.location_mode });
  }

  public navigateEquipment() {
    EquipmentUpdComponent.openDialog(this.dialog, this.current_event.equipment_id, () => {});
  }

  public navigateSensor() {
    SensorUpdComponent.openDialog(this.dialog, this.current_event.sensor_id, () => {});
  }


  // Date range methods
  // ***********************

  public resetDateRange() {
    var today = new Date();
    var minDate = new Date();
    minDate.setDate(today.getDate() - this.date_range);
    minDate.setHours(today.getHours());
    this.start_date = minDate;
    this.end_date = today;
    this.fillHistory();
  }

  public fillHistory() {
    this.onDateRangeApply.emit({ "start_date": this.start_date, "end_date": this.end_date });
  }

  public playHistory() {
    this.onDateRangePlay.emit({ "start_date": this.start_date, "end_date": this.end_date });
  }

  public setDisabledRefresh(disable_refresh: boolean) {
    this.disable_refresh = disable_refresh;
  }
}
import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { ErrorLevel } from "../enums";
import { navutils } from "../navutils";
import { EditorBase } from "./EditorBase";

/**
 * @example <app-datetime-editor label="From" [datekind]="datekind" [(ngModel)]="date_from" width="100px" disabled></app-datetime-editor>
 */
@Component({
  selector: 'app-image-editor',
  templateUrl: 'image.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageEditor),
      multi: true
    }
  ]
})
export class ImageEditor extends EditorBase<string> {

  @Input() public image_width: number = 0;
  @Input() public image_height: number = 0;

  protected message: string = "";

  constructor(private dialog: MatDialog) {
    super();
  }

  protected OnFileSelected(event: any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    var editor = this;
    this.message = "";

    reader.onload = (event: any) => {

      if (!event.target.result.startsWith("data:image/png;base64,")) {
        navutils.openDialogMessage(this.dialog, 'Erreur', "Ce fichier n'est pas une image valide", ErrorLevel.Error);
        return;
      }
      var img = document.createElement("img");
      img.src = event.target.result;
      img.onload = async function () {

        if (editor.image_width && editor.image_height)
          if (img.width != editor.image_width || img.height != editor.image_height) {
            editor.message = "La taille de l'image doit être " + editor.image_width + " x " + editor.image_height;
            return;
          }
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height
        let context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        context?.drawImage(img, 0, 0, img.width, img.height);
        let dataurl = canvas.toDataURL("image/png");
        editor.value = dataurl.replace("data:image/png;base64,", "");
      }
    };
  }

  protected ClearImage() {
    this.value = "";
  }
}

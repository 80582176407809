import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { colorutils } from '../colorutils';
import { ErrorLevel } from '../enums';


@Component({
  selector: "app-dialog-message",
  templateUrl: './dialog-message.component.html',
})
export class DialogMessageComponent {

  public title: string;
  public label_skin: string = "msg-info";
  public btn_skin: string = "btn-info";

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; buttons: any; single_button: boolean; error_level: ErrorLevel },
  ) {
    this.title = data.title;
    this.label_skin = colorutils.getMessageErrorClass(data.error_level);
    this.btn_skin = colorutils.getButtonErrorClass(data.error_level);
  }
}
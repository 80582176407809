import { Component, Input } from '@angular/core';
import { colorutils } from '../colorutils';
import { ErrorLevel } from '../enums';

/**
 * Component used to display messages according to error level
 */
@Component({
  selector: "app-message",
  templateUrl: './message.component.html',
})
export class MessageComponent {
  /**
   * The class to use for displaying the message
   */
  public message_class: string = "msg-error";

  /**
   * The message to display
   */
  @Input() message: string;
  private _level: ErrorLevel;

  /**
   * Gets the error level used to set the class of the message
   */
  get level(): ErrorLevel {
    return this._level;
  }
  /**
   * Set the class for the message according to the error level
   */
  @Input() set level(error_level: ErrorLevel) {
    this._level = error_level;
    this.message_class = colorutils.getMessageErrorClass(this.level);
  }

  /**
   * Initialize a new instance of message component
   */
  constructor() {
  }
}
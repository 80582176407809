
<app-page-title title="{{title}}" mode="popup_mode"></app-page-title>

<div style="margin: 20px">

  <div *ngIf="initialized" class="row col-md-12 nopadding">
    <h3>{{building_name}} - {{floor.name}}&nbsp;</h3>
    <h4 style="margin-top:2px;">({{floor.dimension.width | number:'1.2-2'}}m x {{floor.dimension.height | number:'1.2-2'}}m)</h4>
    <div *ngIf="message" class="ribbon-message msg-warn" style="float:right; margin-left: auto;">{{message}}</div>
  </div>

  <div class="row col-md-12 nopadding">
    <app-floor-plan #plan_view id="plan_view" (onImageClick)="onImageClick($event)"></app-floor-plan>
  </div>

  <app-form-button (click)="saveForm()" text="Save" icon="ok"></app-form-button>
  <app-form-button (click)="cancelForm()" text="Cancel" icon="cancel"></app-form-button>

</div>

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FigureBase } from "./FigureBase";
import { FullPoint } from "./FullPoint";
import { PlanDrawer } from "./PlanDrawer";
import { PlanLayout } from "./PlanLayout";
import { ZoomPoint } from "./PlanPoint";
import { PlanCommandComponent } from "./plan-command.component";
import { DrawMode } from "./DrawMode";
import { FullPolyLine } from "./FullPolyLine";
import { FullLine } from "./FullLine";
import { IFigure } from "./IFigure";
import { mathutils } from "../mathutils";
import { Point2D } from "./common/Point2D";
import { PlanLayerBase } from "./PlanLayer";


@Component({
  selector: 'app-plan ',
  templateUrl: 'plan.component.html',
})
export class PlanComponent implements OnInit, AfterViewInit {

  public OnSelectPoint: (point: FullPoint) => Promise<void>;
  public OnMovePoint: (origin: Point2D, target: Point2D) => Promise<void>;
  public OnAddFigure: (figure: IFigure) => Promise<void>;

  @ViewChild("plan_main_image", { static: true }) public image!: ElementRef<HTMLImageElement>;
  @ViewChild("plan_draw_canvas", { static: true }) public canvas!: ElementRef<HTMLCanvasElement>;

  @ViewChild("plan_command", { static: true }) public command!: PlanCommandComponent;

  private context: CanvasRenderingContext2D;
  protected bitmap: string;
  protected show_command: boolean = true;

  public layout: PlanLayout;
  public draw_mode: DrawMode = DrawMode.None;
  private can_zoom: boolean = true;

  private selected_points: FullPoint[] = [];
  private selected_origin: Point2D | null;
  public current_point: FullPoint | null;
  private current_figure: FullPolyLine | null;

  private shift_start: FullPoint | null;
  private line_start: FullPoint | null;

  public show_grid: boolean = false;

  public selectable_figures: string[] = [];
  public movable_figures: string[] = [];

  constructor() {
  }

  public get Image(): HTMLImageElement {
    return this.image.nativeElement;
  }

  public get Canvas(): HTMLCanvasElement {
    return this.canvas.nativeElement;
  }

  public get Context(): CanvasRenderingContext2D {
    return this.context;
  }

  public get Drawer(): PlanDrawer {
    return this.layout.Drawer;
  }

  public async ngOnInit(): Promise<void> {
  }

  public ngAfterViewInit(): void {
    let context = this.Canvas.getContext('2d');
    if (context)
      this.context = context;
  }

  public async Initialize(image: string | null, real_width: number, real_height: number, accuracy_draw: number | null, force_width: number = 0, show_command: boolean = !!image) {
    this.show_command = show_command;
    let img;
    if (!image) {
      this.bitmap = null;
      this.can_zoom = false;
      img = { width: real_width, height: real_height }
    } else {
      let force_height = (force_width) ? force_width * real_height / real_width : 0;
      await this.LoadImage(image, force_width, force_height);
      img = this.Image;
    }
    this.layout = new PlanLayout(this, real_width, real_height, img.width, img.height, accuracy_draw);
    this.Canvas.width = img.width;
    this.Canvas.height = img.height;
    this.command.Initialize(this);
    this.Redraw();
  }

  private async LoadImage(image: string, force_width: number = 0, force_height: number = 0) {
    return new Promise((resolve, reject) => {
      let img = this.Image;
      if (force_width && force_height) {
        img.width = force_width;
        img.height = force_height;
      }
      img.onload = async () => resolve(null);
      this.bitmap = image;
      // if the image is loaded from cache
      //if (img.complete)
      //  return resolve(null);
    })
  }

  public GetLayer<L extends PlanLayerBase>(TCreator: new () => L, name: string) {
    return this.layout.GetLayer(TCreator, name);
  }

  private ResetDraw() {
    this.current_point = null;
    this.line_start = null;
    this.current_figure = null;
    this.selected_points = [];
    this.shift_start = null;
    this.Clear("route");
    this.layout.SetCurrent(this.current_figure);
    //this.layout.SetCursor(this.current_point);
  }

  public Redraw() {
    if (!this.context)
      return;
    this.context.clearRect(0, 0, this.Canvas.width, this.Canvas.height);
    if (this.bitmap)
      this.context.drawImage(this.Image, this.layout.zoom_shift.X, this.layout.zoom_shift.Y,
        this.layout.plan_size.width * this.layout.zoom_ratio, (this.layout.plan_size.height * this.layout.zoom_ratio));
    this.layout.DrawFigures();
    // refreshes the zoom thumbnail
    if (this.show_command)
      this.command.RefreshThumb();
  }

  public Fill(color: string) {
    this.Drawer.Fill(color);
  }

  public ChangeMode(mode: number) {
    this.draw_mode = mode;
    this.ResetDraw();
    this.Redraw();
  }

  public ClearAll() {
    if (!this.layout)
      return;
    this.layout.ClearAll();
  }

  public Clear(key: string) {
    if (!this.layout)
      return;
    this.layout.ClearFigures(key);
  }

  protected OnResize(event: any) {
  }

  protected OnWheel(event: any) {
    if (!this.can_zoom)
      return;
    event.preventDefault();
    event.stopPropagation();
    let grow = event.wheelDelta > 0;
    let point = new ZoomPoint(event.offsetX, event.offsetY);
    this.layout.UpdateZoom(grow, point);
    this.Redraw();
  }

  public ChangeZoom(grow: boolean) {
    let point = new ZoomPoint(this.Canvas.width / 2, this.Canvas.height / 2);
    this.layout.UpdateZoom(grow, point);
    this.Redraw();
  }

  protected OnMouseDown(event: any) {
    this.shift_start = this.GetFullPoint(event);

    if (this.draw_mode == DrawMode.SelectPoint || this.draw_mode == DrawMode.MovePoint) {
      // gets the nearest points
      if (event.button == 0) {
        for (let point of this.selected_points) {
          point.fore_color = FigureBase.LineColor;
          point.back_color = FigureBase.FillColor;
        }
        let allowed_figures = this.draw_mode == DrawMode.SelectPoint ? this.selectable_figures : this.draw_mode == DrawMode.MovePoint ? this.movable_figures : [];
        this.selected_points = this.layout.GetNearestPoints(this.shift_start, allowed_figures);
        this.selected_origin = this.selected_points.length ? this.selected_points[0].GetPoint2D() : null;
        for (let point of this.selected_points) {
          point.fore_color = FigureBase.ConstructionColor;
          point.back_color = FigureBase.ConstructionBackColor;
        }
        this.Redraw();
      }
    }
  }

  protected OnMouseMove(event: any) {
    this.current_point = this.GetFullPoint(event);
    if (this.draw_mode == DrawMode.None || this.draw_mode == DrawMode.SelectPoint) {
    }
    else if (this.draw_mode == DrawMode.MovePoint) {
      //  moves the selected point
      if (event.button == 0) {
        if (this.selected_points.length) {
          for (let point of this.selected_points)
            point.SetLocation(this.current_point);
          this.Redraw();
        }
      }
    }
    else {
      if (!this.line_start) {
        this.layout.SetCurrent(this.current_point);
      } else {
        if (this.draw_mode == DrawMode.Polyline || this.draw_mode == DrawMode.Polygon) {
          if (!this.current_figure) {
            this.current_figure = FullPolyLine.GetFromFull(this.layout, [this.line_start, this.current_point])
            this.current_figure.closed = this.draw_mode == DrawMode.Polygon;
            this.layout.SetCurrent(this.current_figure);
          } else {
            this.current_figure.GetLastPoint()?.SetLocation(this.current_point);
          }
        }
        else if (this.draw_mode == DrawMode.Rectangle) {
          this.current_figure = FullPolyLine.GetRectangleFromFull(this.layout, [this.line_start, this.current_point])
          this.current_figure.closed = true;
          this.layout.SetCurrent(this.current_figure);
        }
        else {
          let line = FullLine.GetFromFull(this.layout, this.line_start, this.current_point);
          this.layout.SetCurrent(line);
        }
      }
      this.Redraw();
    }
  }

  protected async OnMouseUp(event: any) {
    // left button
    if (event.button == 0) {

      if (this.draw_mode == DrawMode.None) {
      }
      else if (this.draw_mode == DrawMode.SelectPoint) {
        this.DoSelectPoint(this.selected_points[0]);
      }
      else if (this.draw_mode == DrawMode.MovePoint) {
        if (this.selected_points.length) {
          // releases the selected point
          for (let point of this.selected_points)
            point.fore_color = FigureBase.LineColor;
          if (this.selected_origin)
            await this.DoMovePoint(this.selected_origin, this.selected_points[0].GetPoint2D());
          this.selected_points = [];
          this.selected_origin = null;
          this.ResetDraw();
          this.Redraw();
        }
      }
      else {
        let current_point = this.GetFullPoint(event);

        if (!this.line_start) {
          // sets the first point
          this.line_start = current_point;
          if (this.draw_mode == DrawMode.Polyline || this.draw_mode == DrawMode.Polygon) {
            this.current_figure = null;
            this.layout.SetCurrent(this.current_figure);
            this.Redraw();
          }
        } else {
          // draws the line
          let second_point = current_point;

          if (this.draw_mode == DrawMode.Line) {
            let line = FullLine.GetFromFull(this.layout, this.line_start, second_point);
            this.layout.SetCurrent(line);
            this.line_start = null;
            this.DoAddFigure(line);
          }
          else if (this.draw_mode == DrawMode.Polyline || this.draw_mode == DrawMode.Polygon) {
            if (!this.current_figure) {
              this.current_figure = FullPolyLine.GetFromFull(this.layout, [this.line_start, second_point])
              this.current_figure.closed = this.draw_mode == DrawMode.Polygon;
              this.layout.SetCurrent(this.current_figure);
            } else {
              if (this.current_figure.GetLastPoint()?.IsEqual(this.line_start)) {
                this.current_figure.RemoveLast();
                if (this.current_figure)
                  await this.DoAddFigure(this.current_figure);
                this.ResetDraw();
                this.Redraw();
                return;
              }
              else
                this.current_figure.AddPoint(second_point);
            }
            this.line_start = FullPoint.GetFromReal(this.layout, second_point.GetPoint2D());
          }
          else if (this.draw_mode == DrawMode.Rectangle) {
            if (this.current_figure)
              await this.DoAddFigure(this.current_figure);
            this.ResetDraw();
          }
          this.Redraw()
        }
      }
    }
    // right button
    if (event.button == 2) {
      if (!this.can_zoom)
        return;
      // moves the plan
      let dest_point = new ZoomPoint(event.offsetX, event.offsetY);
      if (this.shift_start)
        this.layout.SetZoom(this.layout.zoom_shift.X + (dest_point.X - this.shift_start.Zoom.X), this.layout.zoom_shift.Y + (dest_point.Y - this.shift_start.Zoom.Y));
      this.shift_start = null;
      this.Redraw();
    }
  }

  protected async OnDoubleClick(event: any) {
  }

  private async DoSelectPoint(point: FullPoint) {
    try {
      if (this.OnSelectPoint)
        await this.OnSelectPoint(point);
    } catch (e) {
      console.error(e)
    }
  }

  private async DoMovePoint(origin: Point2D, target: Point2D) {
    try {
      if (this.OnMovePoint)
        await this.OnMovePoint(origin, target);
    } catch (e) {
      console.error(e)
    }
  }

  private async DoAddFigure(figure: IFigure) {
    try {
      if (this.OnAddFigure)
        await this.OnAddFigure(figure);
    } catch (e) {
      console.error(e)
    }
  }

  public ChangeShift(direction: number) {
    /* direction :
        0
      3   1
        2
    */
    let curr_shift = this.layout.zoom_shift;
    let length = this.layout.accuracy_draw * 50;
    let shift = new Point2D(0, 0);
    if (direction == 0)
      shift = new Point2D(curr_shift.X, curr_shift.Y - length);
    else if (direction == 1)
      shift = new Point2D(curr_shift.X + length, curr_shift.Y);
    else if (direction == 2)
      shift = new Point2D(curr_shift.X, curr_shift.Y + length);
    else if (direction == 3)
      shift = new Point2D(curr_shift.X - length, curr_shift.Y);
    this.layout.SetZoom(shift.x, shift.y);
    this.Redraw();
  }

  private GetFullPoint(event: any): FullPoint {
    let point: FullPoint = FullPoint.GetFromZoom(this.layout, new Point2D(event.offsetX, event.offsetY));
    point = FullPoint.GetFromReal(this.layout, new Point2D(this.MagnetizeCoordinate(point.Real.X), this.MagnetizeCoordinate(point.Real.Y)));
    return point;
  }

  public MagnetizeCoordinate(coordinate: number) {
    return mathutils.RoundStep(coordinate, this.layout.accuracy_draw);
  }

  public AddRealPolyline(key: string, points: Point2D[], color: string, size: number = 3) {
    this.layout.AddFigure(key, FullPolyLine.GetFromReal(this.layout, points, false), color, "LightSteelBlue", size, false);
  }

  public AddRealPolygon(key: string, points: Point2D[], color: string, back_color: string, label: string | null = null) {
    this.layout.AddFigure(key, FullPolyLine.GetFromReal(this.layout, points, true), color, back_color, 1, false, label);
  }

  public AddRealLine(key: string, point1: Point2D, point2: Point2D, color: string, size: number = 3, draw_points: boolean = true, back_color: string = "LightSteelBlue") {
    this.layout.AddFigure(key, FullLine.GetFromReal(this.layout, point1, point2), color, back_color, size, draw_points);
  }

  public AddRealPoint(key: string, point1: Point2D, color: string, back_color: string | null = null, size: number = 6, label: string | null = null) {
    this.layout.AddFigure(key, FullPoint.GetFromReal(this.layout, point1), color, back_color || "LightSteelBlue", size, true, label);
  }

  public GetFigures(key: string): IFigure[] {
    return this.layout.GetFigures(key);
  }

  public SetPrecisionGrid(active: boolean) {
    this.show_grid = active;
    if (!active) {
      this.Clear("grid");
    } else {
      if (this.GetFigures("grid").length > 0)
        return;
      let size = this.layout.real_size;
      for (var y = 0; y < size.width; y += this.layout.accuracy_draw)
        this.AddRealLine("grid", new Point2D(y, 0), new Point2D(y, size.height), "LightBlue", 1, false);
      for (var y = 0; y < size.height; y += this.layout.accuracy_draw)
        this.AddRealLine("grid", new Point2D(0, y), new Point2D(size.width, y), "LightBlue", 1, false);
    }
    this.Redraw();
  }
}
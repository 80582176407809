
<div *ngIf="_has_time">
  <input class="form-control" type="date" required [disabled]="!enabled" style="float:left; width:58%; height:26px;"
         [ngModel]="(_date!=null) ? (_date | date:'yyyy-MM-dd') : null" (ngModelChange)="onDateChanged($event)" />
  <input class="form-control" type="time" required [disabled]="!enabled" style="float: right; width: 38%; height: 26px;"
         [ngModel]="(_date!=null) ? (_date | date:'HH:mm') : null" (ngModelChange)="onTimeChanged($event)" />
</div>

<div *ngIf="!_has_time">
  <input class="form-control" type="date" required [disabled]="!enabled" style="float: left; width: 100%; height: 26px;"
         [ngModel]="(_date!=null) ? (_date | date:'yyyy-MM-dd') : null" (ngModelChange)="onDateChanged($event)"/>
</div>
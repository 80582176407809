import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class EquipmentHistoryService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "equipmenthistory";

  /**
   * Initialize a new intance of EquipmentHistoryService
   * @param http
   * @param token
   */
  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * Get equipment history for an equipment
   */
  getEquipmentMaintenanceDates(equipment_id: string) {
    return this.getData("getEquipmentMaintenanceDates", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  /**
 * Get equipment history count for an equipment.
 */
  getEquipmentHistoryCount(equipment_id: string) {
    return this.getData("getEquipmentHistoryCount", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  /*
* Deletes all equipment history of an equipment
* */
  delEquipmentHistories(equipment_id: string, subscribeMethod) {
    return this.updData("delEquipmentHistories", { equipment_id: equipment_id }, subscribeMethod);
  }
}
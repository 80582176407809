import { Component, Input } from "@angular/core";
import { FormEditorBase } from './FormEditorBase';


/**
 * Input editor to use in forms to bind values and manage validation.
 * @code <app-form-date [field]="form.maintenance_date" no_time="true" [submitted]="submitted"></app-form-date>
 * */
@Component({
  selector: "app-form-date",
  templateUrl: './form-date.component.html',
  styles: []
})
export class FormDateComponent extends FormEditorBase {

  /**
   * a boolean property ="false" would be interpreted as true as "false" is evaluated true
   * so we use a property which by default is false, so if we set "true" it will be evaluated true
   * @example no_time="true"
   * @default : false (no usage of the attribute)
   */
  @Input() no_time: boolean = false;

  constructor() {
    super();
  }
}




<div class="card" style="border: none; background-color: #ddd;">
  <div class="card card-body blueprint" style="border: none; background-color: #ddd;" [ngStyle]="{'margin': (use_margin) ? '8px' : '0px'}">

    <div id="id_image-container" class="id_image-container" style="margin:0px 4px 4px 0px; padding:0px; background-color:#ddd;">

      <img id="id_main-image" class="id_main-image blueprint-image" style="visibility: hidden;" [src]="plan_image | safe: 'img'" (load)="onImageLoaded()" (window:resize)="onResize($event)">
      <img *ngIf="plan_map_image" id="id_map_image" class="id_map-image device" [src]="plan_map_image | safe: 'img'" (load)="onImageLoaded()" style="visibility: hidden; opacity: 0.5;">
      <canvas id="id_main_canvas" class="id_main_canvas device" style="border: solid 2px #2D6C9B;"  (wheel)="onWheelImage($event)" (mousedown)="onMouseDownImage($event)" (mousemove)="onMoveImage($event)" (mouseup)="onMouseUpImage($event)" oncontextmenu="return false"></canvas>

      <!-- draws the plan_gateways -->
      <div *ngIf="plan_gateways && plan_gateways.items.length > 0">

        <div *ngFor="let gateway of plan_gateways.items">
          <ng-template #tip_content>
            <div style="display:flex; width:100%">
              <img class="map-image" style="margin:8px;" [src]="gateway.icon | safe: 'img'" [style.filter]="gateway.icon_color_filter | safe: 'style'">
              <div style="text-align: left;" [innerHTML]="gateway.tooltip | safe: 'html'"></div>
            </div>
          </ng-template>

          <div *ngIf="gateway.point">
            <div [ngbTooltip]="tip_content" triggers="hover" class="device touch" id="{{gateway._id}}" [style.left.px]=gateway.point.x [style.top.px]=gateway.point.y (click)="onClickGateway(gateway.record)">
              <div class="map-image-circle gateway" *ngIf="gateway.icon">
                <img class="map-image" [src]="gateway.icon | safe: 'img'" [style.filter]="gateway.icon_color_filter | safe: 'style'">
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- draws the plan_items (sensor or equipment) -->
      <div *ngIf="plan_items && location_mode == 0">

        <div *ngFor="let item of plan_items.items">
          <ng-template #tip_content>
            <div style="display:flex; width:100%">
              <img class="map-image" style="margin:8px;" [src]="item.icon | safe: 'img'" [style.filter]="item.icon_color_filter | safe: 'style'">
              <div style="text-align: left;" [innerHTML]="item.tooltip | safe: 'html'"></div>
            </div>
          </ng-template>

          <div *ngIf="item.point && !item.hidden">
            <div class="device touch" [ngbTooltip]="tip_content" triggers="hover" [style.left.px]=item.point.x [style.top.px]=item.point.y (click)="onClickItem(item.record)">
              <div *ngIf="item.icon" [ngClass]="show_circle ? 'map-image-circle trilateration' : ''" [style.background-color]="item.record.circle_color">
                <img class="map-image" [src]="item.icon | safe: 'img'" [style.filter]="item.icon_color_filter | safe: 'style'">
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let group of plan_items.groups">
          <ng-template #tip_content>
            <div><b>&nbsp;{{group.items.length}} {{plan_items.items_label | locale}}</b></div>
          </ng-template>
          <div *ngIf="group.point">
            <div class="device touch" [ngbTooltip]="tip_content" triggers="hover" [style.left.px]=group.point.x [style.top.px]=group.point.y (click)="onClickGroup(group, $event)">
              <img class="map-image" src='../../../assets/img/items/group40.png' style="filter:invert(41%) sepia(17%) saturate(1968%) hue-rotate(163deg) brightness(85%) contrast(86%)">

              <div style="background: white; border: 1px #2D6C9B solid; border-radius: 4px; line-height: 12px;">
                <span *ngFor="let item of group.items" class="plan-group" style="padding:0px 2px; line-height:12px;" [style.filter]="item.icon_color_filter | safe: 'style'" [style.color]="black">
                  <img *ngIf="item.icon" class="map-image-lite" [src]="item.icon | safe: 'img'" [style.filter]="item.icon_color_filter | safe: 'style'">
                </span>
              </div>

            </div>
          </div>
        </div>

      </div>

      <!-- draws the scale if map mode is activated -->
      <div *ngIf="plan_scale_image" class="card" style="margin:8px 0px 8px 0px; padding:0px; background-color: white;">
        <img id="id_scale-image" class="id_scale-image device" [src]="plan_scale_image | safe: 'img'" (load)="onScaleImageLoaded()" style="visibility: hidden; opacity: 1;">
        <canvas id="id_scale_graduations" class="id_scale_graduations" style="margin:0px; background-color:transparent;"></canvas>
      </div>

      <div style="display:flex;">

        <!-- groups out sites -->
        <div *ngIf="plan_items && plan_items.group_site && plan_items.group_site.items.length>0" class="plan-list">
          <div class="plan-list-title">{{'Out of site' | locale}} : {{plan_items.group_site.items.length}} {{plan_items.items_label | locale}}</div>
          <div *ngFor="let item of plan_items.group_site.items" style="display:flex" class="plan-list" (click)="onClickItem(item.record)">
            <img class="map-image" style="margin:8px;" [src]="item.icon | safe: 'img'" [style.filter]="item.icon_color_filter | safe: 'style'">
            <span [innerHTML]="item.tooltip"></span>
          </div>
        </div>

        <!-- groups out location -->
        <div *ngIf="plan_items && plan_items.group_location && plan_items.group_location.items.length>0" class="plan-list">
          <div class="plan-list-title">{{'Out of range' | locale}} : {{plan_items.group_location.items.length}} {{plan_items.items_label | locale}}</div>
          <div *ngFor="let item of plan_items.group_location.items" style="display:flex" class="plan-list" (click)="onClickItem(item.record)">
            <img class="map-image" style="margin:8px;" [src]="item.icon | safe: 'img'" [style.filter]="item.icon_color_filter | safe: 'style'">
            <span [innerHTML]="item.tooltip"></span>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div id="id_command_bar" class ="id_command_bar" style="z-index: 99; background-color: #F7F9FC">
    <div *ngIf="can_zoom && !no_image" style="background: transparent; pointer-events: none;">
      <div style="float: right;">
        <app-form-button (click)="toggleToolBar(!show_tool_bar)" icon="chevron-circle-{{show_tool_bar ? 'up' : 'down'}}" width="28" height="20" style="margin: 0px; pointer-events:auto; "></app-form-button>
      </div>
    </div>

    <div *ngIf="can_zoom && show_tool_bar && !no_image" style="background-color: transparent; padding: 4px; border: 2px solid #2D6C9B; border-top:none; margin-top:0px; height: 76px;">

      <div style="float:left;">
        <div style="float:left;">
          <app-form-button (click)="onExportPlan()" text="Export png" icon="download"></app-form-button>
        </div>
        <div *ngIf="locations && locations.length>0" style="float:left">
          <span style="font-size:9px; margin:0px;"><label style="width:50px; margin:0px; font-size:9px;">{{'Find' | locale}}: </label></span>
          <input #searchroom style="width:100px;" (keyup)="onFindLocation($event)" />
          <app-form-button (click)="onFindLocation(searchroom.value, true)" icon="arrow-right"></app-form-button>
          <app-form-button (click)="searchroom.value = ''; onFindLocation('', true)" icon="ban"></app-form-button>
        </div>
        <!--<div style="clear: both;">
          <div style="font-size:9px; margin:0px;"><label style="width:100px; margin:0px; font-size:9px;">{{'Left click' | locale}} : </label>{{"Move the sensor (if draw mode and sensor selected)" | locale}}</div>
          <div style="font-size:9px; margin:0px;"><label style="width:100px; margin:0px; font-size:9px;">{{'Rigth click or arrows' | locale}} : </label>{{"Move on the plan" | locale}}</div>
          <div style="font-size:9px; margin:0px;"><label style="width:100px; margin:0px; font-size:9px;">{{'Mouse wheel or +/-' | locale}} : </label>{{"Zoom / unzoom the plan (zoom can't be less than 1)" | locale}}</div>
        </div>-->
      </div>

      <div style="float:right;">

        <div style="float:left; margin-left:24px;">&nbsp;</div>
        <div style="float:left">
          <div>
            <app-form-button (click)="doZoom(true)" icon="plus" width="{{button_width * 2}}" height="{{button_width}}">
            </app-form-button>
          </div>
          <div>
            <app-form-button (click)="doZoom(false)" icon="minus" width="{{button_width * 2}}"
              height="{{button_width}}">
            </app-form-button>
          </div>
        </div>

        <div style="float:left; margin-left:20px;">&nbsp;</div>

        <div style="float:left">
          <div style="float:left">
            <app-form-button (click)="doShift(3)" icon="arrow-left" width="{{button_width * 1.5}}"
              height="{{button_width * 2}}"></app-form-button>
          </div>
          <div style="float:left">
            <div>
              <app-form-button (click)="doShift(0)" icon="arrow-up" width="{{button_width * 2}}"
                height="{{button_width}}"></app-form-button>
            </div>
            <div>
              <app-form-button (click)="doShift(2)" icon="arrow-down" width="{{button_width * 2}}"
                height="{{button_width}}"></app-form-button>
            </div>
          </div>
          <div style="float:left">
            <app-form-button (click)="doShift(1)" icon="arrow-right" width="{{button_width * 1.5}}"
              height="{{button_width * 2}}"></app-form-button>
          </div>
        </div>

        <div style="float:left; margin-left:20px;">&nbsp;</div>

        <div style="float:left; height:62px; padding:0px">
          <canvas id="id_zoom_thumb" class="id_zoom_thumb" (wheel)="onWheelThumb($event)" (mousedown)="onMouseDownThumb($event)" (mouseup)="onMouseUpThumb($event)" style="border: solid 1px #2D6C9B; margin: 0px; background-color: #dddddd; height: 62px; "></canvas>
        </div>

        <div style="float:left; margin-left:28px;">&nbsp;</div>

      </div>
    </div>
  </div>


</div>


<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">
  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>
  <input *ngIf="datekind==0" type="datetime-local" [name]="name" [ngModel]="value | date:'yyyy-MM-ddTHH:mm:ss'" (ngModelChange)="value = GetDate($event)" [disabled]="_disabled" style="width:100%;">
  <input *ngIf="datekind==1" type="date" [name]="name" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="date = GetDate($event)" [disabled]="_disabled" style="width:100%;">
  <input *ngIf="datekind==2" type="time" [name]="name" [ngModel]="value | date:'HH:mm:ss'" (ngModelChange)="value = GetDate($event)" [disabled]="_disabled" style="width:100%;">
  <select *ngIf="datekind==3" type="text" [name]="name" [(ngModel)]="month" [disabled]="_disabled">
    <option *ngFor="let month of months" [value]="month.id">{{ month.name }}</option>
  </select>
  <select *ngIf="datekind==3 || datekind==4" type="text" [name]="name" [(ngModel)]="year" [disabled]="_disabled">
    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
  </select>
</div>

<div class="msg err" *ngIf="form?.submitted && required_error">Le champ {{label}} est obligatoire.</div>

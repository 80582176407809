import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { TokenService } from './token.service';
import { Router } from "@angular/router";
import { EMPTY, of } from 'rxjs';


import { AppConfig } from '../app.config';

import { CoreService } from './core_service';
import { logicutils } from '../common/logicutils';


/**
 * Base class for service managing the API URL and the standard queries
 */
export abstract class ServiceBase {

  // can not use translationService as it would cause circular reference between TranslationService and Settings Service
  // *******************************************************************************************************************
  constructor(protected http: HttpClient, protected token: TokenService, protected router: Router, protected core_service: CoreService) {
  }

  // root URL to call the API
  protected apiUrl = AppConfig.api_url;
  // service part of the url (specified in each real service)
  protected abstract serviceUrlPart: string;

  /**
   * Get the full url for the the gievn command.
   * @param urlCommandPart the command part of the full url
   */
  protected getUrl(urlCommandPart: string) {
    return this.apiUrl + "/" + this.serviceUrlPart + "/" + urlCommandPart;
  }

  /**
   * get the data from the API server
   * @param url_command the part of the URL specifying the kind of data to get.
   * @param filter the filter to apply on the query.
   * @param dataFunction the function to select the data to return.
   */
  protected getData(url_command: string, filter, dataFunction) {
    let start_time = Date.now();
    return this.http.post<any>(this.getUrl(url_command), filter, { headers: this.token.buildHeaders() }).pipe(map((response) => {
      if (response.status === 200 || response.ok === 1) {
        logicutils.logDuration(url_command, start_time, true);
        return dataFunction(response);
      }
      else {
        console.error("[Where-it] : Error get data : \r\n - Status : " + response.status + "\r\n - URL    : " + url_command);
        console.error(response);
        return response.status;
      }
    }), catchError((error: HttpErrorResponse) => {
      return this.manageError(error);
    }));
  }

  /**
  * get the data from the API server
  * @param url_command the part of the URL specifying the kind of data to get.
  * @param data the filter to apply on the query and the data to update.
  * @param dataFunction the function to select the data to return.
  * @param subscribeMethod the method to execute when the action is done
  */
  protected updData(url_command: string, data, subscribeMethod = null) {
    return this.http.post<any>(this.getUrl(url_command), data, { headers: this.token.buildHeaders() }).pipe(map((response) => {
      if (response.status === 200 || response.ok === 1 || response.acknowledged) {
        return response;
      } else {
        console.error("[Where-it] : Error upd data : \r\n - Status : " + response.status + "\r\n - URL    : " + url_command);
        console.error(response);
        if (subscribeMethod)
          subscribeMethod(response);
        return response.status;
      }
    }), catchError((error: HttpErrorResponse) => {
      if (subscribeMethod)
        subscribeMethod(error);
      return this.manageError(error);
    })).subscribe((res) => {
      if (subscribeMethod)
        subscribeMethod(res);
    });
  }

  protected addDataNoSubscribe(url_command: string, data) {
    return this.http.post<any>(this.getUrl(url_command), data, { headers: this.token.buildHeaders() }).pipe(map((response) => {
      if (response.status === 200 || response.ok === 1 || response.acknowledged) {
        return response.data.id;
      } else {
        console.error("[Where-it] : Error upd data : \r\n - Status : " + response.status + "\r\n - URL    : " + url_command);
        console.error(response);
        return response;
      }
    }), catchError((error: HttpErrorResponse) => {
      this.manageError(error);
      return of({ "status": error.status })
    }));
  }

  protected updDataNoSubscribe(url_command: string, data) {
    return this.http.post<any>(this.getUrl(url_command), data, { headers: this.token.buildHeaders() }).pipe(map((response) => {
      if (response.status === 200 || response.ok === 1 || response.acknowledged) {
        return response.data;
      } else {
        console.error("[Where-it] : Error upd data : \r\n - Status : " + response.status + "\r\n - URL    : " + url_command);
        console.error(response);
        return response;
      }
    }), catchError((error: HttpErrorResponse) => {
      this.manageError(error);
      return of({ "status": error.status })
    }));
  }

  protected updloadData(url_command: string, data, subscribeMethod = null) {
    // notes we call buildUploadHeaders instead of buildHeaders
    return this.http.post<any>(this.getUrl(url_command), data, { headers: this.token.buildUploadHeaders() }).pipe(map((response) => {
      if (response.status === 200 || response.ok === 1) {
        return response;
      } else {
        console.error("[Where-it] : Error upd data : \r\n - Status : " + response.status + "\r\n - URL    : " + url_command);
        console.error(response);
        if (subscribeMethod)
          subscribeMethod(response);
        return response.status;
      }
    }), catchError((error: HttpErrorResponse) => {
      if (subscribeMethod)
        subscribeMethod(error);
      return this.manageError(error);
    })).subscribe((res) => {
      if (subscribeMethod)
        subscribeMethod(res);
    });
  }

  private manageError(error) {
    // redirect to login page
    if (error.status == 401 || error.status == 403)
      this.router.navigate(['/login']);
    else if (error.status == 404)
      console.warn("[Where-it] : Warning Data not found : \r\n - Status : " + error.status + "\r\n - Error  : " + error.error + "\r\n - URL    : " + error.url);
    else if (error.status == 412)
      this.core_service.openDialogMessage("Error", error.error);
    else if (error.status == 500)
      console.error("[Where-it] : Error in REST server : \r\nCheck the REST server errors\r\n - Status : " + error.status + "\r\n - Error  : " + error.error + "\r\n - URL    : " + error.url);
    else if (error.status == 0)
      console.error("[Where-it] : Error in Calling API : \r\nUnable to call API may be a timeout\r\n - Status : " + error.status + "\r\n - Error  : " + error.error + "\r\n - URL    : " + error.url);
    else
      console.error("[Where-it] : Error in base service : \r\n - Status : " + error.status + "\r\n - Error  : " + error.error + "\r\n - URL    : " + error.url);

    return error //{ "status": error.status };
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class UserService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "user";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
  * Gets all the users (without the password).
  */
  getUsers(with_installer: boolean = false) {
    return this.getData("getUsers", { with_installer: with_installer }, (response) => { return response.data; });
  }

  /**
   * Gets the user corresponding to the id (without the password).
   * @param userId the id of the user to find.
   */
  getUser(userId: string) {
    return this.getData("getUser", { userId: userId }, (response) => { return response.data; });
  }

  /**
  * Checks if the user name already exists.
  * @param username the user name to check.
  */
  checkUserNameExist(username: string) {
    return this.getData("checkUserNameExist", { username: username }, (response) => { return response.data; });
  }

  /**
  * Checks if the user mail already exists.
  * @param mail the user mail to check.
  */
  checkUserMailExist(mail: string) {
    return this.getData("checkUserMailExist", { mail: mail }, (response) => { return response.data; });
  }

  /**
   * Creates a new user in the database
   * as this method is not a standard add it starts with create
   */
  createUser(user: any, lang: string, subscribeMethod) {
    return this.updData("createUser", { user: user, lang: lang.toLowerCase() }, subscribeMethod);
  }

  /**
 * Upd password in the database
 */
  updUserPassword(userId: String, password: String, subscribeMethod) {
    return this.updData("updUserPassword", { userId: userId, password: password }, subscribeMethod);
  }

  /**
 * Update user information in the database
 * @param user
 */
  updUser(user: any, subscribeMethod) {
    return this.updData("updUser", { user: user }, subscribeMethod);
  }

  /**
   * Updates users for an import
   * @param users the users data to add or replace
   */
  updUsers(users: any[]) {
    return this.updData("updUsers", { users: users });
  }

  /**
   *
   * @param userId
   * @param subscribeMethod
   * @returns
   */
  updUserImage(user_id: string, image: any, subscribeMethod) {
    return this.updData("updUserImage", { _id: user_id, image: image }, subscribeMethod);
  }

  /**
 * Delete the user matching with the given id
 * @param userId
 */
  delUser(userId: string, subscribeMethod) {
    return this.updData("delUser", { userId: userId }, subscribeMethod);
  }

  sendMail(user_mail: string, title: string, message: string, subscribeMethod) {
    return this.updData("sendMail", { user_mail: user_mail, title: title, message: message }, subscribeMethod);
  }

}
import { Pipe, ChangeDetectorRef, PipeTransform } from '@angular/core'
import { TranslationService } from '../../services/translation.service';
import { TranslatePipe, TranslateService, TranslateDirective } from '@ngx-translate/core';

@Pipe({ name: 'locale', pure: false })
export class LocalePipe implements PipeTransform {
  translatePipe: TranslatePipe;

  constructor(translate: TranslateService, private translationService: TranslationService, _ref: ChangeDetectorRef) {
    this.translatePipe = new TranslatePipe(translate, _ref);
  }

  transform(value: any): string {
    let translated = this.translatePipe.transform(value);
    translated = this.translationService.postTranslate(translated);
    return translated;
  }
}

<button class="btn btn-{{btn_type}} {{nomargin_class}}" [disabled]="!enabled" type="button" data-toggle="tooltip" style="line-height:13px; padding:0px 6px; border-radius:2px;"
        title="{{ btn_tooltip | locale}}"
        [style.width]="btn_width"
        [style.height]="btn_height"
        (click)="onClicked($event)">
  <div (click)="onClicked($event)">
    <i class="fas fa-{{btn_icon}}" [style.float]="img_float" [style.margin-right]="btn_text ? '6px' : '0px'" aria-hidden="true"></i>
    {{toggle_prefix}}{{btn_text | locale}}
  </div>
</button>
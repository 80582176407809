import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

import { BaseWorkComponent } from "./base.work.component";

import { TranslationService } from "../../services/translation.service";

import { dateutils } from '../dateutils';
import { mathutils } from '../mathutils';
import { navutils } from '../navutils';
import { GlyphColor, ItemKinds, LocationModes } from "../../models/common";
import { Point, Rectangle } from '../../models/types';
import { FloorLayout } from './plan/floor-layout';
import { PlanItemKind } from './plan/PlanItemKind';
import { ItemPlanSet } from './plan/ItemPlanSet';
import { GatewayPlanSet } from './plan/GatewayPlanSet';
import { PlanGroup } from './plan/PlanGroup';
import { logicutils } from '../logicutils';


@Component({
  selector: "app-floor-plan",
  templateUrl: './floor-plan.component.html',
  styles: [],
})
export class FloorPlanComponent extends BaseWorkComponent {

  @Output() onGatewayClick = new EventEmitter();
  @Output() onItemClick = new EventEmitter();
  @Output() onImageClick = new EventEmitter();
  @Output() onImageMove = new EventEmitter();

  public scale_image: string = "id_scale-image";
  public scale_graduations: string = "id_scale_graduations";
  public canvas_name: string = "id_main_canvas";
  public image_name: string = "id_main-image";
  public map_image_name: string = "id_map-image";
  public image_container: string = "id_image-container";
  public zoom_canvas: string = "id_zoom_thumb";
  public command_bar_id: string = "id_command_bar";

  private plan_component: HTMLElement = null;

  public get ImageContainer(): any {
    return this.plan_component.getElementsByClassName(this.image_container)[0];
  }

  public get ScaleImage(): HTMLCanvasElement {
    return <HTMLCanvasElement>this.plan_component.getElementsByClassName(this.scale_image)[0];
  }

  public get ScaleGraduations(): HTMLCanvasElement {
    return <HTMLCanvasElement>this.plan_component.getElementsByClassName(this.scale_graduations)[0];
  }

  public get Canvas(): HTMLCanvasElement {
    return <HTMLCanvasElement>this.plan_component.getElementsByClassName(this.canvas_name)[0];
  }

  public get CommandBar(): HTMLDivElement {
    return <HTMLDivElement>this.plan_component.getElementsByClassName(this.command_bar_id)[0];
  }

  public get ZoomCanvas(): HTMLCanvasElement {
    return <HTMLCanvasElement>this.plan_component.getElementsByClassName(this.zoom_canvas)[0];
  }

  public get Context(): CanvasRenderingContext2D {
    return this.Canvas.getContext("2d");
  }

  public get ZoomContext(): CanvasRenderingContext2D {
    return this.ZoomCanvas.getContext("2d");
  }

  public get Image(): HTMLImageElement {
    return <HTMLImageElement>this.plan_component.getElementsByClassName(this.image_name)[0];
  }

  public get MapImage(): HTMLImageElement {
    return <HTMLImageElement>this.plan_component.getElementsByClassName(this.map_image_name)[0];
  }

  public no_image: boolean = true;

  private selected_color: string = "#F00"
  private draw_color: string = "#00F";

  public button_width = 32;

  private title: string = "";
  private move_point: Point;
  private move_point_thumb: Point;

  public location_mode: LocationModes = LocationModes.Sensors;

  public floor;
  public plan_image: string;
  public plan_map_image: string;
  public plan_scale_image: string;
  public use_margin: boolean = true;
  private floorLayout: FloorLayout;
  private scale_min: number;
  private scale_max: number;
  private scale_unit: string;
  private scale_hide_negative: boolean = false;

  public plan_items: ItemPlanSet;
  public plan_gateways: GatewayPlanSet;

  public locations: any[];
  public current_location: any;
  private location_draw_walls: boolean;

  public paths: any[];
  public current_path: any;

  public show_circle: boolean = true;
  private can_move_element: any;
  public can_zoom: boolean;
  public show_tool_bar = true;

  private elements: any[];

  public fill_mode: boolean = false;
  get FillMode() {
    return this.fill_mode;
  }
  set FillMode(value: boolean) {
    this.fill_mode = value;
    this.refreshDisplay();
  }

  get Elements() {
    return this.elements;
  }
  set Elements(value: any[]) {
    this.elements = value;
    this.refreshDisplay();
  }

  private surveys: any[];
  get Surveys() {
    return this.surveys;
  }
  set Surveys(value: any[]) {
    this.surveys = value;
    this.refreshDisplay();
  }

  private current_element: any[] = [];
  get CurrentElements() {
    return this.current_element;
  }
  set CurrentElements(value: any[]) {
    this.current_element = value;
    this.refreshDisplay();
  }
  public IsCurrent(element) {
    return this.CurrentElements.some(e => e["id"] == element["id"]);
  }


  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService, private dialog: MatDialog) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  /**
  * Initialize the view after the image has been loaded to ensure it is available for calculations
  * */
  public onImageLoaded() {
    this.refreshDisplay();
  }

  public onResize(event) {
    this.refreshDisplay();
  }

  public onClickGateway(gateway) {
    this.plan_gateways.drawGatewayCircle(gateway._id)
    this.onGatewayClick.emit(gateway);
  }

  public onClickItem(event) {
    this.onItemClick.emit(event);
  }

  public initialize(floor: any, gateways: [], location_mode: LocationModes, item_kind: ItemKinds,
    show_circle: boolean = true, use_margin: boolean = true, show_sensor_precision: boolean = false,
    can_move_element: boolean = false, can_zoom: boolean = false, title: string = "", identifier: string = null) {
    // identifier used to discrminate the plan if there is two plans in the same page
    if (identifier)
      this.plan_component = <HTMLElement>document.getElementById(identifier);
    else
      this.plan_component = <HTMLElement>document.getElementsByTagName("app-floor-plan")[0];

    this.location_mode = location_mode;
    this.item_kind = item_kind;
    this.floor = floor;
    this.plan_image = floor.image;
    this.plan_map_image = null;
    this.show_circle = show_circle;
    this.use_margin = use_margin;
    this.can_move_element = can_move_element;
    this.can_zoom = can_zoom;
    this.title = title;
    this.no_image = false;
    this.ensureLayout(this.floor, false);
    this.setPlanGateway(gateways);
  }

  public changeFloor(floor: any, gateways: []) {
    this.floor = floor;
    this.setPlanGateway(gateways);
    this.plan_image = floor.image;
    this.plan_map_image = null;
    this.no_image = false;
    this.ensureLayout(this.floor, true);
    this.refreshDisplay();
  }

  public reset(): any {
    this.floor = null;
    this.plan_image = "#";
    if (this.floorLayout)
      this.floorLayout.updateImageSize();
    this.plan_map_image = null;
    this.no_image = true;
  }

  public setMapImage(map_image: string) {
    this.plan_map_image = map_image;
  }

  public setMapAndScaleImage(map_image: string, scale_image: string, min: number, max: number, unit: string, hide_negative: boolean = false) {
    this.plan_map_image = map_image;
    this.setScaleImage(scale_image, min, max, unit, hide_negative);
  }

  public setScaleImage(scale_image: string, min: number, max: number, unit: string, hide_negative: boolean) {
    this.plan_scale_image = scale_image;
    this.scale_min = min;
    this.scale_max = max;
    this.scale_unit = unit;
    this.scale_hide_negative = hide_negative;
  }

  public onScaleImageLoaded(event) {
    let width = this.floorLayout.image_size.width;
    let scale_min = this.scale_hide_negative ? 0 : this.scale_min;
    let length = this.scale_max - scale_min;
    let ratio = width / length;
    let hide_negative_ratio = width / (this.scale_max - this.scale_min);
    let scale_image_tag = this.ScaleImage;
    scale_image_tag.width = width;
    scale_image_tag.height = 16;
    let graduation_tag = this.ScaleGraduations;
    graduation_tag.width = width;
    graduation_tag.height = 16 + 18;
    let context = graduation_tag.getContext("2d");

    context.beginPath();
    context.globalAlpha = 0.5;
    context.drawImage(scale_image_tag, this.scale_hide_negative ? this.scale_min * ratio : 0, 0, width * ratio / hide_negative_ratio, 16);
    context.globalAlpha = 1;
    for (var i = scale_min; i <= this.scale_max; i++) {
      let x = (i - scale_min) * ratio;
      this.drawLine(context, x, i, i < this.scale_max, length <= 10);
    }
    context.stroke();
  }

  private drawLine(context, x, temp, left, label_all) {
    let tenth = ((temp % 10) == 0);
    let fifth = ((temp % 5) == 0);
    let shift = tenth ? (left ? 4 : -4) : 0;
    context.textAlign = tenth ? (left ? "left" : "right") : "center";
    context.lineWidth = fifth ? 3 : 1;
    context.beginPath();
    context.moveTo(x, 16 + 0);
    context.lineTo(x, 16 + (tenth ? 18 : fifth ? 10 : 6));
    if (label_all || tenth)
      context.fillText(temp + " " + this.scale_unit, x + shift, 16 + 16);
    context.stroke();
  }

  public changeLocationMode(location_mode: LocationModes) {
    this.location_mode = location_mode;
    if (this.plan_items)
      this.plan_items.location_mode = location_mode;
    this.refreshDisplay();
  }

  public changeItemKind(item_kind: ItemKinds) {
    this.item_kind = item_kind;
    if (this.plan_items)
      this.plan_items.plan_item_kind = (this.item_kind == ItemKinds.Equipment) ? PlanItemKind.Equipment : PlanItemKind.Sensor;
  }

  public setPlanGateway(gateways: any[]) {
    this.plan_gateways = new GatewayPlanSet(gateways, this.floorLayout, this.translation_service);
    this.refreshDisplay();
  }

  public updateReceivedGateways(receiveds: string[]) {
    if (this.plan_gateways)
      this.plan_gateways.updateReceivedGateways(receiveds);
  }

  public setPlanItems(event_infos: any[], kind: PlanItemKind, show_sensor_precision: boolean, group_near: boolean, group_out_site: boolean, group_out_location: boolean) {
    this.plan_items = new ItemPlanSet(event_infos, this.location_mode, kind, this.floorLayout, this.translation_service, show_sensor_precision, group_near, group_out_site, group_out_location);
    this.refreshDisplay();
  }

  public setLocations(locations: any[], current_location: any, draw_walls: boolean = true) {
    this.location_draw_walls = draw_walls;
    this.locations = locations;
    this.current_location = current_location;
    this.refreshDisplay();
  }

  public setPaths(locations: any[], current_location: any) {
    this.paths = locations;
    this.current_path = current_location;
    this.refreshDisplay();
  }

  private ensureLayout(floor, force: boolean = true) {
    if (force || this.floorLayout == null) {
      let max = 0;
      let image = null
      while (!image && (max < 100)) {
        image = this.Image;
        max++;
      }
      if (image != null)
        this.floorLayout = new FloorLayout(<number>floor.dimension.width, <number>floor.dimension.height, this);
    }
  }

  public refreshDisplay() {
    if (!this.floor)
      return;
    this.ensureLayout(this.floor, false);
    // if we haven't floorLayout here, this means the image is empty or the page has changed
    if (!this.floorLayout)
      return;
    // updates the ratios in case of a resize occured
    this.floorLayout.updateImageSize();

    // fills the gateways
    if (this.plan_gateways)
      this.plan_gateways.CalculteLocations();

    // fills the plan items (sensors/equipments)
    if (this.plan_items)
      this.plan_items.CalculteLocations();

    if (this.surveys && this.surveys.length > 0)
      this.drawSurveys(this.surveys);

    if (this.elements && this.elements.length > 0)
      this.drawElements(this.elements);

    if (this.locations) {
      for (let current_room of this.locations)
        if (current_room != this.current_location)
          this.drawWalls(current_room, this.draw_color, false, true, true, this.location_draw_walls);
      if (this.current_location)
        this.drawWalls(this.current_location, this.selected_color, true, true, true, this.location_draw_walls);
    }

    if (this.paths) {
      for (let current_path of this.paths)
        if (current_path != this.current_path)
          this.drawPaths(current_path, this.draw_color, false);
      if (this.current_path)
        this.drawPaths(this.current_path, this.selected_color, true);
    }

    // refreshes the zoom thumbnail
    this.refreshZoomThumb();
  }

  private refreshZoomThumb() {
    let main_canvas: HTMLCanvasElement = this.Canvas;
    let zoom_canvas: HTMLCanvasElement = this.ZoomCanvas;
    if (!zoom_canvas || !main_canvas)
      return;

    zoom_canvas.height = this.button_width * 2;
    zoom_canvas.width = main_canvas.width / main_canvas.height * zoom_canvas.height;
    let main_thumb_ratio = main_canvas.height / zoom_canvas.height;

    let zoom_ratio: number = this.floorLayout.ZoomRatio;
    let zoom_shift: Point = this.floorLayout.getDrawshift();
    var context = this.ZoomContext;
    context.beginPath();
    context.drawImage(this.floorLayout.Image, 0, 0, (zoom_canvas.width), (zoom_canvas.height));
    context.lineCap = 'round';
    context.rect(-zoom_shift.x / main_thumb_ratio / zoom_ratio, -zoom_shift.y / main_thumb_ratio / zoom_ratio - 1, zoom_canvas.width / zoom_ratio + 1, zoom_canvas.height / zoom_ratio + 2);

    context.lineWidth = 1;
    context.strokeStyle = "#2D6C9B";
    context.stroke();
  }

  public onMouseDownThumb(event) {
    if (!this.can_zoom)
      return;
    this.move_point_thumb = new Point(event.clientX, event.clientY);
  }

  public onMouseUpThumb(event) {
    if (!this.can_zoom)
      return;
    if (event.button != 0)
      return;
    let main_canvas: HTMLCanvasElement = this.Canvas;
    let zoom_canvas: HTMLCanvasElement = this.ZoomCanvas;
    if (!zoom_canvas || !main_canvas)
      return;
    zoom_canvas.height = this.button_width * 2;
    zoom_canvas.width = main_canvas.width / main_canvas.height * zoom_canvas.height;
    let main_thumb_ratio = main_canvas.height / zoom_canvas.height;
    let zoom_ratio: number = this.floorLayout.ZoomRatio;
    let shift = new Point((event.clientX - this.move_point_thumb.x), (event.clientY - this.move_point_thumb.y));
    let move_shift = new Point(shift.x * main_thumb_ratio * zoom_ratio * zoom_ratio, shift.y * main_thumb_ratio * zoom_ratio * zoom_ratio);
    // move the plan to the cursor point
    this.moveImage(move_shift);
  }

  public onWheelThumb(event) {
    if (!this.can_zoom)
      return;
    let main_canvas: HTMLCanvasElement = this.Canvas;
    let zoom_canvas: HTMLCanvasElement = this.ZoomCanvas;
    if (!zoom_canvas || !main_canvas)
      return;
    event.preventDefault();
    event.stopPropagation();
    let grow = event.wheelDelta > 0;
    let main_thumb_ratio = main_canvas.height / zoom_canvas.height;
    let current_shift = new Point(event.offsetX * main_thumb_ratio, event.offsetY * main_thumb_ratio);
    this.zoomImage(grow, current_shift);
  }

  public getRealPoint(x, y) {
    return this.floorLayout.getRealPoint(x, y);
  }

  public drawWall(color, posX1, posY1, posX2, posY2) {
    this.floorLayout.drawWall(color, posX1, posY1, posX2, posY2);
  }

  public drawWalls(room: any, line_color: string, selected: boolean = false, draw_title: boolean = false, close: boolean = true, draw_walls: boolean = true) {
    this.floorLayout.drawWalls(room, line_color, selected, this.FillMode, draw_title, close, draw_walls);
  }

  public drawPaths(room: any, line_color: string, selected: boolean = false) {
    this.floorLayout.drawPaths(room, line_color, selected);
  }

  private drawElements(elements: any[]) {
    for (let element of elements)
      if (this.IsCurrent(element))
        this.floorLayout.drawElement(element, GlyphColor.YellowRed);
      else
        this.floorLayout.drawElement(element, GlyphColor.Blue);
  }

  private drawSurveys(elements: any[]) {
    for (let element of elements)
      this.floorLayout.drawElement(element, "glyph_color" in element ? element.glyph_color : GlyphColor.Green, true);
  }

  public onMouseDownImage(event) {
    if (!this.can_zoom)
      return;
    this.move_point = new Point(event.clientX, event.clientY);
  }

  public onMoveImage(event) {
    if (this.can_move_element)
      this.onImageMove.emit(event);
  }

  public onWheelImage(event) {
    if (!this.can_zoom)
      return;
    event.preventDefault();
    event.stopPropagation();
    let grow = event.wheelDelta > 0;
    let current_shift = new Point(event.offsetX, event.offsetY);
    this.zoomImage(grow, current_shift);
  }

  public onMouseUpImage(event) {
    if (this.can_move_element)
      this.onImageClick.emit(event);
    if (!this.can_zoom)
      return;
    if (event.button != 2)
      return;
    let move_shift = new Point(this.move_point.x - event.clientX, this.move_point.y - event.clientY);

    // move the plan to the cursor point
    this.moveImage(move_shift);
  }

  private moveImage(move_shift: Point) {
    let ratio = this.floorLayout.ZoomRatio;
    let aspect_ratio = this.floorLayout.AspectRatio;
    let zoom_shift = this.floorLayout.ZoomShift;
    let shift_meter = new Point((move_shift.x / aspect_ratio.x / ratio), (move_shift.y / aspect_ratio.y / ratio));
    let shift = new Point(zoom_shift.x - shift_meter.x, zoom_shift.y - shift_meter.y);
    this.move_point = null;
    this.floorLayout.setZoom((shift.x), (shift.y), ratio);
    this.refreshDisplay();
  }

  private zoomImage(grow: boolean, current_shift: Point) {
    let ratio = this.floorLayout.ZoomRatio;
    let factor = grow ? 2 : 0.5;
    ratio *= factor;
    let shift = this.floorLayout.ZoomShift;
    let x = grow ? current_shift.x / 2 : current_shift.x;
    let y = grow ? current_shift.y / 2 : current_shift.y;
    if (grow) {
      // to place current point in center of the zoomed view
      /*x = x + (x * 2 - this.floorLayout.Image.width / 2) / 2;
      //y = y + (y * 2 - this.floorLayout.Image.height / 2) / 2;
      // to keep the current point in the same place in the zoomed view*/
      x = x;
      y = y;
    }
    else {
      // to place current point in center of the unzoomed view
      /*x = -(x - (x - this.floorLayout.Image.width / 2) * 2);
      //y = -(y - (y - this.floorLayout.Image.height / 2) * 2);
      // to keep the current point in the same place in the unzoomed view*/
      x = -x;
      y = -y;
    }
    shift = this.floorLayout.getRealPoint(x, y);
    if (ratio <= 1) {
      shift = new Point(0, 0);
      ratio = 1;
    }
    this.floorLayout.setZoom(-(shift.x) * ratio, -(shift.y) * ratio, ratio);
    this.refreshDisplay();
  }

  public setZoom(shift_x: number, shift_y: number, zoom: number) {
    this.floorLayout.setZoom(shift_x * zoom, shift_y * zoom, zoom);
    this.refreshDisplay();
  }

  private resetZoom() {
    this.setZoom(0, 0, 1);
  }

  private zoomArea(shift: Point, width, height) {
    let floor_width = this.floorLayout.floor_size.width;
    let floor_height = this.floorLayout.floor_size.height;
    let ratio = Math.min(floor_width / width, floor_height / height) * 0.8;
    let cor_x = ((floor_width / ratio) - width) / 2;
    let cor_y = ((floor_height / ratio) - height) / 2;
    this.floorLayout.setZoom(-(shift.x - cor_x) * ratio, -(shift.y - cor_y) * ratio, ratio);
    this.refreshDisplay();
  }

  public MoveCurrentElements(e) {
    let current_point = this.floorLayout.getRealPoint(e.offsetX, e.offsetY);
    for (var current_element of this.CurrentElements) {
      current_element["x"] = current_point.x = mathutils.round(current_point.x, 1, false);
      current_element["y"] = current_point.y = mathutils.round(current_point.y, 1, false);
    }
    this.refreshDisplay();
  }

  public doZoom(plus: boolean) {
    if (!this.can_zoom)
      return;
    let grow = plus;
    let current_size = this.floorLayout.image_size;
    let current_shift = new Point(current_size.width / 2, current_size.height / 2)
    this.zoomImage(grow, current_shift);
  }

  public doShift(direction) {
    /* direction :
        0
      3   1
        2
    */
    let length = 50;
    let shift = new Point(0, 0);
    if (direction == 0)
      shift = new Point(0, -length);
    else if (direction == 1)
      shift = new Point(length, 0);
    else if (direction == 2)
      shift = new Point(0, length);
    else if (direction == 3)
      shift = new Point(-length, 0);
    this.moveImage(shift);
  }

  public onExportPlan() {
    let name = this.getExportName(this.title);
    this.exportImage(name)
  }

  private getExportName(label: string) {
    let now: string = dateutils.formatDate(new Date())
    return this.translation_service.translate(label) + " " + this.floor.name + " " + now;
  }

  public exportImage(name: string) {
    // "useCORS: true" is intended to avoid "Uncaught (in promise): Event: {"isTrusted":true}" error
    html2canvas(this.ImageContainer, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true
    }).then(function (canvas) {
      // converts the canvas to blob
      canvas.toBlob(function (blob) {
        // To download directly on browser default 'downloads' location
        /*
        * let link = document.createElement("a");
        * link.download = "image.png";
        * link.href = URL.createObjectURL(blob);
        * link.click();
        */

        // To save manually somewhere in file explorer
        saveAs(blob, name);

      }, 'image/png');
    });
  }

  public setCursor(cursor: string) {
    this.floorLayout.Canvas.style.cursor = cursor;
  }

  public toggleToolBar(show) {
    this.show_tool_bar = show;
  }

  public selected_group: PlanGroup;

  public onClickGroup(group: PlanGroup, event) {
    let location = new Point(event.clientX, event.clientY);
    navutils.openDialogGroupInfo(this.dialog, "Group", group.items, null, this.onItemClick, location);
  }

  public onFindLocation(e, use_text) {
    if (use_text) {
      this.zoomOnLocationByName(e);
    } else {
      if (e && e.key != 'Enter')
        return;
      let name = e ? e.srcElement.value : null;
      this.zoomOnLocationByName(name);
    }
  }

  public zoomOnLocationByName(name: string) {
    if (!this.locations)
      return;
    if (name) {
      for (let current_room of this.locations) {
        if (logicutils.matchName(name, current_room.name)) {
          this.zoomOnLocation(current_room);
          return;
        }
      }
    }
    this.resetZoom();
  }

  public zoomOnLocation(location) {
    if (!location)
      return;
    let rectangle: Rectangle = mathutils.getEnclosingRectangle(location.walls);
    this.zoomArea(new Point(rectangle.x1, rectangle.y1), rectangle.x2 - rectangle.x1, rectangle.y2 - rectangle.y1);
  }
}
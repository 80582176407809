
<div class="dashboard" style="background-color:#2B526F; margin:0px;">

  <app-breadcrumb [links]="links"></app-breadcrumb>
  <app-page-title title="{{title}}" [initialized]="initialized" style="background-color:#2B526F"></app-page-title>

  <div *ngIf="rights" style="margin: 0px auto;" [ngStyle]="{'width':bar_width+'px' }">
    <button *ngIf="canEquipments()" id="equipments_button" class="btn-rounded btn-add" (click)="navigateDashboard()">{{'Equipments'|locale}}</button>
    <button *ngIf="canMaintenance()" id="maintenance_button" class="btn-rounded btn-close" (click)="navigateMaintenanceDashboard()">{{'Maintenance'|locale}}</button>
    <button *ngIf="canBattery()" id="battery_button" class="btn-rounded btn-add" (click)="navigateBatteryDashboard()">{{'Battery'|locale}}</button>
  </div>

  <div class="msg-error">{{error}}</div>

  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">
    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <div style="float:left;">
        <span style="padding-left: 10px; padding-right: 5px; color:white;">{{ '$Equipment kind$' | locale }} :</span>
        <select id="equipment_kind_id_select" class="search-select-single" [ngModel]="equipment_kind_id" (change)="onChangeEquipmentKind($event)" [attr.disabled]="initialized ? null : ''">
          <option *ngFor="let equipment_kind of equipment_kinds" value={{equipment_kind._id}}>{{equipment_kind.name}}</option>
        </select>
      </div>
      <span class="current_date" style="color:white">{{ 'Update time' | locale }} : {{current_date | localizedDate:'medium'}}</span>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="row col-md-12 nopadding">
      <div class="centered" style="width: 1024px;">
        <app-dashboard-maintenance-counters id="widget_counters" #widget_counters (Select)="onSelect($event)"></app-dashboard-maintenance-counters>
      </div>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="centered" style="max-width:1024px;">
      <div class="row col-md-12 nopadding">
        <app-dashboard-maintenance-equipment-levels *ngIf="building_view" id="widget_equipment_levels" #widget_equipment_levels></app-dashboard-maintenance-equipment-levels>
      </div>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="centered" style="max-width:1024px;">
      <div class="row col-md-12 nopadding">
        <app-dashboard-maintenance-history id="widget_level_history" #widget_level_history></app-dashboard-maintenance-history>
        <app-dashboard-maintenance-previsional id="widget_level_previsional" #widget_level_history></app-dashboard-maintenance-previsional>
      </div>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="centered" style="max-width:1024px;">
      <div class="row col-md-12 nopadding">
        <app-dashboard-maintenance-level-rate id="widget_level_rate" #widget_level_rate></app-dashboard-maintenance-level-rate>
        <app-dashboard-maintenance-distribution-kind id="widget_distribution_kind" #widget_distribution_kind></app-dashboard-maintenance-distribution-kind>
        <app-dashboard-maintenance-distribution-floor id="widget_distribution_floor" #widget_distribution_floor></app-dashboard-maintenance-distribution-floor>
      </div>
    </div>
  </div>

</div>
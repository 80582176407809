import { FindResult } from '../../../common/plan/common/FindResult';
import { PathVector } from '../../../common/plan/common/PathVector';
import { Point2D } from '../../../common/plan/common/Point2D';
import { PlanComponent } from '../../../common/plan/plan.component';
import { DisplayBase } from './DisplayBase';


export class PlanDisplay extends DisplayBase {


  constructor(result: FindResult, plan: PlanComponent, floors: any[], rooms: any[]) {
    super(result, plan, floors, rooms);
  }

  protected override async OnDraw(index: number) {
    let entry: PathVector = this.Result.Entries[index];
    let floor_id = entry.end.floor_id;
    let floor: any | undefined = this.floors.find(f => f._id == floor_id);
    if (!floor)
      return;

    if (floor_id != this.current_floor_id)
      await this.plan.Initialize(floor.image, floor.dimension.width, floor.dimension.height, null, 400, false);
    this.current_floor_id = floor_id;

    this.plan.Clear("map");

    // takes only the entries of the current floor
    let floor_points: Point2D[] = [];
    for (let entry of this.Result.Entries) {
      if (!floor_points.length && entry.start.floor_id == floor_id)
        floor_points.push(Point2D.FromPoint3D(entry.start));
      if (entry.end.floor_id == floor_id)
        floor_points.push(Point2D.FromPoint3D(entry.end));
    }

    this.plan.AddRealPolyline("map", floor_points, "red", 4);
    this.plan.AddRealPoint("map", Point2D.FromPoint3D(entry.start), "red", null, 12);

    this.plan.Redraw();
  }
}

import { Component, Input } from "@angular/core";
import { FormEditorBase } from './FormEditorBase';


/**
 * Textarea editor to use in forms to bind values and manage validation.
 * @code <app-form-textarea [field]="form.address.controls.address1" [rows]="6" [submitted]="submitted"></app-form-textarea>
 * */
@Component({
  selector: "app-form-textarea",
  templateUrl: './form-textarea.component.html',
  styles: []
})
export class FormTextareaComponent extends FormEditorBase {

  /**
    * number of rows of the textarea
    * @example rows="10"
    * @default : "6"
    */
  @Input() rows: string = "6";

  constructor() {
    super();
  }
}
<div style="border:1px solid black;">

  <div id="page_title" class="{{label_skin}}" style="border-bottom:1px solid; text-align:center; padding:4px;" data-toggle="tooltip" title="">
    <h3 style="margin:0px;">
      {{title | locale}}
    </h3>
  </div>


  <div style="margin: 0 20px">

    <div class="col-12" style="text-align: center; padding: 20px">
      <div *ngFor="let line of data.message.split('\r\n')" class="col-12" style="text-align: center; padding: 2px 0px">
        <label style="font-size: 15px;">{{line | locale}}</label>
      </div>
    </div>

    <div class="row" style="margin-bottom: 0;">
      <div class="col-12">
        <div class="btndiv" style="display: flex; justify-content: space-evenly;">
          <button [mat-dialog-close]="true" class="btn-dialog {{btn_skin}}">{{data.buttons.validate | locale}}</button>
          <button *ngIf="!data.single_button" [mat-dialog-close]="false" class="btn-dialog {{btn_skin}}">{{data.buttons.cancel | locale}}</button>
        </div>
      </div>
    </div>

  </div>
</div>
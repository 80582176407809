import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ElementRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { colorutils } from '../colorutils';
import { TranslationService } from '../../services/translation.service';


@Component({
  selector: "app-multi-list",
  templateUrl: './multi-list.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiListComponent),
      multi: true
    }
  ],
})
export class MultiListComponent implements OnInit, ControlValueAccessor {

  @Output() Changed = new EventEmitter();

  public _default_label: string = "Please select";

  private _enabled: boolean = true;
  private onChange: Function;
  private onTouched: Function;
  public disabled: boolean;
  public colors: colorutils = colorutils;

  public all_selected: boolean = false;
  public none_selected: boolean = false;

  constructor(private translation_service: TranslationService) {
    this.onChange = (_: any) => { };
    this.onTouched = () => { };
  }

  ngOnInit() {
  }

  // if true, all the items are visually checked if none is really selected
  @Input()
  public check_none_selected: boolean = false;

  /* mode :
  * 0 : hidden (allow to keep the lists)
  * 1 : list
  * 2 : card
  */
  @Input()
  public mode: number = 1;

  @Input()
  public mono_select: boolean = false;

  @Input()
  public title: string = "";

  @Input()
  public item_icon: string;

  @Input()
  public description_plural: string;

  @Input()
  public description_singular: string;

  /**
  * used only if the current list has no parent
  **/
  @Input()
  public description_all: string = "all";

  /*
   * The original list without filtering
   */
  public _list: any[];
  get list() {
    return this._list;
  }
  @Input() set list(val) {
    this._list = val;
    this.filterList();
  }

  /**
   * The list filtered according with the parent
   **/
  public _filtered_list: any[] = [];
  get filtered_list() {
    return this._filtered_list;
  }
  @Input() set filtered_list(val) {
    this._filtered_list = val;
  }

  /**
   * The selected items
   * **/
  @Input('filter')
  public _filter: any[] = [];

  get value() {
    return this._filter;
  }
  set value(val) {
    this._filter = val;
    this.onChange(val);
    this.onTouched();
  }

  private _child: MultiListComponent = null;
  get child() {
    return this._child;
  }
  @Input() set child(val) {
    this._child = val;
  }

  private _parent: MultiListComponent = null;
  get parent() {
    return this._parent;
  }
  @Input() set parent(val: MultiListComponent) {
    this._parent = val;
    this.parent._child = this;
    this.filterList();
  }


  get enabled(): boolean {
    return this._enabled;
  }
  @Input() set enabled(value: boolean) {
    this._enabled = value;
  }

  writeValue(filter: any[]): void {
    this._filter = filter;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.enabled = !disabled;
  }

  protected onChanged() {
    this.Changed.emit(this._filter);
  }

  private validateChange() {
    this.checkSelection();
    this.onChange(this._filter);
    this.onChanged();
    this.onTouched();
  }

  private filterList(): any {
    if (!this._list)
      return;

    if (!this.parent) {
      // if no item is selected in the parent (meaning no filter so all selected) we keep all the items
      // but if the parent use check_none_selected we consider there is really no selection, so we keep the filtered list empty
      if (!this.parent || this.parent.check_none_selected)
        for (let current of this._list)
          this._filtered_list.push(current);
    }
    else {
      // filters the displayed items according with the parent filter
      for (let current of this._list) {
        if (this.parent._filter.find(f => f._id == current.second)) {
          if (!this._filtered_list.find(f => f._id == current._id)) {
            // if we are in check_none_selected mode, we selects the newly added items
            if (this.check_none_selected)
              this.selectItem(current, true);
            this._filtered_list.push(current);
          }
        } else {
          let found_filter = this._filtered_list.find(f => f._id == current._id);
          if (found_filter) {
            this._filtered_list.splice(this._filtered_list.indexOf(found_filter), 1)
            this.selectItem(current, false);
          }
        }
      }
    }
    this.checkSelection();
    if (this._child)
      this._child.filterList();
  }

  private selectItem(item: any, select: boolean) {
    if (this.mono_select) {
      for (let last of this._filter)
        last.selected = false;
      item.selected = true;
      this._filter = [item];
    } else {
      item.selected = select;
      let found = this._filter.find(x => x._id == item._id);
      if (select) {
        if (!found)
          this._filter.push(item);
      }
      else {
        if (found)
          this._filter.splice(this._filter.indexOf(found), 1)
      }
    }
  }

  /**
   * Occurs when an item is select/deselected
   * @param item
   */
  public onClickItem(item) {
    if (this.mono_select) {
      for (let last of this._filter)
        last.selected = false;
      item.selected = true;
      this._filter = [item];
    } else {
      let found = this._filter.find(x => x._id == item._id + "");
      if (found) {
        found.selected = false;
        this._filter.splice(this._filter.indexOf(found), 1)
      } else {
        item.selected = true;
        this._filter.push(item);
      }
    }
    if (this._child)
      this._child.filterList();
    this.validateChange();
  }

  private checkSelection() {
    this.none_selected = this._filter.length == 0;
    this.all_selected = this._filter.length == this.filtered_list.length;
  }

  /**
   * Selects all all or noe of the items
   * @param select
   */
  public onSelectAll(select: boolean) {
    this._filter.splice(0, this._filter.length);
    for (let item of this._filtered_list) {
      item.selected = select;
      if (select)
        this._filter.push(item);
    }
    if (this._child)
      this._child.filterList();
    this.validateChange();
  }

  /**
   * Gets the ids of the selected items
   * */
  public getSelectedIds() {
    let ids: any[] = [];
    for (let filtered of this._filter)
      ids.push(filtered._id);
    return ids.length == 0 ? null : ids;
  }

  public selectIds(...ids: string[]) {
    for (let id of ids) {
      for (let item of this._filtered_list) {
        if (item._id == id) {
          item.selected = true;
          if (!this._filter.find(x => x._id == id))
            this._filter.push(item);
          break;
        }
      }
    }
    this.validateChange();
    if (this._child)
      this._child.filterList();
  }

  /**
   * Gets the names of the selected items (optionaly surrounded with <mark>)
   * */
  public getSelectedNames(with_mark: boolean) {
    let names: any[] = [];
    for (let filtered of this._filter)
      names.push(with_mark ? "<mark>" + filtered.name + "</mark> " : filtered.name);
    return names;
  }

  /**
   * Gets the names of the selected items (take care of the parents recursivly) (optionaly surrounded with <mark>)
   * */
  public getFinalNames(with_mark: boolean) {
    if (this._filter.length == 0)
      if (this.parent)
        return this.parent.getFinalNames(with_mark);
      else if (this._list.length == 1) // case where the first list has only one item (one building in search page)
        return [with_mark ? "<mark>" + this._list[0].name + "</mark> " : this._list[0].name];
      else
        return [];
    return this.getSelectedNames(with_mark);
  }

  /**
   * Gets the human readable description of the selected names
   * */
  public getDescription() {
    return this.translation_service.translate(this._filter.length == 1 ? this.description_singular : this.description_plural) + " ";
  }

  /**
   * Gets the human readable description of the selected names (take care of the parents recursivly)
   * */
  public getFinalDescription() {
    if (this._filter.length == 0)
      if (this.parent)
        return this.parent.getFinalDescription();
      else if (this._list.length == 1) // case where the first list has only one item (one building in search page)
        return this.translation_service.translate(this.description_singular) + " ";
      else
        return this.translation_service.translate(this.description_all) + " ";

    return this.getDescription();
  }

}
<div style="height:100vh">

  <mat-toolbar *ngIf="!hide_titlebar" class="title-bar" style="position:fixed; top:0px; left:0px; z-index:100">
    <mat-toolbar-row>
      <span class="mtr-span">
        <img alt="" id="logo_image" [src]="logo | safe: 'img'" class="logo" (click)="onNavigateHome()">
        <button *ngIf="!!rights && rights['right_users']" id="toggle_menu_nav_button" type="button"
                aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav()" color="white"
                class="sidenav-toggle-button">
          <mat-icon class="menu-icon" aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </span>
      <span style="flex: 1 1 auto;"></span>
      <a class="lang-menu" [matMenuTriggerFor]="langmenu">
        <button class="lang-select btn-lang" mat-icon-button id="lang_nav_button" position="end">
          {{ lang }}
        </button>
        <i class="fa fa-chevron-down ico-menu"></i>
      </a>
      <mat-menu #langmenu="matMenu" class="mat-menu-panel">
        <button mat-menu-item id="lang_fr_nav_button" (click)="ChangeLanguage('fr')">
          <span style="margin-left:16px;">FR</span>
        </button>
        <button mat-menu-item id="lang_en_nav_button" (click)="ChangeLanguage('en')">
          <span style="margin-left:16px;">EN</span>
        </button>
      </mat-menu>

      <button *ngIf="rights['right_rules']" class="btn-menu" mat-icon-button id="alert_nav_button" position="end" (click)="navigateAlerts()">
        <img src="assets/img/layout/bell.png" alt="" class="info-img menu-icon" />
        <div *ngIf="alerts" class="notification-count" [style.backgroundColor]="alert_color">{{alerts}}</div>
      </button>

      <button *ngIf="rights['right_maintenance']" class="btn-menu" mat-icon-button id="maintenance_nav_button" position="end" (click)="navigateMaintenances()">
        <img src="assets/img/layout/wrench.png" alt="" class="info-img menu-icon" />
        <div *ngIf="maintenances" class="notification-count" [style.backgroundColor]="maintenance_color">{{maintenances}}</div>
      </button>

      <button *ngIf="rights['right_supervisor']" class="btn-menu" mat-icon-button id="critical_nav_button" position="end" (click)="navigateCriticals()">
        <img src="assets/img/layout/bug.png" alt="" class="info-img menu-icon" />
        <div *ngIf="criticals" class="notification-count" [style.backgroundColor]="critical_color">{{criticals}}</div>
      </button>

      <button *ngIf="rights['right_supervisor']" class="btn-menu" mat-icon-button id="survey_nav_button" position="end" (click)="navigateDiagnostic()">
        <img src="assets/img/layout/calibration.png" alt="" class="info-img menu-icon" />
        <div *ngIf="surveys" class="notification-count" [style.backgroundColor]="surveys_color">{{surveys}}</div>
      </button>

      <!--<button *ngIf="rights['right_supervisor']" class="btn-menu" mat-icon-button id="duratiuon_report_nav_button" position="end" (click)="navigateDurationsReport()">
        <img src="assets/img/layout/duration.png" alt="" class="info-img menu-icon" />
      </button>-->

      <button *ngIf="rights['right_supervisor']" class="btn-menu" mat-icon-button id="supervision_nav_button" position="end" (click)="navigateSupervision()">
        <img src="assets/img/layout/technical.png" alt="" class="info-img menu-icon" />
      </button>

      <button class="btn-menu" mat-icon-button id="information_nav_button" position="end" (click)="navigateInformation()">
        <img src="assets/img/layout/info.png" alt="" class="info-img menu-icon" />
      </button>

      <button class="btn-menu" mat-icon-button id="profile_nav_button" position="end" (click)="navigateProfile()">
        <img src="assets/img/layout/user.png" alt="" class="info-img menu-icon" />
      </button>

      <button class="btn-menu" mat-icon-button id="logout_nav_button" position="end" (click)="logout()">
        <img src="assets/img/layout/logout.png" alt="" class="info-img menu-icon" />
      </button>

    </mat-toolbar-row>
  </mat-toolbar>

  <div *ngIf="is_loaded" id="sidebar" class="sidenav" style="width:0px">

    <div *ngFor="let item of navItems">

      <a class="map-btn" *ngIf="item.children==null" id="menu_{{item.name}}" [ngClass]="(item.selected)? 'selected' : ''"
         (click)="onNavigate(item.url);">
        <span class="menu-text">{{item.translate ? (item.name|locale) : item.name}}</span>
        <i class="navmenu-icon fa {{item.icon}}"></i>
      </a>

      <div *ngIf="item.children">

        <button id="menu_{{item.name}}_group"
                [ngClass]="[(item.selected)? 'selected' : '', (item.expanded)? 'active' : '']" class="dropdown-btn"
                (click)="onMenuDropDownToggle($event); onNavigate(item.url);">
          <!-- <i class="menu-icon fa {{item.icon}}"></i> -->
          <span class="menu-text">{{item.translate ? (item.name|locale) : item.name}}</span>
          <i [ngClass]="(item.expanded)? 'navmenu-icon fa fa-caret-up' : 'navmenu-icon fa fa-caret-down'"></i>
        </button>

        <div class="dropdown-container" [style.display]="(item.expanded)? 'block' : 'none'">
          <div *ngFor="let sub_item of item.children">
            <a *ngIf="sub_item.children==null" id="menu_{{item.name}}_{{sub_item.name}}"
               [ngClass]="(sub_item.selected)? 'selected' : ''" (click)="onNavigate(sub_item.url);">
              <img src="{{sub_item.icon}}" class="menu-icon">
              <span class="children-menu-text">{{sub_item.name | locale}}</span>
            </a>
          </div>
        </div>

      </div>

    </div>

    <div id="version_label"
         style="text-align:center; background:white; position:fixed; display:none; bottom:0px; left:0px; width:0px; height:24px; padding-top:4px;">
      Version: {{appVersion}}
    </div>
  </div>

  <main id="main" class="main" style="margin-left:0px; margin-top:70px;" [ngStyle]="{'margin-top': hide_titlebar ?'0px': '70px' }">
    <router-outlet></router-outlet>
  </main>

</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class AlertService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "alert";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * To find all alert in database
   */
  getAlerts() {
    return this.getData("getAlerts", {}, (response) => { return response.data; });
  }

  /**
   * Request a specific alert by id from API
   * @param alert_id id of the alert
   */
  getAlert(alert_id: string) {
    return this.getData("getAlert", { alert_id: alert_id }, (response) => { return response.data; });
  }

  /**
   * Adds a new alert to the database
   * @param alert
   */
  addAlert(alert: any, subscribeMethod) {
    return this.updData("addAlert", { alert: alert }, subscribeMethod);
  }

  /**
    * Updates the given alert to the database
    * @param alert
    */
  updAlert(alert: any, subscribeMethod) {
    return this.updData("updAlert", { alert: alert }, subscribeMethod);
  }

  /**
    * Updates the given alert to the database
    * @param alert_key
    * @param payload
    */
  updAlertByKey(alert_key: string, payload: any) {
    return this.updData("updAlertByKey", { alert_key: alert_key, payload: payload });
  }

  /**
  * Deletes the alert matching with the given id
  * @param alert_id
  */
  delAlert(alert_id: string, subscribeMethod) {
    return this.updData("delAlert", { alert_id: alert_id }, subscribeMethod);
  }

  /**
  * Deletes the alert matching with the given id
  * @param alert_key
  */
  delAlertByKey(alert_key: string, subscribeMethod) {
    return this.updData("delAlertByKey", { alert_key: alert_key }, subscribeMethod);
  }
}
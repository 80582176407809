import { FigureBase } from "./FigureBase";
import { FullPoint } from "./FullPoint";
import { IFigure } from "./IFigure";
import { PlanComponent } from "./plan.component";
import { PlanDrawer } from "./PlanDrawer";
import { PlanLayerBase } from "./PlanLayer";
import { PlanPoint, ZoomPoint } from "./PlanPoint";
import { PlanSize } from "./PlanSize";


export class PlanLayout {

  private component: PlanComponent;
  public Drawer: PlanDrawer;

  public real_size: PlanSize;
  public real_ratio: PlanPoint;

  public plan_size: PlanSize;

  public zoom_ratio: number;
  public zoom_shift: ZoomPoint;

  public accuracy_draw: number;
  public accuracy_select: number;

  private layers: PlanLayerBase[] = [];
  private figures: { [key: string]: IFigure[] } = {};
  private current: IFigure | null = null;
  private cursor: IFigure | null = null;

  constructor(component: PlanComponent, real_width: number, real_height: number, plan_width: number, plan_height: number, accuracy_draw: number | null/*, accuracy_select: number | null*/) {
    this.component = component;
    this.Drawer = new PlanDrawer(this);
    this.zoom_ratio = 1;
    this.zoom_shift = new ZoomPoint(0, 0);
    this.real_size = new PlanSize(real_width, real_height);
    this.plan_size = new PlanSize(plan_width, plan_height);
    this.real_ratio = new PlanPoint(plan_width / real_width, plan_height / real_height);
    this.accuracy_draw = (accuracy_draw || 1) * 1;
    this.accuracy_select = this.accuracy_draw * 2;
    //this.plan_step = this.real_step * this.real_ratio.X;
  }

  public get Context(): CanvasRenderingContext2D {
    return this.component.Context;
  }

  public GetLayer<L extends PlanLayerBase>(TCreator: new () => L, name: string) {
    let layer = new TCreator();
    layer.plan = this.component;
    layer.layout = this;
    layer.name = name;
    this.layers.push(layer);
    return layer;
  }

  public UpdateZoom(grow: boolean, position: ZoomPoint) {
    let new_ratio = this.zoom_ratio;
    let shift = this.zoom_shift;
    let x = grow ? (position.X - shift.X) : -(position.X - shift.X) / 2;
    let y = grow ? (position.Y - shift.Y) : -(position.Y - shift.Y) / 2;

    new_ratio *= grow ? 2 : 0.5;
    if (new_ratio <= 1) {
      shift = new ZoomPoint(0, 0);
      new_ratio = 1;
    } else {
      shift = new ZoomPoint(shift.X - x, shift.Y - y);
    }
    this.SetZoom(shift.X, shift.Y, new_ratio);
  }

  public SetZoom(shift_x: number, shift_y: number, zoom: number | null = null) {
    if (zoom)
      this.zoom_ratio = zoom;
    this.zoom_shift = new ZoomPoint(shift_x, shift_y);
    //this.zoom_step = this.plan_step / this.zoom_ratio;
    this.UpdateFigures();
  }

  public AddFigure(key: string, figure: IFigure, fore_color: string, back_color: string, size: number = 3, draw_points: boolean = true, label: string | null = null) {
    figure.fore_color = fore_color;
    figure.back_color = back_color;
    figure.size = size;
    figure.draw_points = draw_points;
    figure.label = label;

    if (!this.figures[key])
      this.figures[key] = [];
    this.figures[key].push(figure);
  }

  public SetCurrent(figure: IFigure | null) {
    if (figure) {
      figure.draw_points = true;
      figure.fore_color = FigureBase.ConstructionColor;
      figure.back_color = FigureBase.ConstructionBackColor;
    } this.current = figure;
  }

  public SetCursor(figure: IFigure | null) {
    this.cursor = figure;
  }

  public ClearAll() {
    for (let key of Object.keys(this.figures))
      this.figures[key] = [];
  }

  public ClearFigures(key: string) {
    this.figures[key] = [];
  }

  public GetFigures(key: string): IFigure[] {
    return this.figures[key] || [];
  }

  private UpdateFigures() {
    for (let layer of this.layers)
      layer.UpdateFigures();
    for (let key of Object.keys(this.figures))
      for (let figure of this.figures[key])
        figure.Update();
    this.current?.Update();
    this.cursor?.Update();
  }

  public GetNearestPoints(position: FullPoint, allowed_figures: string[]): FullPoint[] {
    let fullpoints: FullPoint[] = [];
    let near = this.accuracy_select;
    for (let key of Object.keys(this.figures))
      if (allowed_figures.includes(key))
        for (let figure of this.figures[key]) {
          let points = figure.GetFullPoints();
          for (let point of points)
            if (point.GetDistanceReal(position) <= near)
              fullpoints.push(point);
        }
    fullpoints = fullpoints.sort((p1, p2) => p1.GetDistanceReal(position) - p2.GetDistanceReal(position));

    // takes the ex-equo nearest points
    let retainedPoints: FullPoint[] = [];
    for (var i = 0; i < fullpoints.length; i++) {
      if (i == 0 || fullpoints[i].GetPoint2D().IsEqual(fullpoints[0].GetPoint2D()))
        retainedPoints.push(fullpoints[i]);
      else
        break;
    }
    return retainedPoints;
  }

  public DrawFigures() {
    for (let layer of this.layers)
      layer.DrawFigures();
    for (let key of Object.keys(this.figures))
      for (let figure of this.figures[key])
        figure.Draw(false);
    this.current?.Draw(true);
    this.cursor?.Draw(true);
  }
}

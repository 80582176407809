import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";


@Component({
  selector: "app-list-button",
  templateUrl: './list-button.component.html',
  styles: []
})
export class ListButtonComponent implements OnInit {

  @Output() click = new EventEmitter();

  private _enabled: boolean = true;
  _kind: string;
  _can_edit: boolean = true;
  _small: boolean = false;
  _icon: string;
  _tooltip: string;

  btn_tooltip: string;
  btn_icon: string;
  btn_text: string;
  show: boolean = true;
  is_small: boolean = false;

  get kind(): string {
    return this._kind;
  }
  @Input('kind') set kind(value: string) {
    this._kind = value;
  }

  get can_edit(): boolean {
    return this._can_edit;
  }
  @Input('can_edit') set can_edit(value: boolean) {
    this._can_edit = value;
  }

  get enabled(): boolean {
    return this._enabled;
  }
  @Input() set enabled(value: boolean) {
    this._enabled = value;
  }

  get small(): boolean {
    return this._small;
  }
  @Input('small') set small(value: boolean) {
    this._small = value;
  }

  get icon(): string {
    return this._icon;
  }
  @Input('icon') set icon(value: string) {
    this._icon = value;
  }

  get tooltip(): string {
    return this._tooltip;
  }
  @Input('tooltip') set tooltip(value: string) {
    this._tooltip = value.replace(/_/g, " ");
  }


  constructor() {
  }

  ngOnInit() {
    this.setValues();
  }

  onClicked(event) {
    event.stopPropagation();
    if (this._enabled)
      this.click.emit(event);
  }

  setValues() {
    this.is_small = this._small;
    switch (this._kind) {
      case "Add":
        this.btn_tooltip = "Create a new record";
        this.btn_text = "Add";
        this.show = this._can_edit;
        break;
      case "Upd":
        this.btn_tooltip = this._can_edit ? "Modify the record" : "See the record";
        this.btn_icon = this._can_edit ? "edit" : "eye";
        this.show = true;
        break;
      case "Del":
        this.btn_tooltip = "Delete the record";
        this.btn_icon = "trash";
        this.show = this._can_edit;
        break;

      case "Ctm":
        this.btn_tooltip = this._tooltip;
        this.btn_icon = this._icon
        this.show = this._can_edit;
        break;
      default:
        break;
    }
  }
}

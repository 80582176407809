
<div class="dashboard" style="background-color:#2B526F; margin:0px;">

  <app-breadcrumb [links]="links"></app-breadcrumb>
  <app-page-title title="{{title}}" [initialized]="initialized" style="background-color:#2B526F"></app-page-title>

  <div class="msg-error">{{error}}</div>

  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">

    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <div style="float:left;">
        <span style="padding-left: 10px; padding-right: 5px; color:white;">{{ '$Equipment family$' | locale }} :</span>
        <select id="equipment_family_id_select" class="search-select-single" [ngModel]="equipment_family_id" (change)="onChangeEquipmentFamily($event)">
          <option *ngFor="let equipment_family of equipment_families" value={{equipment_family._id}}>{{equipment_family.name}}</option>
        </select>
      </div>
      <div style="float:left;">
        <span style="padding-left: 40px; padding-right: 5px; color:white;">{{ 'Assignment' | locale }} :</span>
        <select id="department_id_select" class="search-select-single" [ngModel]="department_id" (change)="onChangeDepartmentAssignment($event)">
          <option *ngFor="let department of departments" value={{department._id}}>{{department.name}}</option>
        </select>
      </div>
      <div style="float:left;">
        <span style="padding-left: 40px; padding-right:5px; color:white;">{{ 'Location' | locale }} :</span>
        <select id="department_location_id_select" class="search-select-single" [ngModel]="department_location_id" (change)="onChangeDepartmentLocation($event)">
          <option *ngFor="let department of departments" value={{department._id}}>{{department.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">
    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <div style="float:left;">
        <span style="float:left; padding-left: 10px; padding-right:5px; color:white;">{{ 'Start date' | locale }} :</span>
        <app-date-time #start_date_input [(date)]="start_date" no_time="true" class="search-select-single" style="float:left; color:white;" (DateChanged)="start_date = $event" ></app-date-time>
      </div>
      <div style="float:left;">
        <span style="float: left; padding-left: 40px; padding-right: 5px; color: white;">{{ 'End date' | locale }} :</span>
        <app-date-time #end_date_input [(date)]="end_date" no_time="true" class="search-select-single" style="float:left;" (DateChanged)="end_date = $event" ></app-date-time>
      </div>
      <span class="current_date" style="color:white">{{ 'Update time' | locale }} : {{current_date | localizedDate:'medium'}}</span>
    </div>
  </div>

  <div class="row-home" style="display:flex;margin-top:20px;">
    <div *ngIf="!pdf_loaded && shown" style="width: 50px; height: 50px">
      <span class="spinner-border" style="margin:10px; width: 1.5rem; height: 1.5rem; color: white" role="status"></span>
    </div>
    <button id="display-report" class="btn-rounded" style="width:200px;" (click)="displayReport()">{{ 'Display' | translate }}</button>
    <button *ngIf="shown" id="download-report" class="btn-rounded" style="width:200px;" (click)="downloadReport()">{{ 'Generate' | translate }}</button>
  </div>



  <div *ngIf="shown" [ngClass]="{'hidden': !pdf_loaded}" id="report-page" style="width:1050px; height:1485px; background:white; margin:auto; padding-left:12px;">

    <br />
    <h3 style="margin: auto; width: 1000px; color: #2B526F; text-align: center;">{{report_title}}</h3>
    <div *ngFor="let sub of sub_titles">
      <label style="width:120px;">{{sub.label}}</label>
      <label style="width:500px; font-weight:bold">{{sub.title}}</label>
    </div>
    <br />

    <div class="row col-md-12 dashboard-container">
      <div class="row col-md-12 nopadding">
        <div class="centered" style="width: 1024px;">
          <app-dashboard-counters id="widget_counters" #widget_counters></app-dashboard-counters>
        </div>
      </div>
    </div>

    <div class="row col-md-12 dashboard-container">
      <div class="centered">
        <div class="row col-md-12 nopadding">
          <app-dashboard-availability *ngIf="building_view" id="widget_availability" #widget_availability></app-dashboard-availability>
          <app-dashboard-availability-status *ngIf="!building_view" id="widget_availability_status" #widget_availability_status></app-dashboard-availability-status>
        </div>
      </div>
    </div>

    <div class="row col-md-12 dashboard-container">
      <div class="centered" style="max-width:1024px;">
        <div class="row col-md-12 nopadding">
          <app-dashboard-usage-rate id="widget_usage_rate" #widget_usage_rate></app-dashboard-usage-rate>
          <app-dashboard-distribution-kind *ngIf="building_view" id="widget_distribution_kind" #widget_distribution_kind></app-dashboard-distribution-kind>
          <app-dashboard-distribution-status *ngIf="!building_view" id="widget_distribution_status" #widget_distribution_status></app-dashboard-distribution-status>
          <app-dashboard-distribution-department id="widget_distribution_department" widget_distribution_department></app-dashboard-distribution-department>

          <app-dashboard-park-age id="widget_park_age" #widget_park_age></app-dashboard-park-age>
          <app-dashboard-input-output-monitoring id="widget_input_output_monitoring" #widget_input_output_monitoring></app-dashboard-input-output-monitoring>
        </div>
      </div>
    </div>
  </div>

</div>

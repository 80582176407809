import { Pipe, PipeTransform } from '@angular/core';

import { colorutils } from '../colorutils';


@Pipe({
  name: 'yesnocolor',
  pure: false
})
export class YesNoColorPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    if (value == null || value == undefined)
      return "";
    return colorutils.getBooleanSoftColor(value);
  }
}
import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ElementRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: "app-multi-select",
  templateUrl: './multi-select.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ],
  host: {
    '(document:click)': 'onClickOutside($event)',
  },
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {

  @Output() Changed = new EventEmitter();

  public label: string = "";
  private labels: string[] = [];
  private _enabled: boolean = true;
  private onChange: Function;
  private onTouched: Function;
  public disabled: boolean;

  public expanded = false;

  // used to prevent a bug in chrome where the list scrolls to the first selected item
  private scroll_offset: number = 0;
  // used to prevent a bug in chrome where the list scrolls to the first selected item
  private is_selecting: boolean = false;

  constructor(private _eref: ElementRef) {
    this.onChange = (_: any) => { };
    this.onTouched = () => { };
  }

  ngOnInit() {
  }

  private onClickOutside(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.expanded = false;
    }
  }

  @Input('filter')
  public _filter: any[] = [];


  get value() {
    return this._filter;
  }
  set value(val) {
    this._filter = val;
    this.onChange(val);
    this.onTouched();
  }

  public _list: any[];

  get list() {
    return this._list;
  }
  @Input() set list(val) {
    this._list = val;
  }

  public updateFilter(val) {
    this._list = val
    this.checkFilter();
    this.onChange(this._filter);
    this.onTouched();
    return this._filter;
  }

  public checkFilter() {
    let temp = [];
    let temp_labels = [];
    for (let filter of this._filter) {
      let item = this._list.find(x => x._id == filter + "");
      if (item) {
        temp.push(filter);
        temp_labels.push(item.name);
      }
    }
    this._filter = temp;
    this.labels = temp_labels;
    this.label = this.labels.join(" - ");
  }

  get enabled(): boolean {
    return this._enabled;
  }
  @Input() set enabled(value: boolean) {
    this._enabled = value;
  }

  writeValue(filter: any[]): void {
    this._filter = filter;
    if (filter)
      this.checkFilter();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.enabled = !disabled;
  }

  public onChanged() {
    this.Changed.emit(this._filter);
  }

  public onMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();

    // if we click on the scroll buttons, we have nothing to do
    if (event.srcElement.name == "main-list")
      return;

    // used to prevent a bug in chrome where the list scrolls to the first selected item
    this.scroll_offset = event.srcElement.parentElement.scrollTop;
    this.is_selecting = true;

    event.target.selected = !event.target.selected;
    let value = event.target.value.split(":")[1].trim().split("'").join("");;
    let label = event.target.text;
    this._filter = this._filter || [];
    if (event.target.selected) {
      this._filter.push(value);
      this.labels.push(label)
    } else {
      this._filter.splice(this._filter.indexOf(value), 1)
      this.labels.splice(this.labels.indexOf(label), 1)
    }
    this.label = this.labels.join(" - ");
    // the onChange will update the ngValue, so it must be called before the onChanged() which will raise the event
    this.onChange(this._filter);
    this.onTouched();
    this.onChanged();
    return false;
  }

  onScroll(event) {
    // used to prevent a bug in chrome where the list scrolls to the first selected item
    if (this.is_selecting)
      event.srcElement.scrollTo(0, this.scroll_offset);
  }

  public onMouseUp(event) {
    event.preventDefault();
    event.stopPropagation();
    // used to prevent a bug in chrome where the list scrolls to the first selected item
    this.is_selecting = false;
  }

  public onClick(event) {
    // avoid propagate the event to the header which would cause an activation of the sort
    event.preventDefault();
    event.stopPropagation();
  }

  public toggleList(event) {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }
}
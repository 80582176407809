

/**
 * Contains the location values of an item
 */
export class Point {
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  x: number;
  y: number;

  public IsEqual(other: Point): boolean {
    return this.x == other.x && this.y == other.y;
  }
}

export class Rectangle {
  constructor(x1: number, y1: number, x2: number, y2: number) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
  }
  x1: number;
  y1: number;
  x2: number;
  y2: number;

  public Contain(point: Point) {
    return point.x >= this.x1 && point.x <= this.x2 && point.y >= this.y1 && point.y <= this.y2;
  }

  public Intersect(other: Rectangle) {
    return !((this.x1 >= other.x2 || this.x2 <= other.x1) || (this.y1 >= other.y2 || this.y2 <= other.y1));
  }
}

/**
 * Contains the size values of an item
 */
export class Size {
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
  width: number;
  height: number;
}


export class DisplayItem {

  lateration_kind: number;
  x: number;
  y: number;
  icon: string;

}




import { Injectable } from '@angular/core';;
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class GatewayService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "gateway";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  getGateways(floorId: string) {
    return this.getData("getGateways", { floorId: floorId }, (response) => { return response.data; });
  }

  getAllGateways() {
    return this.getData("getAllGateways", {}, (response) => { return response.data; });
  }

  getAllGatewaysWithFloor() {
    return this.getData("getAllGatewaysWithFloor", {}, (response) => { return response.data; });
  }

  getDisconnectedGateways(building_id: string) {
    return this.getData("getDisconnectedGateways", { building_id: building_id }, (response) => { return response.data; });
  }


  /**
   * Request a specific gateway by id from API
   * @param gateway_id id of the gateway
   */
  getGateway(gateway_id: string) {
    return this.getData("getGateway", { gateway_id: gateway_id }, (response) => { return response.data; });
  }

  /**
  * Gets the gateway matching with the given cloud id
  * @param cloud_id
  */
  getGatewayByCloudId(cloud_id: string) {
    return this.getData("getGatewayByCloudId", { cloud_id: cloud_id }, (response) => { return response.data; });
  }

  /**
   * Adds a new gateway to the database
   * @param gateway
   */
  addGateway(gateway: any, subscribeMethod) {
    return this.updData("addGateway", { gateway: gateway }, subscribeMethod);
  }

  /**
   * Updates the given gateway to the database
   * @param gateway
   */
  updGateway(gateway: any, subscribeMethod) {
    return this.updData("updGateway", { gateway: gateway }, subscribeMethod);
  }

  /**
  * Delete the gateway matching with the given id
  * @param gateway_id
  */
  delGateway(gateway_id: string, subscribeMethod) {
    return this.updData("delGateway", { gateway_id: gateway_id }, subscribeMethod);
  }

  /**
   * Updates the given gateways to the database
   * @param gateways
   */
  updGateways(gateways: any) {
    return this.updData("updGateways", { gateways: gateways });
  }

  /**
   * Changes the given gateways
   * @param gateways
   */
  changeGateways(to_replace_gateway_id: any, to_replace_gateway_cloud_id: any, replacement_gateway_id: any, replacement_gateway_cloud_id: any) {
    return this.getData("changeGateways", { to_replace_gateway_id: to_replace_gateway_id, to_replace_gateway_cloud_id: to_replace_gateway_cloud_id, replacement_gateway_id: replacement_gateway_id, replacement_gateway_cloud_id: replacement_gateway_cloud_id }, (response) => { return response.data; });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

import { DefaultLayoutComponent } from './containers/layout.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { ForgotPasswordComponent } from './views/authentication/forgot-password/forgot-password.component';


export const routes: Routes = [
  { path: '', redirectTo: 'workflow/work-buildings', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'authentication', loadChildren: () => import('./views/authentication/authentication.module').then(m => m.AuthenticationModule) },

      { path: 'forms', loadChildren: () => import('./views/forms/form.module').then(m => m.FormModule) },
      { path: 'dashboard', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule), },
      { path: 'wizards', loadChildren: () => import('./views/wizards/wizards.module').then(m => m.WizardsModule) },
      { path: 'workflow', loadChildren: () => import('./views/workflow/workflow.module').then(m => m.WorkflowModule) },
      { path: 'details', loadChildren: () => import('./views/details/details.module').then(m => m.DetailsModule) },
      { path: 'administration', loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule), },
      { path: 'supervision', loadChildren: () => import('./views/supervision/supervision.module').then(m => m.SupervisionModule), },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
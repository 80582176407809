import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class DashboardMaintenanceService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "dashboard-maintenance";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
 * Gets the counters to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
 * @return response.data : contains the required data
 **/
  getDashboardCounters(args: any) {
    return this.getData("getDashboardCounters", { args: args }, (response) => { return response.data; });
  }

  getDashboardEquipmentLevels(args: any) {
    return this.getData("getDashboardEquipmentLevels", { args: args }, (response) => { return response.data; });
  }

  getDashboardLevelRates(args: any) {
    return this.getData("getDashboardLevelRates", { args: args }, (response) => { return response.data; });
  }

  getDashboardDistributionKinds(args: any) {
    return this.getData("getDashboardDistributionKinds", { args: args }, (response) => { return response.data; });
  }

  getDashboardDistributionDepartments(args: any) {
    return this.getData("getDashboardDistributionDepartments", { args: args }, (response) => { return response.data; });
  }

  getDashboardMaintenanceHistoryInfos(args: any) {
    return this.getData("getDashboardMaintenanceHistoryInfos", { args: args }, (response) => { return response.data; });
  }

}
<!-- THE PAGE MUST FIT IN 1280 x 640 -->

<div style="padding:0px 8px; background:white;">

  <div style="width:400px;">

    <div style="float:left; width:160px; height:100%; margin-bottom:0px; background:white">
      <h4 style="float:left;">{{'Analysis' | translate}}</h4>
      <span *ngIf="!disable_refresh" style="background-color:darkblue; float:right;" class="image-circle"></span>

      <div style="margin: 0px; clear: both;">
        <app-list-button kind="Ctm" [enabled]="!isloading" (click)="fillHistory()" icon="check-circle" tooltip="Apply_the_filter"></app-list-button>
        <app-list-button kind="Ctm" [enabled]="!isloading" (click)="resetDateRange()" icon="sync-alt" tooltip="Reset_the_filter"></app-list-button>
        <app-list-button kind="Ctm" *ngIf="is_location_view" (click)="playHistory()" icon="play" tooltip="Play_the_history"></app-list-button>
        <span *ngIf="isloading">{{'Loading' | locale}} ...</span>
      </div>
    </div>

    <div style="float: right; width: 180px; height: 100%; margin-right: 24px; background: #CBE0F0; border:1px solid">
      <div style="margin: 0px; clear: both;">
        <div style="width:172px; float:left;">
          <app-date-time [(date)]="start_date" (DateChanged)="start_date = $event"></app-date-time>
        </div>
      </div>

      <div style="margin: 0px; clear: both;">
        <div style="width:172px; float:left;">
          <app-date-time [(date)]="end_date" (DateChanged)="end_date = $event"></app-date-time>
        </div>
      </div>

    </div>

  </div>

  <div *ngIf="current_event" style="width: 100%; clear: both;">
    <hr style="margin:8px" />

    <div>{{ current_event.building_name }} - {{ current_event.floor_name}}  [ <b>{{current_event.floor_level}}</b> ] - {{current_event.department_name}} - {{current_event.room_name}}</div>

    <hr style="margin:4px" />

    <div class="info-ribbon-text" style="clear:both;">
      <div style="float:left; margin-right:8px;">
        <app-list-button kind="Ctm" (click)="navigateSensor()" icon="edit" small="true" tooltip="Modify_the_record"></app-list-button>{{ current_event.sensor_name }}
      </div>
      <div style="float:left;"><b>{{ current_event.reception_level }}</b></div>
      <!--<div *ngIf="!current_event.hasextender" style="float:left;"><b style="float:left; color: darkred; text-decoration: line-through wavy red">&nbsp;{{ "RE"}}&nbsp;</b></div>-->
      <div style="width:auto; float:right; text-align:right;">
        <app-list-button kind="Ctm" (click)="navigateLocation()" icon="map-marker" small="true" tooltip="Locate"></app-list-button>{{ current_event.sensor_last_update | date:'dd/MM/yyyy HH:mm:ss' }}
      </div>
    </div>

    <div class="info-ribbon-text" style="clear:both; text-align:right;">

      <div *ngIf="current_event.debug" style="float:left;">{{ current_event.debug.algo }} <b style="color:red">{{ current_event.oor_info && current_event.oor_info.out_of_range ? " - OOR" : "" }}</b> - {{ current_event.debug.info }}</div>
      <div style="width:auto; float:right;">
        <div *ngIf="current_event.coordinates.type != 2">{{ current_event.coordinates.posX | number:'1.1-1' }} x {{ current_event.coordinates.posY | number:'1.1-1' }}</div>
        <div *ngIf="current_event.coordinates.type == 2">{{ current_event.coordinates.posX1 | number:'1.1-1' }} x {{ current_event.coordinates.posY1 | number:'1.1-1' }} - {{ current_event.coordinates.posX2 | number:'1.1-1' }} x {{ current_event.coordinates.posY2 | number:'1.1-1' }}</div>
        <div *ngIf="current_event.coordinates.type == 1">{{ current_event.coordinates.radius | number:'1.1-1' }}</div>
      </div>
    </div>

  </div>

  <hr style="margin:4px; clear:both;" />

  <div *ngIf="current_event" style="border:1px solid; background:white; width:100%; height:100%; clear:both;">

    <app-position-picker [(ngModel)]="position_point" (change)="updateCollectPoint()"></app-position-picker>

    <div style="display:block">

      <div *ngIf="position_point && position_point.floor_id && position_point.x && position_point.y" style="padding:4px;">
        <app-form-button *ngIf="!collect_point" (click)="addCollectPoint()" tooltip="Create a new collect point" icon="plus" height="22"></app-form-button>
        <app-form-button *ngIf="collect_point" (click)="delCollectPoint()" tooltip="Cancel the collect point" icon="trash" height="22"></app-form-button>
        <app-form-button *ngIf="collect_point" (click)="addCollectEvent()" tooltip="Add a new event to the collect point" icon="plus" height="22"></app-form-button>
        <app-form-button *ngIf="collect_point && collect_point.events.length>0" (click)="delCollectEvent()" tooltip="Remove the last event of the collect point" icon="minus" height="22"></app-form-button>
        <app-form-button *ngIf="collect_point && collect_point.events.length>0" (click)="saveCollectPoint()" tooltip="Save the collect point" icon="save" height="22"></app-form-button>

        <div *ngIf="collect_point" style="float:right;">
          {{ collect_point.events.length }} Events
        </div>
      </div>

    </div>

  </div>

  <hr style="margin:4px; clear:both;" />
  <app-history-location #list (onSelectEvent)="selectEvent($event)"></app-history-location>
  <hr style="margin:4px; clear:both;" />
</div>
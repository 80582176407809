import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class BuildingService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "building";

  constructor(http: HttpClient, token: TokenService, router: Router,
    protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * To find all building in database
   */
  getBuildings() {
    return this.getData("getBuildings", {}, (response) => { return response.data; });
  }

  getBuildingsWithImage() {
    return this.getData("getBuildingsWithImage", {}, (response) => { return response.data; });
  }

  /**
  * Gets the building matching with the given id without the image
  * @param building_id the id of the building to get
  */
  getBuilding(building_id: string) {
    return this.getData("getBuilding", { building_id: building_id }, (response) => { return response.data; });
  }

 /**
  * Gets the building matching with the given id including the image
  * @param building_id the id of the building to get
  */
  getBuildingWithImage(building_id: string) {
    return this.getData("getBuildingWithImage", { building_id: building_id }, (response) => { return response.data; });
  }

  /**
   * Adds a new building to the database
   * @param building
   */
  addBuilding(building: any, subscribeMethod) {
    return this.updData("addBuilding", { building: building }, subscribeMethod);
  }

  /**
    * Updates the given building to the database
    * @param building
    */
  updBuilding(building: any, subscribeMethod) {
    return this.updData("updBuilding", { building: building }, subscribeMethod);
  }

  /**
  * Deletes the building matching with the given id
  * @param buildingId
  */
  delBuilding(buildingId: string, subscribeMethod) {
    return this.updData("delBuilding", { buildingId: buildingId }, subscribeMethod);
  }

  /**
  * Gets the menu informations for the buildings and the floors
  **/
  getBuildingMenu() {
    return this.getData("getBuildingMenu", {}, (response) => { return response.data; });
  }

  /**
  * Checks if the equipment name already exists.
  * @param name the building name to check.
  */
   checkNameExist(id: string, name: string) {
    return this.getData("checkNameExist", { id: id, name: name }, (response) => { return response.data; });
  }
}

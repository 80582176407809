import { OnInit, EventEmitter, Output, Directive } from "@angular/core";
// Highcharts
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import { Subject } from "rxjs";
noData(Highcharts)
HC_exporting(Highcharts);

import { TranslationService } from '../../services/translation.service';
import { logicutils } from "../logicutils";
import { DateRange, DateRangeText } from '../enums';


@Directive()
export abstract class BaseDashboardWidgetComponent implements OnInit {

  @Output() Select = new EventEmitter();

  // dates ranges to use in selects
  public static static_date_ranges: any[];

  // indicates the the current widget should be shown so the chart must shown to allow setting chart options
  public initialized: boolean = false;
  // indicates the data are loaded and the waiting message can be replaced with the chart
  public loaded: boolean = false;

  public date_ranges: any[];
  public date_range = DateRange.Day;
  public use_defined_date_range: boolean = true;
  public cached_data: any = {};
  public args: any = null;

  public Highcharts = Highcharts;

  public loaded_subject: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected translationService: TranslationService) {
    BaseDashboardWidgetComponent._initialize()
    this.date_ranges = BaseDashboardWidgetComponent.static_date_ranges;
  }

  /***
   * This method is the static initializer called at the first use of the type
   * */
  public static _initialize() {
    // in this method this refers to the type, not an instannce
    this.static_date_ranges = logicutils.getEnumNames(DateRange, DateRangeText);
    // this method can be called twice, so disable it after the first call
    this._initialize = () => { }
  }

  ngOnInit() {
  }

  /**
   * Gets the data from the database
   * @param args
   */
  protected abstract onGetData(args: any);

  /**
   * Generates the chart options with the getted data
   * @param data
   */
  protected abstract onGetChartOptions(data: any): any;

  /**
  * Gets the name of the chart asd it is given in the html declaration.
  */
  protected abstract onGetChartName(): string;


  /**
   * Initializes the chart to allow setting chart options and display the waiting message.
   * */
  public async initialize() {
    Highcharts.setOptions({ lang: logicutils.getChartLangOptions(this.translationService) });
    this.cached_data = {};
    this.initialized = true;
    this.loaded = false;
  }

  /**
   * Loads the data by query according with the args and display them.
   * @param args
   */
  public async loadData(args: any) {
    this.args = args;
    this.use_defined_date_range = !args.start_date && !args.end_date;
    args.date_range = Number(this.date_range);
    let data = null;
    if (args.date_range in this.cached_data) {
      data = this.cached_data[args.date_range];
    }
    else {
      data = await this.onGetData(args);
      this.cached_data[args.date_range] = data;
    }
    this.displayData(data);
  }

  /**
   * Displays the data getted by query or calculated in the front.
   * @param data the calculated data to diplay in the chart.
   */
  public displayData(data: any) {
    let chart_name = this.onGetChartName();
    let chart_options = this.onGetChartOptions(data);
    if (chart_name)
      this.displayChartData(chart_name, chart_options);
    this.loaded_subject.next(this.loaded);
  }

  /**
   * Occurs the user select a date range to query the according data and diplay them
   * */
  public async onRangeChanged() {
    await this.loadData(this.args);
  }

  /**
   * Sets the chart options generated by the concrete widget to the chart
   * @param chart_name the nname of the markup which will contains the chart
   * @param options the chart options to set to the chart
   */
  public displayChartData(chart_name, options) {
    this.loaded = true;
    Highcharts.chart(chart_name, options);
  }

  public onClick(data) {
    this.Select.emit(data);
  }
}
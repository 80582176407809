import { Component, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";

import { TranslationService } from "../../services/translation.service";
import { MeasureTypesText } from '../../models/common';
import { dateutils } from '../dateutils';
import { logicutils } from "../logicutils";
import { workutils } from "../workutils";

@Component({
  selector: "app-history-status",
  templateUrl: './history-status.component.html',
})
export class HistoryStatusComponent extends BaseWorkComponent {

  @Output() onExportHistoric = new EventEmitter();

  public is_admin: boolean;
  public rights: any;

  public event_list: any[] = [];

  public events: any[] = [];
  public templates: any[] = [];
  private statuses: any[] = [];
  private customs: any[] = [];
  private equipment_name: string;

  public column_names: string[] = [];
  public column_labels = {};


  public filter: any = { "show_states": true, "show_statuses": false, "show_customs": false };

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any) {
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setItems(event_infos: any[], refresh: boolean = false) {
    this.events = event_infos;
    let templates = [];
    for (var item of this.events)
      templates.push(item["events"][0]);
    this.templates = templates;
    if (refresh) {
      this.updateData();
    }
  }

  public setStatuses(statuses: any[], customs: any[], equipment_statuses: any[], equipment_name: string, refresh: boolean = false) {
    this.statuses = statuses;
    this.customs = customs;
    this.equipment_name = equipment_name;
    if (refresh) {
      this.updateData();
    }
  }

  public changeFilter(evt: any, refresh: boolean = false) {
    this.filter = evt;
    if (refresh) {
      this.updateData();
    }
  }

  public updateData() {
    // filters data
    let values = [];
    let counter = 1;
    this.column_names = [];
    this.column_labels = {};
    // add sensor states
    if (this.filter["show_states"]) {
      // add data
      for (var item of this.events) {
        let evts = item["events"];
        let sensor_name = evts[0]["sensor_name"];
        let measure_types = workutils.getSensorMeasureTypes(evts[0]);
        if (!item["events"][0]["no_locate"]) {
          measure_types.push("building");
          measure_types.push("floor");
          measure_types.push("department");
          measure_types.push("room");
        }
        for (var measure_type of measure_types) {
          let measure_type_text = this.translation_service.translate(MeasureTypesText[measure_type]);

          let name = "column_" + counter++;
          this.column_names.push(name);
          this.column_labels[name] = sensor_name + " (" + measure_type_text + ")";
          for (var evt of evts) {
            var date = new Date(evt.sensor_last_update);
            let value = workutils.getReadableMeasure(this.translation_service, measure_type, evt)
            let data = { date: date };
            data[name] = value;
            // for the touches immediatly set to 0 to have only an impulsion
            if (measure_type == "touch" && evt["touch_count"] > 0) {
              let date = new Date(new Date(evt.sensor_last_update).getTime() + 1000);
              data = { date: date, };
              data[name] = this.translation_service.translate("Waiting");
              values.push(data);
            }
            if (measure_type == "building")
              data[name] = evt["building_name"];
            if (measure_type == "department")
              data[name] = evt["department_name"];
            if (measure_type == "floor")
              data[name] = evt["floor_name"];
            if (measure_type == "room")
              data[name] = evt["room_name"];
            values.push(data);
          }
        }
      }
    }

    // add equipment statuses
    if (this.filter["show_statuses"] && (this.statuses.length > 0)) {
      let name = "column_" + counter++;
      this.column_names.push(name);
      this.column_labels[name] = this.equipment_name + " (" + this.translation_service.translate("Status") + ")";
      for (var status of this.statuses) {
        var date = new Date(status.update_time);
        let value = workutils.getStatusLabelFromStringNumber(status.field_value);
        let data = { date: date };
        data[name] = value;
        values.push(data);
      }
    }

    // add custom fields
    if (this.filter["show_customs"]) {
      for (var custom of this.customs) {
        let key = custom._id;
        let histories = custom.histories;
        let name = "column_" + counter++;
        this.column_names.push(name);
        this.column_labels[name] = this.equipment_name + " (" + key.field_name + ")";
        for (var entry of histories) {
          var date = new Date(entry.update_time);
          let value;
          if (key.field_type == 1 || key.field_type == 2)
            value = entry.field_value;
          else if (key.field_type == 3)
            value = entry.field_value ? this.translation_service.translate("Yes") : this.translation_service.translate("No")
          else if (key.field_type == 5)
            value = entry.field_value;
          else
            value = "";
          let data = { date: date, ddate: date.getTime() };
          data[name] = value;
          values.push(data);
        }
      }
    }

    // aggregate data
    // sort ascending
    values = values.sort((a, b) => { return a.date.getTime() - b.date.getTime(); });
    let previous = { date: values.length > 0 ? values[0]["date"] : null };
    let fields = [];
    for (var i = 1; i < counter; i++) {
      let column_name = "column_" + i;
      previous[column_name] = "";
      fields.push(column_name);
    }
    let lines = [];
    for (var value of values) {
      // if both lines have the same timestamp (<100ms) we modify the refererence of the previous instead of generating new line
      let same_time = ((value['date'].getTime() - previous['date'].getTime()) < 100);
      let line = same_time ? previous : JSON.parse(JSON.stringify(previous));
      // aggregate the new data, to the previous one automaticly add the first record
      for (var key of Object.keys(value))
        line[key] = value[key];
      if (!same_time || lines.length == 0)
        lines.push(line);
      previous = line;
    }
    // fills the list
    // sorts descending : this sort descending but other one with same code sort ascending ??
    lines = lines.sort((a, b) => { return a.date.getTime() - b.date.getTime(); });
    lines = logicutils.getUniqueList(lines, fields);
    lines[0]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(new Date(), lines[0]["date"]));
    for (var i = 1; i < lines.length; i++)
      lines[i]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(lines[i - 1]["date"], lines[i]["date"]));

    // replaces the dates with ISO strings for the export
    for (var line of lines)
      line["date"] = line["date"].toISOString();

    this.event_list = lines;
  }

  public exportHistoric() {
    this.onExportHistoric.emit();
  }

  public exportList(name) {
    let headers: string[] = ["Date", "Durée"];
    for (var column_name of this.column_names)
      headers.push(this.column_labels[column_name]);
    let fields: string[] = ["date", "duration"];
    for (var column_name of this.column_names)
      fields.push(column_name);
    let list = this.event_list;
    let records = logicutils.getCSV(this.translation_service, headers, list, fields);
    logicutils.downloadFileCsv(document, records, name);
  }
}

<div [ngClass]="[initialized ? 'shown' : 'hidden']" style="position:relative;" class="dash-card-back dash-medium">
  <div *ngIf="!loaded" class="float-top-left-spinner">{{'Loading...'|locale}}</div>

  <select *ngIf="use_defined_date_range" class="float-top-right search-select-single" [(ngModel)]="date_range" (change)="onRangeChanged()">
    <option *ngFor="let record of date_ranges" value={{record.key}}>{{record.name | locale}}</option>
  </select>

  <div *ngIf="!use_defined_date_range" class="float-top-right search-select-single">
    {{args.start_date | appdate }} - {{args.end_date | appdate }}
  </div>

  <div id="chart_usage_rate" class="dash-chart"></div>
</div>
import { FindResult } from '../../../common/plan/common/FindResult';
import { PlanComponent } from '../../../common/plan/plan.component';


export abstract class DisplayBase {

  public Result: FindResult;
  protected plan: PlanComponent;
  protected floors: any[];
  protected rooms: any[];
  protected current_floor_id: string;

  constructor(result: FindResult, plan: PlanComponent, floors: any[], rooms: any[]) {
    this.Result = result;
    this.plan = plan;
    this.floors = floors;
    this.rooms = rooms;
  }

  public Clear() {
    this.plan.ClearAll();
    this.plan.Initialize(null, 400, 400, null);
  }

  public async Draw(index: number) {
    if (!this.Result.Points.length)
      return;
    await this.OnDraw(index);
  }

  protected abstract OnDraw(index: number): Promise<void>;
}

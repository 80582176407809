
<style>
  table.fixed {
    table-layout: fixed;
  }

    table.fixed td,
    table.fixed th {
      overflow: hidden;
    }
</style>

<table class="table compact scrollable fixed" style="margin: 0px; width: 100%; table-layout: fixed; word-wrap: break-word; white-space: nowrap; overflow:hidden;">
  <thead>
    <tr>
      <th style="width: 60px; max-width: 60px; overflow: hidden;">
        <div style="margin-top: -4px; margin-bottom: -8px;">
          <app-list-button kind="Ctm" (click)="exportHistoric()" small="true" icon="download"></app-list-button>
        </div>
        <!--{{ 'Date' | locale }}-->
      </th>
      <th style="width: 40px; max-width: 40px; overflow: hidden; ">(h:m)</th>
      <th style="width: 54px; max-width: 54px; overflow: hidden; ">{{ 'Building' | locale }}</th>
      <th style="width: 54px; max-width: 54px; overflow: hidden; ">{{ '$Floor$' | locale }}</th>
      <th style="width: 54px; max-width: 54px; overflow: hidden; ">{{ 'Department' | locale }}</th>
      <th style="width: 54px; max-width: 54px; overflow: hidden; ">{{ 'Room' | locale }}</th>
      <th style="width: 60px; max-width: 60px; overflow: hidden; ">{{ 'X x Y' | locale }}</th>
    </tr>
  </thead>
  <tbody style="max-height:200px">
    <tr *ngFor="let event of event_list; let index = index" (click)="selectEvent(event)" [ngClass]="{'highlighted-row' : !!event.selected}">
      <td style="width: 60px; max-width: 60px; overflow: hidden; text-align: right; " title="{{event.sensor_last_update | localizedDate:'medium' }}" [ngStyle]="{'font-weight': event.change_day ? 'bold' :'' }">{{event.sensor_last_update | localizedDate:'HH:mm:ss' }}</td>
      <td style="width: 40px; max-width: 40px; overflow: hidden; text-align: right; ">{{event.duration }}</td>
      <td style="width: 54px; max-width: 54px; overflow: hidden; " title="{{event.building_name}}" [ngStyle]="{'font-weight': event.change_building ? 'bold' :'' }">{{event.building_name}}</td>
      <td style="width: 54px; max-width: 54px; overflow: hidden; " title="{{event.floor_name}}" [ngStyle]="{'font-weight': event.change_floor ? 'bold' :'' }">{{event.floor_name}}</td>
      <td style="width: 54px; max-width: 54px; overflow: hidden; " title="{{event.department_name}}" [ngStyle]="{'font-weight': event.change_department ? 'bold' :'' }">{{event.department_name}}</td>
      <td style="width: 54px; max-width: 54px; overflow: hidden; " title="{{event.room_name}}" [ngStyle]="{'font-weight': event.change_room ? 'bold' :'' }">{{event.room_name}}</td>
      <td style="width: 60px; max-width: 60px; overflow: hidden; " title="{{event.coordinates}}" [ngStyle]="{'font-weight': event.coordinates ? 'bold' :'' }">{{event.coordinates}}</td>
    </tr>
  </tbody>
</table>
import { Point } from '../../../models/types';
import { mathutils } from '../../mathutils';


export class PlanItem {

  public point: Point;
  public icon: string;
  public icon_color_filter: string
  public state_key: string;
  public hidden: boolean;

  public tooltip: string;
  public list_data: string;
  public record: any;

  constructor(record: any) {
    this.record = record;
  }

  public isNear(item: PlanItem) {
    if (!this.point || !item.point)
      return false;
    return mathutils.getDistancePoint(this.point, item.point) < 40;
  }
}
import { Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-toggle-editor label="Enregistrement" [(ngModel)]="boolvalue" width="100px" disabled></app-toggle-editor>
 */
@Component({
  selector: 'app-toggle-editor',
  templateUrl: 'toggle.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleEditor),
      multi: true
    }
  ]
})
export class ToggleEditor extends EditorBase<boolean>{

  constructor() {
    super();
  }
}

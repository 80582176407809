
<div class="dialog-header">
  <app-page-title title="{{title}}" mode="popup_mode"></app-page-title>
</div>

<div class="dialog-body">

  <form [formGroup]="editForm" style="display: flex;">

    <input type="hidden" formControlName="_id" name="_id" id="_id">

    <div class="col-md-6 no-padding">

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5">
            <label>{{ 'Equipment' | translate }} :</label>
            <button class="btn btn-primary btn-small" type="button" (click)="createEquipment()"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
          <div class="col-md-7">
            <!--TOSEE : Add the null value in the list or manage null in app-form-select and use app-form-select-->
            <!--**************************************************************-->
            <select class="form-control" formControlName="equipment_data">
              <option [ngValue]="null" *ngIf="!is_create"></option>
              <option *ngFor="let equipment of equipments" [ngValue]=equipment._id>{{equipment.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Cloud Id' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.cloud_id" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Name' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.name" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Description' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.description" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Type' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-select [field]="form.type" [list]="types" id="key" text="name" [submitted]="submitted"></app-form-select>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Kit' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-input [field]="form.kit" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Event delay' | locale }}{{' (min)'}} :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.event_delay" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Battery level' | locale }}{{' (%)'}} :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.battery_percent" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Battery autonomy' | locale }} :</label></div>
          <div class="col-md-7">
            <input class="form-control" id="battery_autonomy_formated" disabled="" value="{{battery_autonomy_formated}}">
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Battery update time' | locale }} :</label></div>
          <div class="col-md-7">
            <app-form-date [field]="form.battery_update_time" [submitted]="submitted"></app-form-date>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-6 no-padding">

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Reception Level' | translate }} :</label></div>
          <div class="col-md-7" style="float:none">
            <app-form-select [field]="form.reception_level" [list]="reception_levels" id="key" text="name" [submitted]="submitted"></app-form-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Interior' | translate }} :</label></div>
          <div class="col-md-7" style="float:none">
            <input type="checkbox" formControlName="is_internal" class="form-control" style="float:left">
            <div style="float:left; padding:4px;" class="legend">{{'Sensor is placed inside' | locale}}</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'No locate' | translate }} :</label></div>
          <div class="col-md-7" style="float:none">
            <input type="checkbox" formControlName="no_locate" class="form-control" style="float:left">
            <div style="float:left; padding:4px;" class="legend">{{'Sensor is not used in location' | locale}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Technician' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.collect_number" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Mobility' | translate }} :</label></div>
          <div class="col-md-7">
            <app-form-select [field]="form.mobility" [list]="mobilities" id="key" text="name" [submitted]="submitted"></app-form-select>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Floor' | translate }} :</label> </div>
          <div class="col-md-7">
            <!--TOSEE : Add the null value in the list or manage null in app-form-select and use app-form-select-->
            <!--**************************************************************-->
            <select class="form-control" formControlName="floor_data" [ngClass]="{ 'is-invalid': submitted && form.floor_data.errors }">
              <option [ngValue]="null" *ngIf="!is_create"></option>
              <option *ngFor="let floor of floors" [ngValue]=floor._id>{{floor.building_data?.name}} - {{floor.name}}</option>
            </select>
            <app-form-error [field]="form.floor_data" [submitted]="submitted"></app-form-error>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>X (m) :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.x" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor']" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>Y (m) :</label></div>
          <div class="col-md-7">
            <app-form-input type="number" [field]="form.y" [submitted]="submitted"></app-form-input>
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor'] && show_temperature_internal" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Calculate internal temperature' | locale }} :</label></div>
          <div class="col-md-7">
            <input type="checkbox" formControlName="calculate_temperature_internal" class="form-control">
          </div>
        </div>
      </div>

      <div *ngIf="rights['right_supervisor'] && show_temperature_internal && use_temperature_internal" class="form-group">
        <div class="row row-center">
          <div class="col-md-5"><label>{{ 'Internal temperature' | locale }} :</label></div>
          <div class="col-md-7"><label>{{ temperature_internal }}</label></div>
        </div>
      </div>

    </div>
  </form>

</div>

<div class="dialog-footer">
  <button class="btn-rounded btn-add" (click)="onSubmit()">{{ 'Save' | locale }}</button>
  <button class="btn-rounded btn-close" (click)="close(false)">{{ 'Close' | locale }}</button>
  <button class="btn-rounded btn-light" (click)="sendLedOn()">{{ 'Identify' | locale }}</button>
</div>
import { mathutils } from "../../mathutils";
import { AngularPoint, Point2D } from "./Point2D";


export class PointFloor {

  public x: number;
  public y: number;
  public floor_id: string;

  constructor(x: number, y: number, floor_id: string) {
    this.x = x;
    this.y = y;
    this.floor_id = floor_id;
  }

  public static FromJson(json: any, floor_id: string) {
    return new PointFloor(json.x, json.y, floor_id);
  }

  public static FromArray(array: [number, number, string]) {
    return new PointFloor(array[0], array[1], array[2]);
  }

  public static FromPoint2D(point: Point2D, floor_id: string) {
    return new PointFloor(point.x, point.y, floor_id);
  }

  public IsEqual(other: PointFloor): boolean {
    return this.x == other.x && this.y == other.y && this.floor_id == other.floor_id;
  }

  public ToString(): string {
    return this.x * 1 + "," + this.y * 1 + "," + this.floor_id;
  }

  public ToArray(): [number,number,string] {
    return [this.x, this.y, this.floor_id];
  }

  public ToJson(): any {
    return { x: this.x, y: this.y };
  }

  public GetDistance(position: PointFloor): number {
    return Math.hypot(position.x - this.x, position.y - this.y);
  }

  public GetAngle(position: PointFloor): number {
    return Math.atan2(position.y - this.y, position.x - this.x);
  }

  public Substract(origin: PointFloor) {
    return new PointFloor(this.x - origin.x, this.y - origin.y, this.floor_id);
  }

  public Add(origin: PointFloor) {
    return new PointFloor(this.x + origin.x, this.y + origin.y, this.floor_id);
  }

  public Shift(x: number, y: number) {
    return new PointFloor(this.x + x, this.y + y, this.floor_id);
  }

  public GetAngularPoint(): AngularPoint {
    var distance = Math.hypot(this.x, this.y);
    var angle = mathutils.LimitAngle(Math.atan2(this.y, this.x));
    return new AngularPoint(angle, distance);
  }

  //public Round(step: number): PointFloor {
  //  return new PointFloor(mathutils.RoundStep(this.x, step), mathutils.RoundStep(this.y, step), this.floor_id);
  //}

  //public static GetPoint(origin: PointFloor, angle: number, distance: number): PointFloor {
  //  return origin.Add(new AngularPoint(angle, distance).GetCartesianPoint3D(origin.floor_id));
  //}

  //public static GetPolygon(coordinates: [number, number, string][]) {
  //  let pts: PointFloor[] = [];
  //  for (let coordinate of coordinates)
  //    pts.push(new PointFloor(coordinate[0], coordinate[1], coordinate[2]));
  //  return pts;
  //}
}


import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldType, FieldConfig, Validator } from '../../models/common';


@Component({
  selector: "app-select",
  template: `
    <div class= "form-group" [formGroup]="group">
      <div class="row row-center">
        <div class="col-md-3">      
          <label for="field.name">{{field.label}} :</label>
        </div>
        <div class="col-md-5">
        <select class="form-control" [formControlName]="field.name">    
          <option *ngFor="let item of field.options" [value]="item">{{field.translate ? (item | translate) : item}}</option>
        </select>   
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
      </div>
    </div>`,  
  styles: []
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}

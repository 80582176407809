import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, ContentChild, EventEmitter, Output } from '@angular/core';
import { Router } from "@angular/router";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { TranslateService } from '@ngx-translate/core';
import { FilterKind } from '../enums';
import { logicutils } from '../logicutils';


@Component({
  selector: "app-list",
  templateUrl: './list-component.html',
})
export class ListComponent implements OnInit, AfterViewInit {

  @ContentChild("actionsheader", { static: true }) actionsheader: TemplateRef<any>;
  @ContentChild("actionsrows", { static: true }) actionsrows: TemplateRef<any>;

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  @Output() filterChanged = new EventEmitter();

  FilterKind = FilterKind;

  // the records diplayed in the list
  public records: any[];
  // the datasource used by the list
  public data_source: MatTableDataSource<any>;

  // defintions of the columns allowing to specify if if filter use select
  public column_definitions: any[] = null;
  // represents all the shown columns including actions
  public displayed_columns: string[];
  // used to bind the filters
  public filter_entity: any;


  /**
   * Initialize a new instance of BaseListComponent
   * @param router the router to manage routing between pages
   * @param authenticationService the authencationService to check user rights
   */
  constructor(
    protected router: Router,
    protected translate: TranslateService) {
  }

  async ngOnInit() {
  }

  ngAfterViewInit() {
  }

  public async setData(column_definitions, records) {
    this.column_definitions = column_definitions;
    // use another column to sort (ex : for formated dates)
    let sort_mapping = {};
    for (let definition of this.column_definitions)
      if (definition.sort_column)
        sort_mapping[definition.name] = definition.sort_column;
    this.data_source = logicutils.getListDataSource(this.records, this.paginator, this.sort, this.translate, sort_mapping);

    if (this.column_definitions) {
      this.filter_entity = {}
      // fills the displayed column names
      let columns = []
      for (let definition of this.column_definitions)
        columns.push(definition.name);
      columns.push("actions");
      this.displayed_columns = columns;
      // initialize the predicate
      this.data_source.filterPredicate = (data: any, filtersJson: string) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);
        filters.forEach(filter => {
          const value = !!(data[filter.id] + "") ? (data[filter.id] + "") : "";
          let match: boolean = false;
          if (filter.filter_kind === FilterKind.Select)
            match = !filter.value ? true : value == filter.value;
          else if (filter.filter_kind === FilterKind.MultiSelect)
            match = filter.value.length === 0 || !!filter.value.find(x => x === value);
          else
            match = value.toLowerCase().includes(filter.value.trim().toLowerCase());

          matchFilter.push(match);
        });
        return matchFilter.every(Boolean); // AND condition
        // return matchFilter.some(Boolean); // OR condition
      }
    }
    this.updateData(records);
  }

  public async setSort(column: string, direction: Number) {
    const sort_state: Sort = { active: column, direction: direction > 0 ? 'asc' : 'desc' };
    this.sort.active = sort_state.active;
    this.sort.direction = sort_state.direction;
    this.sort.sortChange.emit(sort_state);
  }

  public async updateData(records) {
    this.records = records;
    this.data_source.data = this.records;
    // fills the select options if required
    if (this.column_definitions) {
      for (let definition of this.column_definitions) {
        if (definition.filter_kind === FilterKind.Select) {
          definition.select_options = this.getUniqueValues(this.records, definition.name, true);
        }
        else if (definition.filter_kind === FilterKind.MultiSelect) {
          definition.select_options = this.formatToJson(this.getUniqueValues(this.records, definition.name, false));
          if (!this.filter_entity[definition.name])
            this.filter_entity[definition.name] = [];
        }
      }
    }
  }

  public setFilter(column_name: string, value: any) {
    this.filter_entity[column_name] = value;
  }

  public onSearch() {
    const table_filters = [];
    for (let key of Object.keys(this.filter_entity)) {
      let column = this.column_definitions.find(c => c.name == key);
      let value = this.filter_entity[key];
      if (value)
        table_filters.push({ id: key, value: value, filter_kind: column.filter_kind });
    }
    this.data_source.filter = JSON.stringify(table_filters);
    if (this.data_source.paginator)
      this.data_source.paginator.firstPage();
    this.filterChanged.emit();
  }

  public getData(): any {
    return this.data_source.data;
  }

  public getFileredData(): any {
    return this.data_source.filteredData;
  }

  /**
   * Gets all the values of a column
   * @param records
   * @param key
   * @param add_empty : adds an empty element representing no selection
   */
  private getUniqueValues(records, key, add_empty = true) {
    const unique_values = [];
    if (add_empty)
      unique_values.push("");
    if (records)
      for (var obj of records)
        if (obj[key] && !unique_values.includes(obj[key]))
          unique_values.push(obj[key]);
    return unique_values;
  }

  private formatToJson(list) {
    let new_list = [];
    list.forEach(element => {
      new_list.push({ "_id": element, "name": element });
    });
    return new_list;
  }
}


export enum ColorIntensity {
  Soft = 0,
  Mid = 1,
  Hard = 2,
}


export enum ErrorLevel {
  "Info" = 0,
  "Ok" = 1,
  "Warning" = 2,
  "Error" = 3,
};

export enum AlertStatus {
  "New" = 0,
  "InProgress" = 1,
  "Closed" = 2,
};

export enum AlertStatusText {
  "New" = "New",
  "InProgress" = "InProgress",
  "Closed" = "Closed",
};

/** The kind of date for the editors */
export enum DateKind {
  Datetime = 0,
  Date = 1,
  Time = 2,
  Month = 3,
  Year = 4
}

export enum TimeFormat {
  "None" = 0,
  "HH_MM" = 1,
  "HH_MM_SS" = 2,
};

export enum DurationFormat {
  "None" = 0,
  "HH_MM" = 1,
  "HH_MM_SS" = 2,
  "HH_MM_SS_MS" = 3,
  "MM_SS" = 4,
  "MM_SS_MS" = 5,
  "SS_MS" = 6,
};

export enum ReportFrequency {
  Daily = 0,
  Weekly = 1,
  Monthly = 2
}

export enum ReportFrequencyText {
  Daily = "Every day",
  Weekly = "Every week",
  Monthly = "Every month"
}

export enum AlertLevel {
  "Info" = 0,
  "Warning" = 1,
  "Error" = 2,
};

export enum AlertLevelText {
  "Info" = "Information",
  "Warning" = "Warning",
  "Error" = "Error",
};


export enum MessageLevel {
  "Info" = 0,
  "Success" = 1,
  "Warning" = 2,
  "Error" = 3,
};

export enum MessageLevelText {
  "Success" = "Success",
  "Info" = "Information",
  "Warning" = "Warning",
  "Error" = "Error",
};


export enum AlertOrigin {
  "User" = 0,
  "Rule" = 1,
  "App" = 2,
};

export enum AlertOriginText {
  "User" = "User",
  "Rule" = "Rule",
  "App" = "Application",
};


export enum HomecareLevel {
  "UpToDate" = 1,
  "Forthcoming" = 2,
  "Upcoming" = 3,
  "OutOfDate" = 4,
  "Unknow" = 5,
  "Returned" = 6,
};

export enum HomecareLevelText {
  "UpToDate" = "Up to date",
  "Forthcoming" = "Forthcoming",
  "Upcoming" = "Upcoming",
  "OutOfDate" = "Out of date",
  "Unknow" = "Unknow",
  "Returned" = "Returned",
};

export enum MaintenanceLevel {
  "UpToDate" = 1,
  "Forthcoming" = 2,
  "Upcoming" = 3,
  "OutOfDate" = 4,
  "NoActivation" = 5,
  "WrongMaintenance" = 6,
};

export enum MaintenanceLevelText {
  "UpToDate" = "Up to date",
  "Forthcoming" = "Forthcoming",
  "Upcoming" = "Upcoming",
  "OutOfDate" = "Out of date",
  "NoActivation" = "No Activation",
  "WrongMaintenance" = "Faulty Maintenance",
};


export enum DrawKind {
  "None" = 0,
  "Select" = 1,
  "FreeLines" = 2,
  "NormalizedLines" = 3,
  "Rectangles" = 4,
};

export enum DrawKindText {
  "None" = "No draw",
  "Select" = "Select",
  "FreeLines" = "Free lines",
  "NormalizedLines" = "Normalized lines",
  "Rectangles" = "Rectangles",
};

export enum RecordKind {
  "No" = 0,
  "Sensor" = 1,
  "Gateway" = 2,
  "Equipment" = 3,
  "EquipmentKind" = 4,
  "SensorCloudId" = 10,
  "GatewayCloudId" = 11,
}

export enum RecordKindText {
  "No" = "None",
  "Sensor" = "Sensor",
  "Gateway" = "Gateway",
  "Equipment" = "Equipment",
  "EquipmentKind" = "Equipment kind",
  "SensorCloudId" = "Sensor",
  "GatewayCloudId" = "Gateway",
}

export enum FilterKind {
  "Text" = 0,
  "Select" = 1,
  "MultiSelect" = 2,
  "Date" = 3,
}

export enum Rights {
  Sites = "right_sites",
  Equipments = "right_equipments",
  Devices = "right_devices",
  Users = "right_users",
  Location = "right_location",
  History = "right_history",
  Inventory = "right_inventory",
  Search = "right_search",
  Identification = "right_identification",
  Association = "right_association",
  Rules = "right_rules",
  Statistics = "right_statistics",
  Maintenance = "right_maintenance",
  Thermic_map = "right_thermic_map",
  Export = "right_export",
  Report = "right_report",
  Api = "right_api",
  Supervisor = "right_supervisor",
};

export enum MailKind {
  "Information" = 1,
  "Evolution" = 2,
  "Bug" = 11,
  "Other" = 101,
};

export enum MailKindText {
  "Information" = "Information request",
  "Evolution" = "Evolution request",
  "Bug" = "Report a bug",
  "Other" = "Other",
};


export enum MessageKindCode {
  "Information" = "Info",
  "Evolution" = "Evol",
  "Bug" = "Bug",
  "Other" = "Other",
};


export enum DateRange {
  Day = 0,
  Week = 1,
  Month = 2,
  ThreeMonth = 3,
  SixMonth = 4,
  Year = 5,
}

export enum DateRangeText {
  Day = "For 1 day",
  Week = "For 1 week",
  Month = "For 1 month",
  ThreeMonth = "For 3 months",
  SixMonth = "For 6 months",
  Year = "For 1 year",
}


export enum RedirectKind {
  None = 0,
  LocationFromEquipmentId = 1,
  LocationFromEquipmentName = 2,
  LocationFromSensorCloudId = 3,

  HistoryFromEquipmentId = 5,
  HistoryFromEquipmentName = 6,
  HistoryFromSensorCloudId = 7,

  AnalysisFromEquipmentId = 11,
  AnalysisFromEquipmentName = 12,
  AnalysisFromSensorCloudId = 13,
}

export enum RuleEventMethod {
  Method1 = 1, // as soon
  Method2 = 2, // strict
  Method3 = 3, // constant
}

export enum RuleEventMethodText {
  Method1 = "Method1", // as soon
  Method2 = "Method2", // strict
  Method3 = "Method3", // constant
}

export enum RuleEventMethodDescription {
  Method1 = "The target must be reached at one of the event in the range",
  Method2 = "The target must be reached at the defined event",
  Method3 = "The target must be reached at the defined event and the evelution must be in the same sens for all events",
}

export enum StorageFields {
  RememberMe = "remember_me",
  CurrentToken = "current_token",
  CurrentUserId = "current_user_id",
  CurrentUsername = "current_username",
  DashboardView = "dashboard_view",
  UserFloorId = "user_floor_id",
  UserAffectationDepartmentId = "user_affectation_department_id",
  UserLocationDepartmentId = "user_location_department_id",
  CurrentUserRights = "current_user_rights",
  CurrentUserIsInstaller = "current_user_is_installer",
  FrontSettings = "front_settings",
  CompanySettings = "company_settings",
  BackSettings = "back_settings",
  SearchPageFilters = "search_page_filters",
  CollectNumber = "collect_number",
  CurrentLang = "current_lang",
}
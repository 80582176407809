
<div class="wrapper-content">
  <div class="mat-elevation-z8">
    <!--<div class="table-container">-->
      <table class="mat-table-s-row" mat-table [dataSource]="data_source" matSort>

        <!-- list columns -->
        <ng-container *ngFor="let column of column_definitions">
          <ng-container matColumnDef="{{column.name}}">

            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div>{{ column.label | locale }}</div>
              <input *ngIf="!column.filter_kind" class="form-control" [(ngModel)]="filter_entity[column.name]" (click)="$event.stopPropagation();" (keyup)="onSearch()">
              <input *ngIf="column.filter_kind === FilterKind.Date" class="form-control" [(ngModel)]="filter_entity[column.name]" (click)="$event.stopPropagation();" (keyup)="onSearch()">
              <select *ngIf="column.filter_kind === FilterKind.Select" [(ngModel)]="filter_entity[column.name]" class="form-control" (click)="$event.stopPropagation();" (change)="onSearch()">
                <option [value]="item" *ngFor="let item of column.select_options">{{item}}</option>
              </select>
              <app-multi-select *ngIf="column.filter_kind === FilterKind.MultiSelect" [(ngModel)]="filter_entity[column.name]" [list]="column.select_options" (Changed)="onSearch();"></app-multi-select>
            </th>

            <td mat-cell *matCellDef="let row" [ngStyle]="{'color': row.row_color ? row.row_color : '#000088' }">
              <div *ngIf="column.point_color" [style.backgroundColor]=row[column.point_color] class="image-circle"></div>
              {{column.filter_kind === FilterKind.Date ? (row[column.name] | date:'short') : (row[column.name])}}
            </td>

          </ng-container>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;width:140px;">
            <ng-template [ngTemplateOutlet]="actionsheader"></ng-template>
          </th>
          <td mat-cell *matCellDef="let row" data-label="actions" style="text-align: center">
            <ng-template [ngTemplateOutlet]="actionsrows" [ngTemplateOutletContext]="{$implicit: row}"></ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayed_columns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayed_columns;"></tr>

      </table>
    <!--</div>-->
    <mat-paginator [showFirstLastButtons]=true [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>

<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">

  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>

  <select type="text" [name]="name" [(ngModel)]="value" [disabled]="_disabled" style="width:120px;">
    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
  </select>

</div>

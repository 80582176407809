import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-select-editor label="Enregistrements" [records]="list" [empty]="''" [(ngModel)]="record_id" (Changed)="OnChanged()" width="100px" disabled></app-select-editor>
 */
@Component({
  selector: 'app-text-editor',
  templateUrl: 'text.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditor),
      multi: true
    }
  ]
})
export class TextEditor extends EditorBase<string> {

  @Input()
  public type: string = "text";

  @Input()
  public align: string | null = null;

  @Input()
  public maxlength: string | null = null;

  @Input()
  public minlength: string | null = null;

  constructor() {
    super();
  }
}

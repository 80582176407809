import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { colorutils } from '../../../../common/colorutils';
import { DashboardService } from '../../../../services/dashboard.service';
import { TranslationService } from '../../../../services/translation.service';
import { DashCardLiteInfo } from '../../dashboard';


@Component({
  selector: "app-dashboard-counters-battery-months",
  templateUrl: './dashboard-counters-battery-months.component.html',
})
export class DashboardCountersBatteryMonthsComponent extends BaseDashboardWidgetComponent {

  public cards: DashCardLiteInfo[] = [];
  public card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardCountersBatteryMonths(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let years = this.translationService.translate("years");
    let year = this.translationService.translate("year");
    let months = this.translationService.translate("months");
    let cards = [];
    let data_levels = data.levels;
    cards.push(new DashCardLiteInfo(data.total_sensors, this.translationService.translate('Total'), null, colorutils.Default));
    let levels = [
      {
        level: "0",
        label: "< 6 " + months,
        color: "rgb(235, 101, 106)"
      },
      {
        level: "1",
        label: "6 " + months + " - 1 " + year,
        color: "rgb(242,120,96)"
      },
      {
        level: "2",
        label: "1 - 2 " + years,
        color: "rgb(255,161,80)"
      },
      {
        level: "6",
        label: this.translationService.translate("Unknown"),
        color: "rgb(208, 191, 169)"
      },
      {
        level: "3",
        label: "2 - 5 " + years,
        color: "rgb(230,170,94)"
      },
      {
        level: "4",
        label: "5 - 10 " + years,
        color: "rgb(180,180,116)"
      },
      {
        level: "5",
        label: "> 10 " + years,
        color: "rgb(144, 193, 130)"
      },
    ];
    for (let level of levels)
      cards.push(new DashCardLiteInfo(data_levels[level["level"]] || 0, level["label"], null, level["color"], level["level"]));
    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;
  }
}

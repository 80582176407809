<div class="login-body">
  <img src="assets/img/login/background.jpg" class="login-img-background" alt="">
  <div class="login-container animated fadeInDown">
      <div class="login-row row">
          <div class="col-md-6 login-col-left">
              <img src="assets/img/login/left-col.svg" alt="">
          </div>
          <div class="col-md-6 login-col-right">
              <form role="form" [formGroup]="forgotForm">
                  <div class="login-logo">
                      <img src="assets/img/login/logo.png" alt="">
                  </div>
                  <p class="login-text">
                    {{ 'Reset the password' | translate }}
                  </p>
                  <div class="login-input-username">
                    <img src="assets/img/login/mail.png" alt="" class="login-input-icon">
                  <input id="mail" formControlName="mail" type="text" class="login-input" placeholder="{{ 'Email' | translate }}" autocomplete="email" required>
                </div>
                  <button type="submit" id="send_button" style="width:180px;" (click)="initPwd()" class="btn-rounded login-btn-submit">{{ 'Send the mail' | translate }}</button>
                  <button class="btn login-link-forgot" id="close_button" (click)="close()">
                    {{ 'Return' | translate }}
                  </button>
                  <div class="login-div-error">
                    <em id="error_message" class="login-i-error"> {{ error | translate }} </em>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
<div *ngIf="!loaded" class="row col-md-12 nopadding row-counter">
  <div *ngFor="let item of [].constructor(8); let i = index" class="dash-card-back">
    <div class="dash-card-left">
      <span class="spinner-border" style="margin:28px 32px; width: 1.5rem; height: 1.5rem;" role="status"></span>
    </div>
    <div class="dash-card-right">
      <div class="dash-card-title">{{'-'}}</div>
      <div class="dash-card-text">{{'Loading...'|locale}}</div>
    </div>
  </div>
</div>

<div *ngIf="loaded" class="row col-md-12 nopadding row-counter">
  <div *ngFor="let card of cards" class="dash-card-back" [ngStyle]="{'margin-left': card_margin, 'margin-right': card_margin }" (click)="onClick(card.data)">
    <div class="dash-card-left">
      <div class="dash-card-icon" [style.backgroundColor]=card.color>
        <i class="fa fa-{{card.icon}}" aria-hidden="true"></i>
      </div>
    </div>
    <div class="dash-card-right">
      <div class="dash-card-title">{{card.title}}</div>
      <div class="dash-card-text">{{card.text}}</div>
    </div>
  </div>
</div>
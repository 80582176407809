import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  error: String;
  result: String;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
  ) {
    this.forgotForm = fb.group({
      mail: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.error = '';
  }

  close() {
    this.router.navigate(['/login']);
  }

  validityMail() {
    const re = new RegExp('^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,4}$', 'g');
    return re.test(this.fc.mail.value);
  }

  get fc() {
    return this.forgotForm.controls;
  }

  initPwd() {
    this.error = '';
    if (this.forgotForm.invalid) {
      this.error = "Please enter an email address";
      return;
    }
    if (this.validityMail() === false) {
      this.error = "The email address is not valid";
      return;
    }
    let lang = this.translate.currentLang;
    if (!lang)
      lang = "en";
    this.authenticationService.forgotPassword(this.fc.mail.value, lang.toLowerCase()).subscribe((response) => {
      if (response)
        this.close();
      else
        this.error = 'No account matches this username';
    });
  }
}

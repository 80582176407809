import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { PointFloor } from '../common/plan/common/PointFloor';
import { ServiceBase } from './base.service';
import { CoreService } from './core_service';
import { TokenService } from './token.service';


@Injectable({ providedIn: 'root' })
export class FindService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "find";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  public async GetSpots(): Promise<any> {
    return await lastValueFrom(this.getData("get-spots", {}, (response) => { return response.data; }));
  }

  public async FindSpot(start_spot_id: string, target_spot_id: string): Promise<any> {
    return await lastValueFrom(this.getData("find-spot", { start_spot_id, target_spot_id }, (response) => { return response.data; }));
  }

  public async FindPoint(start_point: PointFloor, target_point: PointFloor): Promise<any> {
    return await lastValueFrom(this.getData("find-point", { start_point, target_point }, (response) => { return response.data; }));
  }

  public async FindRssiSpot(rssis: any, target_spot_id: string): Promise<any> {
    return await lastValueFrom(this.getData("find-rssi-spot", { rssis, target_spot_id }, (response) => { return response.data; }));
  }

  public async FindRssiPoint(rssis: any, target_point: PointFloor): Promise<any> {
    return await lastValueFrom(this.getData("find-rssi-point", { rssis, target_point }, (response) => { return response.data; }));
  }
}

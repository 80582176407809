import { mathutils } from '../../../common/mathutils';
import { FindResult } from '../../../common/plan/common/FindResult';
import { PathVector } from '../../../common/plan/common/PathVector';
import { Point2D } from '../../../common/plan/common/Point2D';
import { PlanComponent } from '../../../common/plan/plan.component';
import { DisplayBase } from './DisplayBase';


export class PerspectiveDisplay extends DisplayBase {


  constructor(result: FindResult, plan: PlanComponent, floors: any[], rooms: any[]) {
    super(result, plan, floors, rooms);
  }


  protected override async OnDraw(index: number) {
    let entry: PathVector = this.Result.Entries[index];
    let next: PathVector | null = index < this.Result.Entries.length ? this.Result.Entries[index + 1] : null;

    this.DrawWalls(entry);
    this.DrawArrows(entry, next);
  }

  private DrawWalls(entry: PathVector) {
    // draws the floor
    this.AddWall([[-5, -5], [-5, 405], [405, 405], [405, -5]], "LightSteelBlue");

    // draws the north walls
    if (entry.cardinals.North) {
      this.AddWall([[-5, -5], [-5, 125], [130, 125], [130, -5]]);
      this.AddWall([[130, -5], [130, 125], [180, -5]]);
      this.AddWall([[270, -5], [270, 125], [405, 125], [405, -5]]);
      this.AddWall([[270, -5], [270, 125], [220, -5]]);
    }
    else
      this.AddWall([[-5, -5], [-5, 125], [405, 125], [405, -5]]);

    // draws the west walls
    if (entry.cardinals.West)
      this.AddWall([[-5, 405], [100, 200], [100, -5], [-5, -5]]);
    else
      this.AddWall([[-5, 405], [130, 125], [130, -5], [-5, -5]]);

    // draws the east walls
    if (entry.cardinals.East)
      this.AddWall([[405, 405], [300, 200], [300, -5], [405, -5]]);
    else
      this.AddWall([[405, 405], [270, 125], [270, -5], [405, -5]]);

    if (entry.cardinals.IsNextLift)
      this.AddWall([[130, -5], [130, 150], [270, 150], [270, -5]], "gray");
    if (entry.cardinals.IsNextStair)
      this.AddWall([[130, -5], [130, 150], [270, 150], [270, -5]], "blue");

    if (entry.cardinals.IsLift)
      this.AddWall([[100, -5], [100, 200], [300, 200], [300, -5]], "gray");
    if (entry.cardinals.IsStair)
      this.AddWall([[100, -5], [100, 200], [300, 200], [300, -5]], "blue");

    this.plan.Redraw();
  }

  private AddWall(coordinates: number[][], color: string = "beige") {
    let pts: Point2D[] = Point2D.GetPolygon(coordinates);
    this.plan.AddRealPolygon("walls", pts, "darkblue", color);
  }

  private DrawArrows(entry: PathVector, next: PathVector | null) {
    // draws the previous arrow
    //if (!entry.cardinals.IsLift && !entry.cardinals.IsStair && !entry.cardinals.IsArrival)
    //  this.plan.Drawer.DrawArrowRelief(new Point2D(200, 380), 20, mathutils.Ang360 - entry.angle, "blue");

    // draws the current arrow and message
    this.plan.Drawer.DrawArrowRelief(new Point2D(200, 300), 60, 0, "blue");

    this.plan.Drawer.DrawText(new Point2D(200, 220), entry.label, "blue", true, 24);

    // draws the next arrow and message
    if (!next || entry.cardinals.IsSpecial)
      this.plan.Drawer.DrawPoint(new Point2D(200, 150), "blue", "yellow", 20);
    else
      this.plan.Drawer.DrawArrowRelief(new Point2D(200, 150), 20, next.angle, "blue");
  }
}

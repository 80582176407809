import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  public back_image = "../assets/img/back.png";
  public _links: any[];

  get links(): any[] {
    return this._links;
  }

  @Input('links') set links(values: any[]) {
    if (!values || values.length == 0)
      values = [{ label: '', path: './' }];
    // add home link for all the pages
    // values.splice(0, 0, { label: 'Home', path: '', ico: 'fa fa-home' });
    values.splice(0, 0, { label: 'Home', path: ''});
    // the last item is the current page
    if (values.length > 1)
      values[values.length - 1]["class"] = "disabled";
    // tranlate if necessary
    for (var value of values) {
      let text = "";
      if (value["label"])
        text = this.translationService.translate(value["label"])
      if (value["name"])
        text += (text ? " " : "") + value["name"];
      value["text"] = text;
    }
    this._links = values;
  }

  constructor(private translationService: TranslationService,
    private location: Location) {
  }

  ngOnInit() {
  }

  goBack(){
    if(!this.location.isCurrentPathEqualTo("/workflow/work-buildings")){
      this.location.back();
    }
  }
}
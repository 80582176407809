<div class="row col-md-12 card-container" style="margin:0px;">

  <div style="margin:4px 4px 0px 4px;">
    <app-form-button (click)="exportHistoric()" text="Download" icon="download" height="22"></app-form-button>
  </div>

  <table class="table compact scrollable" style="margin:4px 4px 8px 4px; width:auto;">
    <thead>
      <tr align="center">
        <th style="width:160px">{{ 'Date' | locale }}</th>
        <th style="width:80px">{{ 'Duration' | locale }} (h:m)</th>
        <th *ngFor="let column_name of column_names" style="width:140px">{{ column_labels[column_name] }}</th>
      </tr>
    </thead>
    <tbody style="max-height:200px">
      <tr *ngFor="let event of event_list">
        <td style="width:160px;">{{ event['date'] | localizedDate:'medium' }}</td>
        <td style="width:80px; text-align:right">{{event.duration }}</td>
        <td *ngFor="let column_name of column_names" style="width:140px;">{{ event[column_name] }}</td>
      </tr>
    </tbody>
  </table>

</div>
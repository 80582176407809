import { Injectable } from '@angular/core';
import { StorageFields } from '../common/enums';
import { logicutils } from '../common/logicutils';

@Injectable({ providedIn: 'root' })
export class StorageService {

  private remember_me: boolean;
  private storage: Storage;

  constructor(){
    // Initializing the storage in the constructor is mandatory in case a user who asked to be remembered reopens the app after closing the browser
    try {
      this.remember_me = JSON.parse(localStorage.getItem(StorageFields.RememberMe));
    } catch (err) {
      this.remember_me = false;
    }
    this.storage = this.remember_me ? localStorage : sessionStorage;
  }

  /**
   * Initializes the storage after login
   * @param {boolean} is_remember_me : option remember_me is selected
   * @param {any} data : data of the user to store in storage
  */
  public initialize(is_remember_me: boolean, data) {
    this.storage = is_remember_me ? localStorage : sessionStorage;

    this.setValue(StorageFields.RememberMe, is_remember_me);

    if(data.user !== null){
      this.setValue(StorageFields.CurrentToken, data.token);
      this.setValue(StorageFields.CurrentUserId, data.user._id);
      this.setValue(StorageFields.CurrentUsername, data.user.username);

      if(data.user.dashboard_view != undefined)
        this.setValue(StorageFields.DashboardView, JSON.stringify(data.user.dashboard_view));
      if(data.user.floor_data)
        this.setValue(StorageFields.UserFloorId, data.user.floor_data);
      if(data.user.affectation_department_data)
        this.setValue(StorageFields.UserAffectationDepartmentId, data.user.affectation_department_data);
      if(data.user.location_department_data)
        this.setValue(StorageFields.UserLocationDepartmentId, data.user.location_department_data);

      this.setValue(StorageFields.CurrentUserRights, JSON.stringify(data.user.rights));
      this.setValue(StorageFields.CurrentUserIsInstaller, data.user.is_installer);
    }
  }

  /**
   * Resets the storage on logout. Goes through every storage field defined in the enum.
   * this.storage needs to be set to one of the storage types, since it is used on the login page and results in an error if it is null or undefined
   */
  public reset() {
    let keys = logicutils.getEnumKeysString(StorageFields);
    for(let key of keys){
      this.removeValue(StorageFields[key]);
    }
    this.storage = sessionStorage;
  }

  /**
   * removes from storage an item
   * @param {string} key : key of the item to remove
   */
  public removeValue(key: string){
    this.storage.removeItem(key);
  }

  /**
   * returns the value of the key in storage
   * @param {string} key 
   * @returns {any}
   */
  public getValue(key: string): any {
    return this.storage.getItem(key);
  }

  /**
   * stores a value under a key in the storage
   * @param {string} key 
   * @param {any} value 
   */
  public setValue(key: string, value: any){
    this.storage.setItem(key,value);
  }

}

<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">

  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>

  <input type="number" [name]="name" [(ngModel)]="value"
         [disabled]="_disabled" [required]="required" [step]="step"
         [ngClass]="required_error ? 'invalid' : ''" style="text-align: right; width:120px;" />
</div>

<div class="msg err" *ngIf="form?.submitted && required_error">Le champ {{label}} est obligatoire.</div>


<div class="dialog-header">
  <app-page-title title="{{title}}" mode="popup_mode"></app-page-title>
</div>

<div class="dialog-body">

  <form [formGroup]="editForm">

    <input type="hidden" formControlName="_id">

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ '$Equipment$' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-select [field]="form.equipment_data" [list]="equipments" id="_id" text="name" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Maintenance date' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-date [field]="form.maintenance_date" no_time="true" [submitted]="submitted"></app-form-date>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Comment' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-input [field]="form.comment" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div *ngIf="!is_create" class="form-group">
      <div class="row row-center">
        <div class="col-md-3">
          <label>{{ 'Documents' | locale }} :</label>
          <button type="button" class="btn btn-small" (click)="fileSelect.click()"><i class="fa fa-plus" aria-hidden="true"></i></button>
          <input type="file" #fileSelect class="form-control browse" (change)="uploadFile($event)" style="display:none">
        </div>
        <div class="col-md-5">

          <table class="table compact debug" style="width:100%;">
            <tbody>
              <tr align="center">
                <th width="100px">{{'Name' | locale }}</th>
                <th width="60px">{{'Date' | locale }}</th>
                <th width="60px">{{'Size' | locale }}</th>
                <th width="80px">{{'Actions' | locale }}</th>
              </tr>
              <tr *ngFor="let info of files_info">
                <td>{{ info.filename }}</td>
                <td>{{ info.timestamp | appdate }}</td>
                <td style="text-align:right">{{ info.size | appsize: 'KB' }}</td>
                <td style="text-align: center">
                  <app-list-button kind="Ctm" icon="download" tooltip="Download the file" (click)="downloadFile(info._id)"></app-list-button>
                  <app-list-button kind="Del" (click)="delFile(info._id)"></app-list-button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </form>

</div>

<div class="dialog-footer">
  <button class="btn-rounded btn-add" (click)="onSubmit()">{{ 'Save' | locale }}</button>
  <button class="btn-rounded btn-close" (click)="close(false)">{{ 'Close' | locale }}</button>
</div>
import { Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-datetime-editor label="From" [datekind]="datekind" [(ngModel)]="date_from" width="100px" disabled></app-datetime-editor>
 */
@Component({
  selector: 'app-year-editor',
  templateUrl: 'year.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearEditor),
      multi: true
    }
  ]
})
export class YearEditor extends EditorBase<number>{

  protected years: number[] = [];

  constructor() {
    super();
    let current = new Date().getFullYear();
    for (var i = current; i >= 2010; i--)
      this.years.push(i);
  }
}

import { Point2D } from "./common/Point2D";
import { FullLine } from "./FullLine";
import { FullPoint } from "./FullPoint";
import { IFigure } from "./IFigure";
import { PlanDrawer } from "./PlanDrawer";
import { PlanLayout } from "./PlanLayout";


export abstract class FigureBase implements IFigure {

  public static ConstructionColor: string = "green";
  public static ConstructionBackColor: string = "rgba(240, 255, 240, 1)";
  public static ConstructionTransparentBackColor: string = "rgba(0, 255, 0, 0.4)";
  public static ConstructionVeryTransparentBackColor: string = "rgba(0, 255, 0, 0.1)";
  public static ErrorColor: string = "red";
  public static WarnColor: string = "orange";
  public static LineColor: string = "blue";
  public static FillColor: string = "lightsteelblue";

  protected layout: PlanLayout;
  protected drawer: PlanDrawer;

  public fore_color: string;
  public back_color: string;
  public size: number;
  public draw_points: boolean;

  public label: string | null;
  public label_color: string;

  protected constructor(layout: PlanLayout) {
    this.layout = layout;
    this.drawer = layout.Drawer;
  }

  protected abstract OnUpdate(): void;
  protected abstract OnDraw(construction: boolean): void;

  protected abstract OnGetFullPoints(): FullPoint[];
  protected abstract OnGetFullLines(): FullLine[];

  public Update() {
    this.OnUpdate();
  }

  public Draw(construction: boolean): void {
    this.OnDraw(construction);
  }

  public GetFullPoints(): FullPoint[] {
    return this.OnGetFullPoints();
  }

  public GetFullLines(): FullLine[] {
    return this.OnGetFullLines();
  }

  public GetZoomPoints(): Point2D[] {
    let full_points = this.GetFullPoints();
    let points: Point2D[] = [];
    for (let point of full_points)
      points.push(new Point2D(point.Zoom.X, point.Zoom.Y));
    return points;
  }

  public GetRealPoints(): Point2D[] {
    let full_points = this.GetFullPoints();
    let points: Point2D[] = [];
    for (let point of full_points)
      points.push(new Point2D(point.Real.X, point.Real.Y));
    return points;
  }
}

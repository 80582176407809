import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldType, FieldConfig, Validator } from '../../models/common';
//cols = "40" rows = "5" 

@Component({
  selector: "app-textarea",

  template: `
    <div class= "form-group" [formGroup]="group">
      <div class="row row-center">
        <div class="col-md-3">      
          <label for="field.name">{{field.label}} :</label>
        </div>
        <div class="col-md-5">
          <textarea class="form-control" rows = "5" [formControlName]="field.name"></textarea>
        </div>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
      </div>
    </div>
`,
  styles: []
})
export class TextAreaComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }
}
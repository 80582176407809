import { Component, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-select-editor label="Enregistrements" [records]="list" [empty]="''" [(ngModel)]="record_id" (Changed)="OnChanged()" width="100px" disabled></app-select-editor>
 */
@Component({
  selector: 'app-number-editor',
  templateUrl: 'number.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberEditor),
      multi: true
    }
  ]
})
export class NumberEditor extends EditorBase<number> {

  @Input()
  public step: string | null = null;

  constructor() {
    super();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class MaintenanceService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "maintenance";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  getMaintenances(equipment_id: string) {
    return this.getData("getMaintenances", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  getMaintenanceDates(equipment_id: string) {
    return this.getData("getMaintenanceDates", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  getMaintenancesCount(equipment_id: string) {
    return this.getData("getMaintenancesCount", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  getMaintenance(maintenance_id: string) {
    return this.getData("getMaintenance", { maintenance_id: maintenance_id }, (response) => { return response.data; });
  }

  /**
  * Adds a new record to the database
  * @param record the record to add
  */
  addMaintenance(record: any, subscribeMethod) {
    return this.updData("addMaintenance", { maintenance: record }, subscribeMethod);
  }

  /**
    * Updates the given record to the database
    * @param record the record to update
    */
  updMaintenance(record: any, subscribeMethod) {
    return this.updData("updMaintenance", { maintenance: record }, subscribeMethod);
  }

  /**
  * Deletes the record matching with the given id
  */
  delMaintenance(maintenance_id: string, equipment_id: string, subscribeMethod) {
    return this.updData("delMaintenance", { maintenance_id: maintenance_id, equipment_id: equipment_id }, subscribeMethod);
  }

  /*
* Deletes all maintenance history of an equipment
* */
  delMaintenanceHistories(equipment_id: string, subscribeMethod) {
    return this.updData("delMaintenanceHistories", { equipment_id: equipment_id }, subscribeMethod);
  }

  /**
   * Get the informations about the files uploaded fotr the given maintenance
   * @param maintenance_id the id of the related maintenance
   */
  getfilesInfo(maintenance_id: string) {
    return this.getData("getfilesInfo", { maintenance_id: maintenance_id }, (response) => { return response.data; });
  }

  /**
   * Gets the flie for download
   * @param flie_id the id of the file to get
   */
  getFile(flie_id: string) {
    return this.getData("getFile", { flie_id: flie_id }, (response) => { return response.data; });
  }

  /**
   * Upload a file to the maintenance dedicated collection
   * @param file_data the file to upload and related informations
   */
  uploadFile(file_data, subscribeMethod) {
    return this.updloadData("uploadFile", file_data, subscribeMethod);
  }

  /**
  * Deletes the record matching with the given id
  */
  delFile(file_id: string, subscribeMethod) {
    return this.updData("delFile", { file_id: file_id }, subscribeMethod);
  }
}
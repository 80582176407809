import { mathutils } from "../mathutils";
import { Point2D } from "./common/Point2D";
import { PlanLayout } from "./PlanLayout";


export class PlanDrawer {

  protected layout: PlanLayout;
  private context: CanvasRenderingContext2D;

  public constructor(layout: PlanLayout) {
    this.layout = layout;
    this.context = layout.Context;
  }

  public Fill(color: string) {
    let context = this.context;
    context.rect(0, 0, context.canvas.width, context.canvas.height);
    context.fillStyle = color;
    context.fill();
  }

  public DrawPoint(point: Point2D, fore_color: string, back_color: string = "yellow", size: number = 6) {
    let context = this.context;
    context.beginPath();
    context.arc(point.x, point.y, size, 0, 2 * Math.PI, false);
    context.strokeStyle = fore_color;
    context.fillStyle = back_color;
    context.lineWidth = 2;
    context.fill();
    context.stroke();
  }

  public DrawLine(point1: Point2D, point2: Point2D, fore_color: string, size: number = 3) {
    let context = this.context;
    context.beginPath();
    context.lineWidth = size;
    context.lineCap = 'round';
    context.strokeStyle = fore_color;
    context.moveTo(point1.x, point1.y);
    context.lineTo(point2.x, point2.y);
    context.stroke();
  }

  public DrawPolyline(points: Point2D[], fore_color: string, size: number = 3) {
    let context = this.context;
    context.beginPath();
    context.lineWidth = size;
    context.lineCap = 'round';
    context.strokeStyle = fore_color;
    context.moveTo(points[0].x, points[0].y);
    for (var i = 1; i < points.length; i++)
      context.lineTo(points[i].x, points[i].y);
    context.stroke();
  }

  public DrawPolygon(points: Point2D[], fore_color: string, back_color: string = "yellow", width: number = 3) {
    let context = this.context;
    context.beginPath();
    context.lineWidth = width;
    context.lineCap = 'round';
    context.strokeStyle = fore_color;
    context.fillStyle = back_color;
    context.moveTo(points[0].x, points[0].y);
    for (var i = 1; i < points.length; i++)
      context.lineTo(points[i].x, points[i].y);
    context.closePath();
    context.fill();
    context.stroke();
  }

  public DrawText(point: Point2D, text: string, fore_color: string = "blue", center: boolean = true, font_size: number = 12) {
    let context = this.context;
    context.font = "bold " + font_size + "px sans-serif";
    context.fillStyle = fore_color;
    context.textAlign = center ? "center" : "left";
    context.textBaseline = "middle";
    context.fillText(text, point.x, point.y);
  }

  public DrawArrowRelief(point: Point2D, size: number, angle: number, fore_color: string) {
    let points: Point2D[] = [];
    // shift of 90° as the trigonometric circle 0 angle is at right not at top
    angle -= mathutils.Ang90;
    points.push(Point2D.GetPoint(point, angle, size));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 * 1.2, size * 0.9));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 * 0.7, size * 0.625));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 - mathutils.Ang90, size * 1.1));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 + mathutils.Ang90, size * 1.1));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 * 0.7, size * 0.625));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 * 1.2, size * 0.9));

    this.DrawPolygon(points, fore_color);
  }

  public DrawArrow(point: Point2D, size: number, angle: number, fore_color: string) {
    let points: Point2D[] = [];
    // shift of 90° as the trigonometric circle 0 angle is at right not at top
    angle -= mathutils.Ang90;
    points.push(Point2D.GetPoint(point, angle, size));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 * 1.2, size * 0.9));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 * 0.7, size * 0.625));
    points.push(Point2D.GetPoint(point, angle - mathutils.Ang45 * 1.45 - mathutils.Ang90, size * 0.8));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 * 1.45 + mathutils.Ang90, size * 0.8));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 * 0.7, size * 0.625));
    points.push(Point2D.GetPoint(point, angle + mathutils.Ang45 * 1.2, size * 0.9));

    this.DrawPolygon(points, fore_color);
  }

}

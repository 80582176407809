import { Point } from '../../../models/types';
import { PlanItem } from './PlanItem';


export class PlanGroup {

  public point: Point;
  public items: PlanItem[] = [];

  public item_counts: {} = {};

  constructor(item: PlanItem) {

    if (item) {
      this.point = new Point(item.point.x, item.point.y);
      this.addItem(item);
    }
  }

  public addItem(item: PlanItem) {
    item.hidden = true;
    this.items.push(item);
    let key = item.state_key;
    if (key in this.item_counts) {
      this.item_counts[key]["count"] += 1;
    }
    else {
      this.item_counts[key] = {
        count: 1,
        color_filter: item.icon_color_filter
      };
    }
  }
}
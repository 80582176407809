

export interface IPath {

  _id: string;
  floor_data: string;

  paths: any[];
}

export interface ISpot {

  _id: string;
  floor_data: string;

  name: string;
  kind: SpotKind;
  x: number;
  y: number;
}

export interface ILink {

  _id: string;
  building_data: string;

  spot1: ISpot;
  spot1_id: string;

  spot2: ISpot;
  spot2_id: string;
}

export interface IRoom {

  _id: string;
  floor_data: string;

  name: string;
  type: number;
  walls?: any[];
}


export enum SpotKind {
  None = 0,
  Poi = 1,
  Entrance = 11,
  Stair = 51,
  Lift = 52
}

export enum SpotKindText {
  None = "",
  Poi = "POI",
  Entrance = "Entrée",
  Stair = "Escalier",
  Lift = "Ascenseur",
}

export enum RoomKind {
  None = 0,
  Main = 1,
  Room = 2
}

export enum RoomKindText {
  None = "",
  Main = "Principal",
  Room = "Pièce",
}


<div *ngIf="current_event" class="card-container" style="align-items: center; background-color:#2D6C9B; display: flex;" [ngStyle]="{'background-color': is_old ? '#9B2C2D' : '#2D6C9B' }">
  <div class="row" style="width: 100%; height: 100%; margin: 8px 0px;">
    <span class="historic-info">
      <i [ngClass]="(is_playing) ? 'fas fa-play' : 'fas fa-map-marker-alt'"
         style="color:white; font-size: 24px; margin: 4px 0px;" aria-hidden="true"></i>
    </span>
    <span class="historic-info">{{current_event.sensor_last_update | date:'dd/MM/yyyy HH:mm:ss' }}</span>
    <span class="historic-info">{{current_event.building_name }}</span>
    <span class="historic-info">{{current_event.floor_name }}</span>
    <span class="historic-info" *ngIf="current_event.department_id">{{ current_event.department_name }}</span>
    <span class="historic-info" *ngIf="current_event.room_id">{{ current_event.room_name }}</span>
  </div>
  <button *ngIf="is_location_view" (click)="exportPlan()" class="btn-rounded" style="margin: 8px;">
    {{ 'Download' | translate }}
  </button>

  <button *ngIf="is_admin" (click)="navigateAnalysis()" class="btn-rounded" style="margin: 8px;">
    {{ 'Analysis' | translate }}
  </button>
</div>
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { TemplateRef, EventEmitter, Injector } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { ActivatedRoute } from '@angular/router';
import { map, take } from "rxjs/operators";

import { DialogMessageComponent } from "./entry-components/dialog-message.component";
import { GroupInfoComponent } from './entry-components/group-info.component';
import { Point } from '../models/types';
import { ItemKinds } from '../models/common';
import { RedirectKind, StorageFields } from './enums';
import { ErrorLevel } from './enums';
import { AppConfig } from '../app.config';
import { StorageService } from '../services/storage.service';


export class navutils {

  /**
   * navigate to the search page
   * @param router the angular module which provide navigation and URL manipulation capabilities.
   * @param building_id the if of the building
   */
  public static NavigateToSearch(router: Router, building_id: any, storage_service: StorageService) {
    // resets the filters as we start a new search
    //localStorage.removeItem('search_page_filters');
    storage_service.removeValue(StorageFields.SearchPageFilters);
    router.navigate(['workflow/work-search/', building_id || 0]);
  }


  /**
  * Request the user to confirm the deletion of a record
  * @return true, if the user allow the deletion, otherwise false
  * */
  public static async confirmDelete(dialog: MatDialog) {
    return await navutils.openDialogConfirm(dialog, "Delete the record", "Do you want to delete this record ?", ErrorLevel.Warning);
  }

  /**
   * Opens a dialog to show an items group
   * @param dialog the dialog service managing the display and interactions.
   * @param title the title of the dialog
   * @param items the items to display
   * @param callback the method to call when an item is clicked
   * @param event_emiter in some contexts the callback use an event_emiter which is empty when the dialog is opened, so we must pass it before opening the dialog
   */
  public static async openDialogGroupInfo(dialog: MatDialog, title: string, items: any[], callback: (record: any) => void, event_emiter: EventEmitter<{}>, location: Point): Promise<void> {
    return await navutils.openDialogNonModal(dialog, GroupInfoComponent, "auto", "auto", { title: title, items: items, callback: callback, event_emiter: event_emiter }, () => { }, location);
  }

  /**
   * Opens a dialog box with a message and a title, with two buttons which the user has to select.
   * Used to ask confirmation to the user.
   * @param dialog Instance of MatDialog
   * @param title Title of popup window
   * @param message Message to display in popup window.
   * @param validate_word Word for validation button, default is "Yes"
   * @param cancel_word Word for cancel button, default is "No"
   * @returns returns true or false depending on choice of user
   */
  public static async openDialogConfirm(dialog: MatDialog, title: string, message: string, error_level: ErrorLevel = ErrorLevel.Info): Promise<boolean> {
    return await navutils.openDialogWithReturn(dialog, title, message, false, error_level);
  }

  /**
   * Opens a dialog with a message and a title with a single button to simply close the popup.
   * Used to display information to the user.
   * @param dialog Instance of MatDialog
   * @param title Title of the popup window
   * @param message Message to display in popup window
   * @param validate_word Custom word for the button
   * @returns true
   */
  public static async openDialogMessage(dialog: MatDialog, title: string, message: string, error_level: ErrorLevel = ErrorLevel.Info): Promise<void> {
    await navutils.openDialogWithReturn(dialog, title, message, true, error_level);
  }

  /**
   * Used to open a dialog that will return a boolean depending on the choice the user made, with a message and a title
   * @param dialog instance of MatDialog
   * @param title Title of popup window
   * @param message Message to display in popup window.
   * @param validate_word If is null or empty, will be replaced by default value "Oui".
   * @param cancel_word If is null, is replaced by default value "Non". If is "", button cancel will not be displayed
   * @returns
   */
  private static async openDialogWithReturn(dialog: MatDialog, title: string, message: string, single_button: boolean, error_level: ErrorLevel = ErrorLevel.Info): Promise<boolean> {
    let buttons = {
      validate: single_button ? "Ok" : "Yes",
      cancel: "No"
    };
    return await navutils.openDialogReturn(dialog, DialogMessageComponent, "40%", "auto", { title: title, message: message, buttons: buttons, single_button: single_button, error_level: error_level }).toPromise();
  }

  /**
   * Generic method to open a dialog
   * @param dialog the dialog service managing the display and interactions.
   * @param compoenent the component to display
   * @param width the width of the dialog
   * @param height the haight of the dialog
   * @param data the data to pass to the component such as id of the record and parmaters
   * @param closed_callback the callback to call when the dialog is closed allowed to refresh the list
   * @param modal true if the dialog must be modal otherwise false
   */
  public static openModalDialog<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any, closed_callback) {
    data["popup_mode"] = true;
    dialog.open(component, {
      width: width,
      height: height,
      data: data,
      disableClose: true
    }).afterClosed().subscribe(data => {
      closed_callback ? closed_callback(data) : ''
    });
  }

  /**
 * Generic method to open a dialog
 * @param dialog the dialog service managing the display and interactions.
 * @param compoenent the component to display
 * @param width the width of the dialog
 * @param height the haight of the dialog
 * @param data the data to pass to the component such as id of the record and parmaters
 * @param closed_callback the callback to call when the dialog is closed allowed to refresh the list
 */
  private static openDialogNonModal<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any, closed_callback, location: Point) {
    data["popup_mode"] = true;
    dialog.open(component, {
      position: { left: location.x + "px", top: location.y + "px", },
      width: width,
      height: height,
      data: data,
      disableClose: false
    }).afterClosed().subscribe(
      data => closed_callback ? closed_callback() : ''
    );
  }

  /**
   * Generic method to open a dialog that returns true or false depending on the interaction of the user
   * @param dialog the dialog service managing the display and interactions.
   * @param compoenent the component to display
   * @param width the width of the dialog
   * @param height the haight of the dialog
   * @param data the data to pass to the component such as id of the record and parmaters
   * @returns result of the choice of the user (true or false)
   */
  public static openDialogReturn<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any): any {
    data["popup_mode"] = true;
    return dialog.open(component, {
      width: width,
      height: height,
      data: data,
      disableClose: true
    }).afterClosed().pipe(take(1), map(
      data => { return data; }
    ));
  }

  /**
   * Navigate to the maintenance list
   * @param router { Router } an instance of router
   * @param equipment_kind_id { any } the id of the kind of equipement
   * @param maintenance_status { any } the maintenance status
   */
  public static NavigateToMaintenanceList(router: Router, equipment_kind_id: any, maintenance_status: any) {
    router.navigate(['administration/maintenances/list/', (equipment_kind_id || 0), (maintenance_status || 0)]);
  }

  /**
   * Gets the path to the maintenance list
   * @param equipment_kind_id { any } the id of the kind of equipement
   * @param maintenance_status { any } the maintenance status
   * @returns the path to the mainhtenance list
   */
  public static GetMaintenanceListPath(equipment_kind_id: any, maintenance_status: any) {
    return '/administration/maintenances/list/' + (equipment_kind_id || 0) + "/" + (maintenance_status || 0);
  }

  /**
   * Navigate to the dashboard
   * @param router { Router } an instance of router
   * @param building_id { any } the id of the building
   * @param equipment_family_id { any } the id of the family of equipement
   * @param department_id { any } the id of the departement
   * @param is_first_open { boolean } get if it is the first open of the page allowing to determined if filters should be applied
   */
  public static NavigateToDashboard(router: Router, building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    router.navigate(['dashboard/dashboard/', (building_id || 0), (equipment_family_id || 0), (department_id || 0), is_first_open ? 1 : 0]);
  }

  /**
   * Gets the path to the dashboard
   * @param building_id { any } the id of the building
   * @param equipment_family_id { any } the id of the family of equipement
   * @param department_id { any } the id of the departement
   * @param is_first_open { boolean } get if it is the first open of the page allowing to determined if filters should be applied
   * @returns
   */
  public static GetDashboardPath(building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    return '/dashboard/dashboard/' + (building_id || 0) + "/" + (equipment_family_id || 0) + "/" + (department_id || 0) + "/" + (is_first_open ? 1 : 0);
  }

  /**
   * Navigate to the report dashboard
   * @param router { Router } an instance of router
   * @param building_id { any } the id of the building
   * @param equipment_family_id { any } the id of the family of equipement
   * @param department_id { any } the id of the departement
   * @param is_first_open { boolean } get if it is the first open of the page allowing to determined if filters should be applied
   */
  public static NavigateToDashboardReport(router: Router, building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    router.navigate(['dashboard/dashboard-report/', (building_id || 0), (equipment_family_id || 0), (department_id || 0), is_first_open ? 1 : 0]);
  }

  /**
   * Gets the path to the report dashboard
   * @param building_id { any } the id of the building
   * @param equipment_family_id { any } the id of the family of equipement
   * @param department_id { any } the id of the departement
   * @param is_first_open { boolean } get if it is the first open of the page allowing to determined if filters should be applied
   * @returns the path to the report dashboard
   */
  public static GetDashboardReportPath(building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    return '/dashboard/dashboard-report/' + (building_id || 0) + "/" + (equipment_family_id || 0) + "/" + (department_id || 0) + "/" + (is_first_open ? 1 : 0);
  }

  /**
   * Navigate to the dashboard maintenance
   * @param router { Router } an instance of router
   * @param building_id { any } the id of the building
   * @param equipment_kind_id { any } the id of the kind of equipement
   */
  public static NavigateToDashboardMaintenance(router: Router, building_id: any = 0, equipment_kind_id: any = 0) {
    router.navigate(['dashboard/dashboard-maintenance/', (building_id || 0), (equipment_kind_id || 0)]);
  }

  /**
   * Gets the path to the dashboard maintenance
   * @param building_id { any } the id of the building
   * @param equipment_kind_id { any } the id of the kind of equipement
   * @returns the path to the dashboard maintenance
   */
  public static GetDashboardMaintenancePath(building_id: any = 0, equipment_kind_id: any = 0) {
    return '/dashboard/dashboard-maintenance/' + (building_id || 0) + '/' + (equipment_kind_id || 0);
  }

  /**
   * Navigate to the battery dashboard
   * @param router { Router } an instance of router
   * @param building_id { any } the id of the building
   */
  public static NavigateToDashboardBattery(router: Router, building_id: any = 0) {
    router.navigate(['/dashboard/dashboard-battery/', (building_id || 0)]);
  }

  /**
   * Gets the path to the battery dashboard
   * @param building_id { any } the id of the building
   * @returns the path to the battery dashboard
   */
  public static GetDashboardBatteryPath(building_id: any = 0) {
    return '/dashboard/dashboard-battery/' + (building_id || 0);
  }

  /**
   * Navigate to redirect
   * @param router { Router } an instance of router
   * @param redirect_kind { RedirectKind } an instance of redirect kind
   * @param item_id { any } the id of the item
   */
  public static NavigateToRedirect(router: Router, redirect_kind: RedirectKind, item_id: any) {
    router.navigate(['/workflow/work-redirect/', redirect_kind || RedirectKind.LocationFromEquipmentName, item_id || 0]);
  }

  /**
   * Navigates to the location view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'details-historic/:floor_id/:item_id/:item_kind', component: DetailsHistoricComponent },
   */
  public static NavigateToLocationView(router: Router, floor_id: any, item_id: any, item_kind: ItemKinds) {
    router.navigate(['/details/details-location/', floor_id || 0, item_id || 0, item_kind || ItemKinds.Sensor]);
  }

  /**
   * Navigates to the historic view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'details-historic/:floor_id/:item_id/:item_kind', component: DetailsLocationComponent },
   */
  public static NavigateToHistoricView(router: Router, floor_id: any, item_id: any, item_kind: ItemKinds) {
    router.navigate(['/details/details-historic/', floor_id || 0, item_id || 0, item_kind || ItemKinds.Sensor]);
  }

  /**
  * Navigates to the analysis view
  * @param router
  * @param floor_id
  * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
  * @param item_kind
  * { path: 'analysis/:floor_id/:item_id/:item_kind', component: AnalysisComponent },
  */
  public static NavigateToAnalysisView(router: Router, floor_id: any, item_id: any, item_kind: ItemKinds) {
    router.navigate(['/supervision/analysis/', floor_id || 0, item_id || 0, item_kind || ItemKinds.Sensor]);
  }

  /**
   * Navigates to the events list view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'events-list/:building_id/:floor_id/:item_kind', component: EventsListComponent },
   */
  public static NavigateToEventsList(router: Router, building_id: any, floor_id: any, department_id: any, equipment_department_id: any, equipment_family_id: any,
    equipment_kind_id: any, equipment_status: string, item_kind: ItemKinds, show_plan: boolean, is_first_open: boolean = true, out_site: boolean = false) {
    let default_item_kind = AppConfig.front_settings.views.default_item_kind || ItemKinds.Sensor;
    router.navigate(['/details/events-list/', building_id || 0, floor_id || 0, department_id || 0, equipment_department_id || 0, equipment_family_id || 0, equipment_kind_id || 0, equipment_status || 0, (item_kind == null) ? default_item_kind : item_kind, show_plan ? 1 : 0, is_first_open ? 1 : 0, out_site ? 1 : 0]);
  }

  /**
 * Gets the event list path for the breadcrumbs
 * @param building_id
 * @param floor_id
 * @param item_kind
 * @param show_plan
 */
  public static GetEventsListPath(building_id: any, floor_id: any, department_id: any, equipment_department_id: any, equipment_family_id: any,
    equipment_kind_id: any, equipment_status: string, item_kind: ItemKinds, show_plan: boolean, is_first_open: boolean = true, out_site: boolean = false) {
    let default_item_kind = AppConfig.front_settings.views.default_item_kind || ItemKinds.Sensor;
    return '/details/events-list/' + (building_id || 0) + "/" + (floor_id || 0) + "/" + (department_id || 0) + "/" + (equipment_department_id || 0) + "/" + (equipment_family_id || 0) + "/" + (equipment_kind_id || 0) + "/" + (equipment_status || 0) + "/" + ((item_kind == null) ? default_item_kind : item_kind) + "/" + (show_plan ? 1 : 0) + "/" + (is_first_open ? 1 : 0) + "/" + (out_site ? 1 : 0);
  }

  /**
   * Gets the string parameter of null if 0 is passed
   * @param route
   * @param param_name
   */
  public static getStringParam(route: ActivatedRoute, param_name: string): string {
    let param = route.snapshot.params[param_name];
    if (!param || param == "0")
      return null;
    return param;
  }

  /**
  * Gets the integer parameter which can also be used for an enum
  * @param route
  * @param param_name
  */
  public static getIntParam(route: ActivatedRoute, param_name: string): number {
    return parseInt(route.snapshot.params[param_name]);
  }

  /**
  * Gets the boolean parameter which can also be used for an enum
  * @param route
  * @param param_name
  */
  public static getBoolParam(route: ActivatedRoute, param_name: string): boolean {
    return !!parseInt(route.snapshot.params[param_name]);
  }
}
import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '../../services/translation.service';


@Pipe({
  name: 'yesno',
  pure: false
})
export class YesNoPipe implements PipeTransform {

  constructor(private translateService: TranslationService) {
  }

  transform(value: any): string {
    if (value == null || value == undefined)
      return "";
    return this.translateService.Common.getYesNo(value);
  }
}

<div style="z-index: 99; background-color: #D3DCE8">

  <div [ngStyle]="{ 'display':can_zoom && !no_image ? 'block' : 'none' }" style="background: transparent; pointer-events: none;">
    <div style="float: right;">
      <button (click)="toggleToolBar(!show_tool_bar)" class="option" style="margin: 0px; pointer-events:auto; ">
        <mat-icon>expand_{{show_tool_bar ? 'less' : 'more'}}"</mat-icon>
      </button>
    </div>
  </div>

  <div [ngStyle]="{ 'display':can_zoom && show_tool_bar && !no_image ? 'block' : 'none' }"
       style="padding: 4px; border: 2px solid #2D6C9B; border-top:none; margin-top:0px; height: 72px;">

    <div style="float: left;">
      <div>
        <button class="display" [class.selected]="plan?.draw_mode==0"><mat-icon>center_focus_strong</mat-icon></button>

        <button class="display" [class.selected]="plan?.draw_mode==11"><mat-icon>call_received</mat-icon></button>
        <button class="display" [class.selected]="plan?.draw_mode==12"><mat-icon>zoom_out_map</mat-icon></button>

        <button class="display" [class.selected]="plan?.draw_mode==21"><mat-icon>swap_horiz</mat-icon></button>
        <button class="display" [class.selected]="plan?.draw_mode==22"><mat-icon>timeline</mat-icon></button>
        <button class="display" [class.selected]="plan?.draw_mode==23"><mat-icon>pentagon</mat-icon></button>
        <button class="display" [class.selected]="plan?.draw_mode==24"><mat-icon>rectangle</mat-icon></button>
      </div>

      <div style="line-height:8px;">
        <mat-icon>border_clear</mat-icon> {{plan?.layout?.accuracy_draw}}
        <mat-icon>zoom_out</mat-icon> {{plan?.layout?.zoom_ratio}}
        <mat-icon>crop_free</mat-icon> {{plan?.current_point?.Real?.ToString()}}
      </div>
    </div>

    <div style="float:right;">

      <div style="float:left; margin-left:24px;">&nbsp;</div>
      <div style="float:left; margin-top:3px;">
        <div><button (click)="doZoom(true)" class="option" style="margin:0px;"><mat-icon>add</mat-icon></button></div>
        <div><button (click)="doZoom(false)" class="option" style="margin:0px;"><mat-icon>remove</mat-icon></button></div>
      </div>

      <div style="float:left; margin-left:20px;">&nbsp;</div>

      <div style="float: left; margin-top: 3px;">
        <div style="float:left">
          <div style="height:14px;"></div>
          <button (click)="doShift(3)" class="option" style="margin:0px;"><mat-icon>west</mat-icon></button>
        </div>
        <div style="float:left">
          <div><button (click)="doShift(0)" class="option" style="margin:0px;"><mat-icon>north</mat-icon></button></div>
          <div><button (click)="doShift(2)" class="option" style="margin:0px;"><mat-icon>south</mat-icon></button></div>
        </div>
        <div style="float:left">
          <div style="height:14px;"></div>
          <button (click)="doShift(1)" class="option" style="margin:0px;"><mat-icon>east</mat-icon></button>
        </div>
      </div>

      <div style="float:left; margin-left:20px;">&nbsp;</div>

      <div style="float:left; height:62px; padding:0px">
        <canvas #plan_thumb_canvas (wheel)="onWheelThumb($event)" (mousedown)="onMouseDownThumb($event)" (mouseup)="onMouseUpThumb($event)" style="border: solid 1px #2D6C9B; margin: 0px; background-color: #dddddd; height: 62px; "></canvas>
      </div>

      <div style="float:left; margin-left:20px;">&nbsp;</div>

    </div>
  </div>
</div>


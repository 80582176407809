import { Point2D } from "./common/Point2D";
import { FigureBase } from "./FigureBase";
import { FullLine } from "./FullLine";
import { PlanLayout } from "./PlanLayout";
import { PlanPoint, RealPoint, ZoomPoint } from "./PlanPoint";


export class FullPoint extends FigureBase {

  private real: RealPoint;
  private plan: PlanPoint;
  private zoom: ZoomPoint;

  private constructor(layout: PlanLayout) {
    super(layout);
  }

  public get Real(): RealPoint {
    if (!this.real)
      this.real = this.plan.GetRealPoint(this.layout);
    return this.real;
  }

  public get Plan(): PlanPoint {
    return this.plan;
  }

  public get Zoom(): ZoomPoint {
    if (!this.zoom)
      this.zoom = this.plan.GetZoomPoint(this.layout);
    return this.zoom;
  }

  public GetDistanceReal(position: FullPoint): number {
    return Math.hypot(position.Real.X - this.Real.X, position.Real.Y - this.Real.Y);
  }

  public GetPoint2D(): Point2D {
    return new Point2D(this.Real.X, this.Real.Y)
  }

  public IsEqual(other: FullPoint) {
    return this.GetPoint2D().IsEqual(other.GetPoint2D())
  }

  protected override OnGetFullPoints(): FullPoint[] {
    return [this];
  }

  protected override  OnGetFullLines(): FullLine[] {
    return [];
  }

  public SetLocation(location: FullPoint) {
    this.real = location.Real;
    this.plan = location.plan;
    this.zoom = location.Zoom;
  }

  protected override OnUpdate() {
    this.zoom = this.plan.GetZoomPoint(this.layout);
  }

  protected override OnDraw(construction: boolean): void {
    this.drawer.DrawPoint(this.GetZoomPoints()[0], construction ? FigureBase.ConstructionColor : this.fore_color, this.back_color, this.size);
    if (this.label)
      this.drawer.DrawText(this.GetZoomPoints()[0].Shift(0, this.size * 2), this.label);
  }

  public static GetFromReal(layout: PlanLayout, point: Point2D): FullPoint {
    let full: FullPoint = new FullPoint(layout);
    full.real = new RealPoint(point.x, point.y);
    full.plan = full.real.GetPlanPoint(layout);
    return full;
  }

  public static GetFromZoom(layout: PlanLayout, point: Point2D): FullPoint {
    let full: FullPoint = new FullPoint(layout);
    full.zoom = new ZoomPoint(point.x, point.y);
    full.plan = full.zoom.GetPlanPoint(layout);
    return full;
  }
}

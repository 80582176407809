
<form [formGroup]="group">

  <div formArrayName="selected_filters" style="width:100%">
    <table class="table compact" style="margin:0px; border:none;">
      <thead>
        <tr>
          <th style="border:none;">
            {{filter_name | locale}}
            <span></span>
            <app-list-button *ngIf="main_filterable_items" [enabled]="main_filterable_item" kind="Ctm" (click)="addItems()" icon="plus-circle" text="All" tooltip="Add all items" style="float:right;"></app-list-button>
            <div style="float:right;">
              <select *ngIf="main_filterable_items" class="form-control" (change)="onMainFilterChange($event)" style="height:20px;">
                <option *ngFor="let filterable_item of main_filterable_items" value={{filterable_item.id}}>{{filterable_item.name}}</option>
              </select>
            </div>
          </th>
          <th style="width: 28px; border: none;">
            <app-list-button kind="Ctm" (click)="clearItems()" icon="trash-alt" tooltip="Remove all items"></app-list-button>
          </th>
        </tr>
      </thead>
      <tr *ngFor="let item of selected_filters.controls; let i=index" formGroupName="{{i}}">
        <td style="border:none;">
          <select class="form-control" formControlName="item" (change)="onCountChanged()" style="height:20px;" [ngClass]="{ 'is-invalid': !!item.value.error }">
            <option *ngFor="let filterable_item of filterable_items" [ngClass]="{ 'is-invalid': filterable_item.error }" value={{filterable_item.id}}>{{filterable_item.name}}</option>
          </select>
        </td>
        <td style="border:none;">
          <app-list-button kind="Ctm" (click)="removeItem(i)" icon="trash" tooltip="Remove item"></app-list-button>
        </td>
      </tr>
      <tr>
        <td style="border:none; background:transparent;"></td>
        <td style="border: none;">
          <app-list-button kind="Ctm" (click)="addItem()" icon="plus" tooltip="Add item"></app-list-button>
        </td>
      </tr>
    </table>
  </div>

</form>
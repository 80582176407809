import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormsModule, FormGroup, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms";

import { FieldType, FieldConfig, Validator } from '../../models/common';


@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  template: `
  <form class="dynamic-form" [formGroup]="dynform" (submit)="onSubmit($event)">
    <ng-container *ngFor="let field of fields;" dynamicField [field]="field" [group]="dynform">
    </ng-container>
  </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldConfig[] = [];
  @Input() disabled: boolean = false;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  public dynform: FormGroup;

  get value() {
    var value = this.dynform.value;
    // converts the values to typed ones
    for (var key in value) {
      const field = this.fields.filter(x => x.name == key)[0];
      if (field != null) {
        if (field.type === "date")
          value[key] = new Date(value[key]);
        else if ((field.type === "input") && (field.inputType === "number"))
          value[key] = Number(value[key]);
        else if ((field.type === "select") && field.is_json)
          value[key] = { value: value[key], values: field.options.join(";") };
      }
    }
    return this.dynform.value;
  }
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.updateControls(this.fields);
  }

  public updateControls(fields: FieldConfig[]) {
    this.fields = fields;
    this.dynform = this.createControl();
  }

  public disable() {   
    this.dynform.disable();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dynform.valid)
      this.submit.emit(this.dynform.value);
    else
      this.validateAllFormFields(this.dynform);
  }

  resetControls() {
    this.dynform = this.fb.group({});
  }


  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      if (this.disabled)
        control.disable();

      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}

import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";
import { TranslationService } from "../../services/translation.service";
import { dateutils } from '../dateutils';
import { RedirectKind } from "../enums";
import { navutils } from "../navutils";


@Component({
  selector: "app-light-ribbon",
  templateUrl: './light-ribbon.component.html',
})
export class LightRibbonComponent extends BaseWorkComponent {

  @Output() onExportPlan = new EventEmitter();

  public is_admin: boolean;
  public rights: any;

  public is_playing: boolean;
  public is_location_view: boolean;
  public is_old: boolean;

  public current_event: any;


  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any, is_location_view: boolean) {
    this.is_location_view = is_location_view;
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setEvent(current_event: any) {
    if (current_event.sensor_last_update)
      this.is_old = dateutils.isOlderThan(new Date(current_event.sensor_last_update), 1800)
    this.current_event = current_event;
  }

  public setPlayInfo(is_playing: boolean) {
    this.is_playing = is_playing;
  }

  public exportPlan() {
    this.onExportPlan.emit();
  }

  public navigateAnalysis() {
    navutils.NavigateToRedirect(this.router, RedirectKind.AnalysisFromSensorCloudId, this.current_event.sensor_cloud_id);
  }
}
<div style="margin:0px;">
  <app-page-title title="Map"></app-page-title>

  <div class="container-fluid" style="padding:0px 16px;">
    <div class="row">

      <div class="col-md-2">
        <label>{{ 'Kind' | locale }} :</label>
        <select class="form-control" [(ngModel)]="record_kind" (change)="onChangeValues($event)">
          <option *ngFor="let kind of record_kinds" [ngValue]=kind.key>{{kind.name | locale}}</option>
        </select>
      </div>

      <div class="col-md-3">
        <label>{{ '$Equipment kind$' | locale }} :</label>
        <select class="form-control" [(ngModel)]="equipment_kind_id" (change)="onChangeValues($event)">
          <option *ngFor="let kind of equipment_kinds" [ngValue]=kind.id>{{kind.name}}</option>
        </select>
      </div>

    </div>
  </div>


  <div class="row container-fluid" style="padding:0px 16px;">

    <div class="col-md-2" style="margin-top:4px;">
      <table class="table compact">
        <tr *ngFor="let marker of item_markers">
          <td [class]="marker.selected ? 'selected-row' : ''" (click)="onMarkerClick($event, marker)">{{marker.name}}</td>
        </tr>
      </table>
    </div>

    <div class="col-md-10">
      <div class="card">
        <div class="card-body bg-light">
          <div *ngIf="is_api_loaded | async">
            <google-map
                        width="100%"
                        height="600px"
                        [center]="{lat: map_info.lat, lng: map_info.lon}"
                        [(zoom)]="map_info.zoom"
                        [options]="{minZoom: map_info.min_zoom, maxZoom: map_info.max_zoom, disableDefaultUI: true, disableDoubleClickZoom: false, scrollwheel: true, zoomControl: true}"
                        >
              <ng-container *ngFor="let marker of circles">
                <map-circle [center]="{lat: marker.lat, lng: marker.lon}"
                            [radius]="10000 * sqrt(marker.total)"
                            [options]="{draggable: false, fillColor: '#FF0000', editable: false, strokeWeight: 0}"
                            (circleClick)="onMarkerClick($event, marker)">
                          </map-circle>
                <map-circle [center]="{lat: marker.lat, lng: marker.lon}"
                            [radius]="10000 * sqrt(marker.used)"
                            [options]="{draggable: false, fillColor: '#FFFF00', editable: false, strokeWeight: 0}"
                            (circleClick)="onMarkerClick($event, marker)">
                          </map-circle>
                <map-circle [center]="{lat: marker.lat, lng: marker.lon}"
                            [radius]="10000 * sqrt(marker.available)"
                            [options]="{draggable: false, fillColor: '#00FF00', editable: false, strokeWeight: 0}"
                            (circleClick)="onMarkerClick($event, marker)">
                          </map-circle>
              </ng-container>


              
              <map-marker *ngFor="let marker of markers"
                [position]="{lat: marker.lat, lng: marker.lon}"
                [options]="{icon: marker.icon, visible: true}"
                (mapClick)="onMarkerClick($event, marker)"
                (mapRightClick)="onMarkerRightClick($event, marker)">
              </map-marker>
              
            </google-map>
            <!-- web component spécifique pour l'API google map -->
            <!-- <agm-map [(latitude)]="map_info.lat"
                     [(longitude)]="map_info.lon"
                     [zoomControl]="true"
                     [(zoom)]="map_info.zoom"
                     [minZoom]="map_info.min_zoom"
                     [maxZoom]="map_info.max_zoom"
                     [disableDefaultUI]="true"
                     [disableDoubleClickZoom]="false"
                     [scrollwheel]="true">

              <ng-container *ngFor="let marker of circles">
                <agm-circle [latitude]="marker.lat"
                            [longitude]="marker.lon"
                            [radius]="3000 * sqrt(marker.total)"
                            [fillColor]="'#FF0000'"
                            [circleDraggable]="false"
                            [editable]="false"
                            (circleClick)="onMarkerClick($event, marker)">
                </agm-circle>
                <agm-circle [latitude]="marker.lat"
                            [longitude]="marker.lon"
                            [radius]="3000 * sqrt(marker.available + marker.used)"
                            [fillColor]="'#FFFF00'"
                            [circleDraggable]="false"
                            [editable]="false"
                            (circleClick)="onMarkerClick($event, marker)">
                </agm-circle>
                <agm-circle [latitude]="marker.lat"
                            [longitude]="marker.lon"
                            [radius]="3000 * sqrt(marker.available)"
                            [fillColor]="'#00FF00'"
                            [circleDraggable]="false"
                            [editable]="false"
                            (circleClick)="onMarkerClick($event, marker)">
                </agm-circle>
              </ng-container>

              <agm-marker *ngFor="let marker of markers;"
                          (markerClick)="onMarkerClick($event, marker)"
                          (markerRightClick)="onMarkerRightClick($event, marker)"
                          [iconUrl]="marker.icon"
                          [visible]="true"
                          [latitude]="marker.lat"
                          [longitude]="marker.lon">
              </agm-marker>

            </agm-map> -->

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid" style="padding:0px 16px;">

    <div class="row col-md-12 nopadding" *ngIf="current_marker && equipment_kind_id">
      <div class="row col-md-12 nopadding">
        <div class="centered">
          <h3><i class="menu-icon fa {{marker_icon}}"></i>&nbsp;&nbsp;{{current_marker.name}}</h3>
          <div *ngIf="current_marker && current_marker.address">
            <h4>{{current_marker.address.address1}}</h4>
            <h4>{{current_marker.address.address2}}</h4>
            <h4 style="display:inline-block">{{current_marker.address.zip_code}}&nbsp;&nbsp;</h4>
            <h4 style="display:inline-block">{{current_marker.address.city}}</h4>
          </div>
        </div>
      </div>
      <div class="centered">
        <div class="row col-md-12 nopadding">

          <div *ngFor="let card of cards" class="dash-card-back">
            <div class="dash-card-left">
              <div class="dash-card-icon" [style.backgroundColor]=card.color>
                <i class="fa fa-{{card.icon}}" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dash-card-right">
              <div class="dash-card-title">{{card.title}}</div>
              <div class="dash-card-text">{{card.text | locale}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row col-md-12 nopadding" *ngIf="current_marker && !equipment_kind_id">
      <div class="row col-md-12 nopadding">
        <div class="centered">
          <h3><i class="menu-icon fa {{marker_icon}}"></i>&nbsp;&nbsp;{{current_marker.name}}</h3>
          <div *ngIf="current_marker && current_marker.address">
            <h4>{{current_marker.address.address1}}</h4>
            <h4>{{current_marker.address.address2}}</h4>
            <h4 style="display:inline-block">{{current_marker.address.zip_code}}&nbsp;&nbsp;</h4>
            <h4 style="display:inline-block">{{current_marker.address.city}}</h4>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
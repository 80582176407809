import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { DonutChartInfo } from '../../dashboard';
import { colorutils } from '../../../../common/colorutils';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { workutils } from '../../../../common/workutils';
import { DashboardService } from '../../../../services/dashboard.service';
import { logicutils } from "../../../../common/logicutils";


@Component({
  selector: "app-dashboard-distribution-status",
  templateUrl: './dashboard-distribution-status.component.html',
})
export class DashboardDistributionStatusComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_distribution_status";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardEquipments(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    // - Camembert avec le nombre d��quipement par statut(hors site, disponible et maintenance)
    let chart = new DonutChartInfo(this.translationService.translate('Distribution by status'));
    let statuses = workutils.getAllowedStatuses();
    for (let status of statuses) {
      let count = logicutils.getCount(data, e => e.status_behaviour == status["behaviour"]);
      chart.addSerie(status["label"], count, colorutils.getStatusColor(parseInt(status["behaviour"])), parseInt(status["behaviour"]), (id) => { this.onClick(id); });
    }
    return chart.getOptions();
  }
}
<div *ngIf="selects" class="row row-ribbon-top">

  <div class="component-ribbon">
    <div class="label-ribbon">{{'Building' |locale}}</div>
    <select id="ribbon_building_filter" [(ngModel)]="filters.building_id" class="filter-ribbon" (change)="onbuildingChanged()">
      <option *ngFor="let record of selects.buildings; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon">
    <div class="label-ribbon">{{'$Floor$' |locale}}</div>
    <select id="ribbon_floor_filter" [(ngModel)]="filters.floor_id" class="filter-ribbon" (ngModelChange)="onfloorChanged()">
      <option *ngFor="let record of selects.floors; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon">
    <div class="label-ribbon">{{'Department' |locale}}</div>
    <select id="ribbon_department_filter" [(ngModel)]="filters.department_id" class="filter-ribbon" (change)="onDepartmentChanged()">
      <option *ngFor="let record of selects.departments; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon">
    <div class="label-ribbon">{{'Assignment' |locale}}</div>
    <select id="ribbon_department_filter" [(ngModel)]="filters.equipment_department_id" class="filter-ribbon" (change)="onAssignmentChanged()">
      <option *ngFor="let record of selects.equipment_departments; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon" *ngIf="filters.item_kind != '0'">
    <div class="label-ribbon">{{'$Equipment family$' |locale}}</div>
    <select id="ribbon_equipment_family_filter" [(ngModel)]="filters.equipment_family_id" class="filter-ribbon" (change)="onfilterChanged()">
      <option *ngFor="let record of selects.equipment_families; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon" *ngIf="filters.item_kind != '0'">
    <div class="label-ribbon">{{'$Equipment kind$' |locale}}</div>
    <select id="ribbon_equipment_kind_filter" [(ngModel)]="filters.equipment_kind_id" class="filter-ribbon" (change)="onfilterChanged()">
      <option *ngFor="let record of selects.equipment_kinds; trackBy:identify" value={{record._id}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon" *ngIf="filters.item_kind != '0'">
    <div class="label-ribbon">{{'$Equipment$ status' |locale}}</div>
    <select id="ribbon_equipment_status_filter" [(ngModel)]="filters.equipment_status" class="filter-ribbon" (change)="onfilterChanged()">
      <option *ngFor="let record of selects.equipment_status; trackBy:identify" value={{record.key}}>{{record.name}}</option>
    </select>
  </div>

  <div class="component-ribbon" *ngIf="filters.item_kind != '0'">
    <div class="label-ribbon">{{'$Equipment$' |locale}}</div>
    <input id="ribbon_equipment_name_filter" [(ngModel)]="filters.equipment_name" type="text" class="input-ribbon" placeholder="{{ '$Equipment$' | locale }}" (keyup)="onfilterChanged()">
  </div>

  <div class="component-ribbon" *ngIf="filters.item_kind != '2'">
    <div class="label-ribbon">{{'Sensor' |locale}}</div>
    <input id="ribbon_sensor_name_filter" [(ngModel)]="filters.sensor_name" type="text" class="input-ribbon" placeholder="{{ 'Sensor' | locale }}" (keyup)="onfilterChanged()">
  </div>

  <div *ngIf="rights && rights['right_equipments']" class="component-ribbon">
    <div class="label-ribbon">{{'Out of site' |locale}}</div>
    <input id="ribbon_out_site_filter" [(ngModel)]="filters.out_site" type="checkbox" (change)="onfilterChanged()">
  </div>

  <div style="float: right;" *ngIf="filters.floor_id && rights['right_thermic_map'] && show_thermic_map">
    <button id="thermic_map_button" (click)="onThermicMapShown()" class="btn-rounded" style="margin: 6px 10px;">{{ 'Thermic map' | locale }}</button>
  </div>

</div>

<div *ngIf="selects" class="row col-md-12 row-ribbon-bottom" style="background: none; display: flex; justify-content: flex-start;">
  <select id="item_kind_filter" class="filter-view-ribbon" [(ngModel)]="filters.item_kind" (change)="onItemKindChanged()">
    <option *ngFor="let kind of item_kinds; trackBy:identify" [ngValue]=kind.key>{{kind.name | locale}}</option>
  </select>

  <div *ngIf="assignment_floors.length" style="width: 83%; padding: 5px">
    <div *ngFor="let floor of assignment_floors" style="display: inline;">
      <button class="btn btn-primary" id="floor._id" (click)="onFloorClicked(floor._id)">{{ floor.name }}</button>
    </div>
  </div>
</div>
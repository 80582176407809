import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { DateTimeEditor } from "./editors/datetime.editor";
import { ImageEditor } from "./editors/image.editor";
import { MonthEditor } from "./editors/month.editor";
import { NumberEditor } from "./editors/number.editor";
import { SelectEditor } from "./editors/select.editor";
import { TextEditor } from "./editors/text.editor";
import { TextAreaEditor } from "./editors/textarea.editor";
import { ToggleEditor } from "./editors/toggle.editor";
import { YearEditor } from "./editors/year.editor";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
  ],
  declarations: [
    /* SafePipe,*/

    DateTimeEditor,
    SelectEditor,
    ToggleEditor,
    TextEditor,
    NumberEditor,
    MonthEditor,
    YearEditor,
    ImageEditor,
    TextAreaEditor
  ],
  exports: [
    //SafePipe,

    DateTimeEditor,
    SelectEditor,
    ToggleEditor,
    TextEditor,
    NumberEditor,
    MonthEditor,
    YearEditor,
    ImageEditor,
    TextAreaEditor
  ]
})
export class EditorsModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';
import ObjectID from 'bson-objectid';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class NavigationService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "navigation";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * To find all user navigations in database
   */
  getNavigations() {
    return this.getData("getNavigations", {}, (response) => { return response.data; });
  }

  getNavigationsForUser(user_id: string) {
    return this.getData("getNavigationsForUser", { user_id: user_id }, (response) => { return response.data; });
  }

  getNavigationsForDate(navigation_date: Date) {
    return this.getData("getNavigationsForDate", { navigation_date: navigation_date }, (response) => { return response.data; });
  }

  getNavigationsForDateRange(start_date: Date, end_date: Date) {
    return this.getData("getNavigationsForDateRange", { start_date: start_date, end_date: end_date }, (response) => { return response.data; });
  }

  /**
   * Request a specific user navigation by id from API
   * @param user_navigation_id id of the user navigation
   */
  getNavigation(user_navigation_id: string) {
    return this.getData("getNavigation", { user_navigation_id: user_navigation_id }, (response) => { return response.data; });
  }

  getNavigationForUserDate(user_id: string, navigation_date: Date) {
    return this.getData("getNavigationForUserDate", { user_id: user_id, navigation_date: navigation_date }, (response) => { return response.data; });
  }

  getUserActionCount(user_id: string) {
    return this.getData("getUserActionCount", { user_id: user_id }, (response) => { return response.data; });
  }

  public async addOrUpdNavigation(user_id: string, url: string) {
    if (!user_id)
      return;
    url = url.split("/").join("_");
    let now = new Date();
    let navigation = await this.getNavigationForUserDate(user_id, now).toPromise();
    if (navigation) {
      if (!("navigations" in navigation))
        navigation.navigations = {};
      if (url in navigation.navigations)
        navigation.navigations[url]++;
      else
        navigation.navigations[url] = 1;
      this.updNavigation(navigation, null);
    } else {
      navigation = {
        user_data: new ObjectID(user_id),
        navigation_date: now,
        navigations: {}
      }
      navigation.navigations[url] = 1;
      this.addNavigation(navigation, null);
    }
  }

  /**
   * Adds a new navigation to the database
   * @param navigation
   */
  addNavigation(navigation: any, subscribeMethod) {
    return this.updData("addNavigation", { navigation: navigation }, subscribeMethod);
  }

  /**
    * Updates the given navigation to the database
    * @param navigation
    */
  updNavigation(navigation: any, subscribeMethod) {
    return this.updData("updNavigation", { navigation: navigation }, subscribeMethod);
  }

  /**
  * Deletes the navigation matching with the given id
  * @param navigation_id
  */
  delNavigation(navigation_id: string, subscribeMethod) {
    return this.updData("delNavigation", { navigation_id: navigation_id }, subscribeMethod);
  }

  /**
  * Deletes all user actions of an user
  * @param {string} user_id the id of the user to delete actions
  */
  delUserActions(user_id: string, subscribeMethod) {
    return this.updData("delUserActions", { user_id: user_id }, subscribeMethod);
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardService } from '../../../services/dashboard.service';
import { colorutils } from "../../../common/colorutils";
import { logicutils } from '../../../common/logicutils';
import { DashCardLiteInfo } from '../dashboard';
import { TranslateService } from '@ngx-translate/core';
import { StatusBehaviours } from '../../../models/common';
import { workutils } from '../../../common/workutils';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

enum RecorKind {
  District = 0,
  Building = 1,
}

export enum RecorKindText {
  District = "Districts",
  Building = "Buildings",
}


@Component({
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private api_loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is_api_loaded = this.api_loaded.asObservable();

  public building_icon = '/assets/img/map/pred.png';
  public district_icon = '/assets/img/map/pblue.png';
  public selected_icon = '/assets/img/map/pyellow.png';


  map_info: any = {
    lat: 46.8,
    lon: 2,
    zoom: 6,
    min_zoom: 3,
    max_zoom: 17
  }

  district_markers: any[] = [];
  building_markers: any[] = [];
  item_markers: any[] = [];
  markers: any[] = [];
  circles: any[] = [];
  items: any[] = [];
  current_marker: any;
  cards: DashCardLiteInfo[] = [];

  record_kind: RecorKind = RecorKind.Building;
  record_kinds: any[];

  equipment_kind_id: any = null;
  equipment_kinds: any[];
  marker_icon: string;

  constructor(//public mapsApiLoader: MapsAPILoader, 
    private router: Router,
    private http_client: HttpClient,
    private translate: TranslateService,
    private dashboardService: DashboardService,
  ) {
    http_client.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyD346t0Hf3FH_-g9hXB_1z3y3Si2XqcFEU', 'callback')
      .subscribe(
        () => {
          this.api_loaded.next(true);
        },
        (error) => {
          console.error("failed to load google maps api : ", error);
        }
      )
  }

  ngOnInit() {
    this.dashboardService.getMapInitialData().subscribe(data => {
      this.record_kinds = logicutils.getEnumNames(RecorKind, RecorKindText);
      this.equipment_kinds = data.equipment_kinds;
      this.equipment_kinds.splice(0, 0, { id: null, name: this.translate.instant('None') });
      this.markers = data.markers;
      this.district_markers = this.markers.filter(m => m.kind == 0);
      this.building_markers = this.markers.filter(m => m.kind == 1);
      for (let marker of this.markers) {
        if (marker["kind"] == RecorKind.District)
          marker["origin_icon"] = marker["icon"] = this.district_icon;
        else if (marker["kind"] == RecorKind.Building)
          marker["origin_icon"] = marker["icon"] = this.building_icon;
      }
      this.onChangeValues(null);
    });
  }

  protected sqrt(value) {
    if (value == 0)
      return 0;
    return Math.sqrt(value);
  }


  public onChangeValues(event) {

    if (this.equipment_kind_id == null) {
      this.markers = this.record_kind == 0 ? this.district_markers : this.building_markers;
      this.item_markers = this.markers;
      this.circles = [];
    } else
      this.dashboardService.getEquipmentsInfo(this.record_kind, this.equipment_kind_id).subscribe((data) => {
        let markers = data;
        for (var marker of markers) {
          marker["available"] = marker.behaviours[StatusBehaviours.available + ""];
          marker["used"] = marker.behaviours[StatusBehaviours.used + ""];
        }
        this.circles = markers;
        this.item_markers = this.circles;
        this.markers = [];
      });
    this.current_marker = null;
    this.marker_icon = this.record_kind == 0 ? "fa-map" : "fa-building";
  }

  onMarkerClick($event, marker: any) {
    if (marker.kind == RecorKind.Building) {
      this.navigateTo(marker.id);
      return;
    }
    this.current_marker = marker;
    let behaviours = marker.behaviours;
    for (let marker of this.item_markers) {
      let selected = marker == this.current_marker;
      marker["selected"] = selected;
      marker["icon"] = selected ? this.selected_icon : marker["origin_icon"];
    }
    this.cards = [];
    if (this.equipment_kind_id != null) {
      for (let status of workutils.getAllowedStatuses())
        AddCard(this, parseInt(status["behaviour"]));
      this.cards.push(new DashCardLiteInfo(this.current_marker.total, "Total equipments", null, colorutils.Blue));
    }

    function AddCard(parent: any, behaviour: StatusBehaviours) {
      parent.cards.push(new DashCardLiteInfo(behaviours[behaviour], workutils.getStatusLabelFromStringNumber(behaviour + ""), null, colorutils.getStatusColor(behaviour)));
    }
  }

  onMarkerRightClick($event, m: any) {
  }

  navigateTo(id: string) {
    this.router.navigate(['/workflow/work-building/' + id]);
  }
}
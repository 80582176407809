import { Component, OnInit, Input, Inject, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: "app-group-info",
  templateUrl: './group-info.component.html',
  styles: []
})
export class GroupInfoComponent implements OnInit {

  /**
 * Use din a dialog to display multiple items informationsq
 * @param dialog the reference to a dialog opened via the MatDialog service.
 * @param title the title of the dialog
 * @param items the items to display
 * @param callback the method to call when an item is clicked
 * @param event_emiter in some contexts the callback use an event_emiter which is empty when the dialog is opened, so we must pass it before opening the dialog
 */
  constructor(public dialogRef: MatDialogRef<GroupInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string; items: any[]; callback: (record: any) => void; event_emiter: EventEmitter<{}> }) {
  }

  ngOnInit() {
  }

  protected onClickItem(record) {
    if (this.data.callback)
      this.data.callback(record);
    else if (this.data.event_emiter)
      this.data.event_emiter.emit(record);
    this.dialogRef.close(true);
  }
}
import { Component, OnInit, Input, EventEmitter, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from "@angular/router";


@Component({
  selector: "app-date-time",
  templateUrl: './date-time.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true
    }
  ]
})
export class DateTimeComponent implements OnInit, ControlValueAccessor {

  @Output() DateChanged = new EventEmitter();

  private _enabled: boolean = true;
  public _has_time: boolean = true;
  private onChange: Function;
  private onTouched: Function;
  protected disabled: boolean;

  @Input('date')
  public _date: Date = null;

  get value() {
    return this._date;
  }
  set value(val) {
    this._date = val;
    this.onChange(val);
    this.onTouched();
  }

  get enabled(): boolean {
    return this._enabled;
  }
  @Input() set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * a boolean property ="false" would be interpreted as true as "false" is evaluated true
   * so we use a property which by default is false, so if we set "true" it will be evaluated true
   * */
  get no_time(): boolean {
    return !this._has_time;
  }
  @Input() set no_time(value: boolean) {
    this._has_time = !value;
  }

  constructor(private router: Router) {
    this.onChange = (_: any) => { };
    this.onTouched = () => { };
  }

  ngOnInit() {
  }

  writeValue(date: Date): void {
    this._date = date ? new Date(date) : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.enabled = !disabled;
  }

  protected onDateChanged(date: string): void {
    if (!date)
      return null;
    let new_date = new Date(date);
    if (this._date)
      new_date.setHours(this._date.getHours(), this._date.getMinutes());
    this._date = new_date;
    this.onChange(this._date);
    this.DateChanged.emit(this._date);
  }

  protected onTimeChanged(time: string): void {
    if (!time)
      return null;
    let new_date = new Date(this._date);
    let times = time.split(":");
    new_date.setHours(Number(times[0]), Number(times[1]));
    this._date = new_date;

    this.onChange(this._date);
    this.DateChanged.emit(this._date);
  }
}

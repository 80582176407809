
<div class="row bread-crumb">
  <div class="btn-group btn-breadcrumb">
    <div class="arrow-back">
      <img alt="" id="back_image" src="../assets/img/back.png" (click)="goBack()">
    </div>
    <a *ngFor="let link of _links" [routerLink]="link.path" routerLinkActive="router-link-active" href=""
       class="link-breadcrumb btn btn-default {{ link.class }}">
      <!-- <i [ngClass]="link.ico"></i> -->{{ link.text }}
    </a>
  </div>
</div>

<!-- to avoid content of the page come on this -->
<div style="height:40px; margin: 0px;"></div>
<!-- <div style=" height:40px; margin: 0px;"></div> -->

<div class="dashboard" style="background-color:#2B526F; margin:0px;">

  <app-breadcrumb [links]="links"></app-breadcrumb>
  <app-page-title title="{{title}}" [initialized]="initialized" style="background-color:#2B526F"></app-page-title>

  <div class="msg-error">{{error}}</div>

  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">
    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <span class="current_date" style="color:white">{{ 'Update time' | locale }} : {{current_date | localizedDate:'medium'}}</span>
    </div>
  </div>

  <div *ngIf="show_widget_counters" class="row col-md-12 dashboard-container">
    <div class="row col-md-12 nopadding">
      <div class="centered" style="width: 1024px;">
        <app-dashboard-consumer-counters id="widget_counters" #widget_counters (Select)="onSelectStatus($event)"></app-dashboard-consumer-counters>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormArray } from "@angular/forms";
import { FieldType, FieldConfig, Validator } from '../../models/common';


@Component({
  selector: "app-array",
  template: `
    <div class= "form-group" [formGroup]="group">
      <div class="row row-center">
        <div class="col-md-3">      
          <label for="field.name">{{field.label}} :</label>
        </div>
        <div class="col-md-5">
 
<div [formArrayName]="array">
<table class="table"> 
  <tr *ngFor="let item of field.value; let i=index" formGroupName="{{i}}">
     <td><input class="form-control" [formControlName]="name" [type]="text"></td>     
     <td><input class="form-control" [formControlName]="value" [type]="text"></td>     
  </tr>
</table>
</div>

        <!--<select class="form-control" [formControlName]="field.name">
          <option *ngFor="let item of field.value" [value]="item">{{item}}</option>
        </select>-->
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
      </div>
    </div>
`,

  styles: []
})
export class ArrayComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  array: FormArray;

  constructor() {
  }

  ngOnInit() {   
  }
}

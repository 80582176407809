import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ObjectID from 'bson-objectid';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { BaseDashboardComponent } from '../base.dashboard.component';

import { DashboardCountersComponent } from './Components/dashboard-counters.component';
import { DashboardUsageRateComponent } from './Components/dashboard-usage-rate.component';
import { DashboardAvailabilityComponent } from './Components/dashboard-availability.component';
import { DashboardAvailabilityStatusComponent } from './Components/dashboard-availability-status.component';
import { DashboardDistributionKindComponent } from './Components/dashboard-distribution-kind.component';
import { DashboardDistributionDepartmentComponent } from './Components/dashboard-distribution-department.component';
import { DashboardDistributionStatusComponent } from './Components/dashboard-distribution-status.component';

import { DashboardInputOutputMonitoringComponent } from './Components/dashboard-input-output-monitoring.component';
import { DashboardParkAgeComponent } from './Components/dashboard-park-age.component';

import { CommonService } from '../../../services/common.service';
import { TranslationService } from '../../../services/translation.service';

import { AppConfig } from '../../../app.config';
import { DashboardCache } from '../DashboardCache';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { navutils } from '../../../common/navutils';
import { logicutils } from '../../../common/logicutils';
import { StorageService } from '../../../services/storage.service';
import { StorageFields } from '../../../common/enums';
import { dateutils } from '../../../common/dateutils';


@Component({
  selector: 'app-dashboard-report',
  templateUrl: './dashboard-report.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardReportComponent extends BaseDashboardComponent implements OnDestroy {

  @ViewChild(DashboardCountersComponent) widget_counters: DashboardCountersComponent;
  @ViewChild(DashboardUsageRateComponent) widget_usage_rate: DashboardUsageRateComponent;
  @ViewChild(DashboardAvailabilityComponent) widget_availability: DashboardAvailabilityComponent;
  @ViewChild(DashboardAvailabilityStatusComponent) widget_availability_status: DashboardAvailabilityStatusComponent;
  @ViewChild(DashboardDistributionKindComponent) widget_distribution_kind: DashboardDistributionKindComponent;
  @ViewChild(DashboardDistributionDepartmentComponent) widget_distribution_department: DashboardDistributionDepartmentComponent;
  @ViewChild(DashboardDistributionStatusComponent) widget_distribution_status: DashboardDistributionStatusComponent;

  @ViewChild(DashboardInputOutputMonitoringComponent) widget_input_output_monitoring: DashboardInputOutputMonitoringComponent;
  @ViewChild(DashboardParkAgeComponent) widget_park_age: DashboardParkAgeComponent;


  public initialized: boolean = false;
  public links: any[];
  public title: string;
  public report_title: string;
  public sub_titles: any[] = [];
  public error: string = "";
  public shown: boolean = false;
  public pdf_loaded: any = false;
  private start_time: number;

  public equipment_family_id: any;
  public department_id: any;
  public department_location_id: any;
  private is_first_open: boolean = false;
  public end_date: Date = new Date();
  public start_date: Date = dateutils.addDays(this.end_date, -1);

  public current_date = new Date(Date.now());

  public equipment_families: any[];
  public departments: any[];

  public building_view: boolean = true;

  private widget_subscriptions: any = {};

  private loaded_states: any = {};


  constructor(
    protected router: Router,
    private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    protected translation_service: TranslationService,
    protected authentication_service: AuthenticationService,
    private storage_service: StorageService,
  ) {
    super(router, translation_service, authentication_service);

    let main = document.getElementById("main");
    /*  main.style.backgroundColor = "#2B526F";*/
    main.style.height = "100%";
    main.style.padding = "0px";
    // this is needed to ensure the view is refreshed when change view mode
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's
   * data-bound properties for the first time, and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  async onInitialize() {

    this.building_id = navutils.getStringParam(this.route, 'building_id') || 0;
    this.equipment_family_id = navutils.getStringParam(this.route, 'equipment_family_id') || 0;
    this.department_id = navutils.getStringParam(this.route, 'department_id') || 0;
    this.department_location_id = navutils.getStringParam(this.route, 'department_location_id') || 0;
    this.is_first_open = navutils.getBoolParam(this.route, "is_first_open");

    let user_affectation_department_id = this.storage_service.getValue(StorageFields.UserAffectationDepartmentId);//localStorage.getItem('user_affectation_department_id');
    if (user_affectation_department_id && !this.department_id && this.is_first_open)
      this.department_id = user_affectation_department_id;

    this.building_view = !this.equipment_family_id;
    this.is_first_open = false;

    let collections_names = ["buildings", "departments", "equipment_families"];
    let first = await this.translation_service.translateAsync("Global view");
    let all = await this.translation_service.translateAsync("All");
    await this.commonService.fillLookups(collections_names, this.building_id);
    this.equipment_families = this.commonService.getSelectLookup("equipment_families", "name", first);
    this.departments = this.commonService.getSelectLookup("departments", "name", all);

    let building_name = this.setTitle();
    this.links = [
      { name: building_name, path: '/workflow/work-building/' + this.building_id },
      { label: "Report", path: './' }
    ]
  }

  private setTitle() {
    let building_name = this.commonService.getLookupName("buildings", new ObjectID(this.building_id)) + " ";
    this.title = this.building_id == 0 ? (AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site") : building_name;
    this.report_title = this.translation_service.translate("Usage report of") + " " + this.title;
    let all = this.translation_service.translate("All");
    let equipment_family_name = this.equipment_family_id ? this.commonService.getLookupName("equipment_families", new ObjectID(this.equipment_family_id)) + " " : "";
    let department_name = this.department_id ? this.commonService.getLookupName("departments", new ObjectID(this.department_id)) : "";
    let department_location_name = this.department_id ? this.commonService.getLookupName("departments", new ObjectID(this.department_location_id)) : "";
    this.sub_titles = [];
    this.addSubTitle("Update time", this.current_date.toLocaleDateString() + " - " + this.current_date.toLocaleTimeString());
    this.addSubTitle("Period", this.start_date.toLocaleDateString() + " - " + this.end_date.toLocaleDateString());
    this.addSubTitle("$Equipment family$", equipment_family_name ? equipment_family_name : all);
    this.addSubTitle("Assignment", department_name ? department_name : all);
    this.addSubTitle("Location", department_location_name ? department_location_name : all);
    return this.title;
  }

  private addSubTitle(label: string, title: string) {
    this.sub_titles.push({ label: this.translation_service.translate(label) + " : ", title: title });
  }

  protected async onGetData() {
    // await this.fillDashboardData(false);
  }

  async ngOnDestroy() {
    this.unsubWidgets();
  }

  private unsubWidgets() {
    for (let key of Object.keys(this.widget_subscriptions)) {
      this.widget_subscriptions[key].unsubscribe();
    }
  }

  /**
   * Fills the data to use for the widgets in the dashboard
   * */
  private async fillDashboardData(set_title: boolean = true) {
    this.building_view = !this.equipment_family_id;
    this.start_time = Date.now();
    this.initialized = false;
    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError : Expression has changed after it was checked.
    this.changeDetectorRef.detectChanges();
    if (set_title)
      this.setTitle();
    else
      this.error = "";
    try {
      let args = {
        building_id: this.building_id,
        equipment_family_id: this.equipment_family_id,
        department_id: this.department_id,
        department_location_id: this.department_location_id,
        start_date: this.start_date,
        end_date: this.end_date,
      }
      let cache: DashboardCache = new DashboardCache();
      cache.addCache("1", this.widget_counters, true);
      cache.addCache("2", this.widget_availability, this.building_view);
      cache.addCache("3", this.widget_distribution_kind, this.building_view);
      cache.addCache("4", this.widget_distribution_department, true);
      cache.addCache("5", this.widget_distribution_status, !this.building_view);
      cache.addCache("6", this.widget_usage_rate, true);
      cache.addCache("7", this.widget_availability_status, !this.building_view);
      // those are temporary disabled
      cache.addCache("8", this.widget_park_age, false);
      cache.addCache("9", this.widget_input_output_monitoring, false && !this.building_view);
      cache.loadData("1", args);
      logicutils.logDuration("Dashboard", this.start_time);

      //gets the widgets and subscribes to their loaded private value
      //to wait for all widgets to be loaded before displaying the html
      let widgets = cache.getWidgets();
      for (let key of Object.keys(widgets)) {
        this.widget_subscriptions[key] = new Subscription();
        this.loaded_states[key] = false;
        if (widgets[key].widget && widgets[key].allowed)
          this.widget_subscriptions[key] = widgets[key].widget.loaded_subject.subscribe(value => {
            this.loaded_states[key] = value;

            this.pdf_loaded = this.getAllLoaded();
          });
        else {
          this.loaded_states[key] = true;
          this.pdf_loaded = this.getAllLoaded();
        }
      }
    } catch (e) {
      console.error(e)
      if (e.status == 501 || e.status == 504)
        this.error = this.translation_service.translate("Too much data");
      else
        this.error = "Error : " + e.message;
    } finally {
      this.initialized = true;
    }
  }

  public async onChangeEquipmentFamily(event) {
    let equipment_family_id = event.target.value;
    this.equipment_family_id = (equipment_family_id == "0") ? 0 : equipment_family_id;
    //await this.fillDashboardData();
  }

  public async onChangeDepartmentAssignment(event) {
    let department_id = event.target.value;
    this.department_id = (department_id == "0") ? 0 : department_id;
    //await this.fillDashboardData();
  }

  public async onChangeDepartmentLocation(event) {
    let department_id = event.target.value;
    this.department_location_id = (department_id == "0") ? 0 : department_id;
    //await this.fillDashboardData();
  }

  private checkDatesValidity() {
    this.error = null;
    if (this.end_date < this.start_date) {
      this.error = this.translation_service.translate("Error") + " : " + this.translation_service.translate("Start date must be older or equal to end date");
      return false
    }
    if (this.end_date > this.current_date) {
      this.error = this.translation_service.translate("Error") + " : " + this.translation_service.translate("End date must be past or present");
      return false;
    }
    return true;
  }

  public async displayReport() {
    if (this.checkDatesValidity()) {
      this.resetLoadedBooleans();
      //this.unsubWidgets();
      this.pdf_loaded = this.pdf_loaded ? false : this.pdf_loaded;
      this.shown = true;
      await this.fillDashboardData(true);
    }
  }

  /**
   * Downloads the report in PDF format
   * */
  public async downloadReport() {
    let data = document.getElementById('report-page');
    let fileName = this.translation_service.translate("Report") + "_" + this.current_date.toLocaleDateString().replace("/", "_");
    await this.generatePdfFromImage(data, fileName);
  }

  /**
   * generates a PDF from an html source and download it
   * @param html the html source can be the html string or the HTMLElement
   * @param filename the filename without extension (.pdf will be added automaticly)
   */
  private generatePdfFromHtml(html: string | HTMLElement, filename: string) {
    let document = new jsPDF();
    document.html(html, {
      callback(doc) { doc.save(filename + ".pdf"); },
      x: 0,
      y: 0,
    });
  }

  private async generatePdfFromImage(html: HTMLElement, filename: string) {
    let document = new jsPDF('p', 'cm', 'a4');
    let canvas = await html2canvas(html);
    const contentDataURL = canvas.toDataURL('image/png');
    document.addImage(contentDataURL, 'PNG', 0, 0, 21.0, 29.7);
    document.save(filename + ".pdf");
  }

  private getAllLoaded() {
    for (let key of Object.keys(this.loaded_states)) {
      if (!this.loaded_states[key])
        return false;
    }
    this.unsubWidgets();
    return true;
  }

  private resetLoadedBooleans() {
    for (let key of Object.keys(this.loaded_states)) {
      this.loaded_states[key] = false;
    }
  }
}
import { Component, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonService } from '../../../services/common.service';
import { TranslationService } from '../../../services/translation.service';
import { AuthenticationService } from '../../../services/authentication.service';

import { BaseDashboardComponent } from '../base.dashboard.component';

import { DashboardCache } from '../DashboardCache';
import { DashboardCountersBatteryComponent } from './Components/dashboard-counters-battery.component';
import { DashboardCountersBatteryMonthsComponent } from './Components/dashboard-counters-battery-months.component';
import { navutils } from '../../../common/navutils';
import { logicutils } from '../../../common/logicutils';
import { StorageService } from '../../../services/storage.service';
import { StorageFields } from '../../../common/enums';


@Component({
  selector: 'app-dashboard-battery',
  templateUrl: './dashboard-battery.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardBatteryComponent extends BaseDashboardComponent {

  @ViewChild(DashboardCountersBatteryComponent) widget_counters: DashboardCountersBatteryComponent;
  @ViewChild(DashboardCountersBatteryMonthsComponent) widget_counters_months: DashboardCountersBatteryMonthsComponent;

  public initialized: boolean = false;
  public links: any[];
  public title: string;
  public error: string = "";
  private start_time: number;

  //private is_first_open: boolean = false;

  public current_date = Date.now();

  public equipment_families: any[];
  public departments: any[];

  public building_view: boolean = true;

  public show_widget_counters = true;


  constructor(
    protected router: Router,
    private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    protected translation_service: TranslationService,
    protected authentication_service: AuthenticationService,
    private storage_service: StorageService,
  ) {
    super(router, translation_service, authentication_service);

    // determinates the allowed widgets
    let dashboard_view = this.storage_service.getValue(StorageFields.DashboardView); //localStorage.getItem("dashboard_view");
    if (dashboard_view != undefined) {
      let dashboard_view_json = JSON.parse(dashboard_view);
      this.show_widget_counters = dashboard_view_json.show_widget_counters;
    }
    let main = document.getElementById("main");
    /*  main.style.backgroundColor = "#2B526F";*/
    main.style.height = "100%";
    main.style.padding = "0px";
    // this is needed to ensure the view is refreshed when change view mode
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's
   * data-bound properties for the first time, and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  async onInitialize() {
    //this.is_first_open = Utils.getBoolParam(this.route, "is_first_open");
    //this.is_first_open = false;

    // building_id is not used in this dashborad, but is kept to navigate in other dashboards
    this.building_id = navutils.getStringParam(this.route, 'building_id') || 0;

    let title = this.translation_service.translate(this.setTitle());

    this.links = [
      //{ label: 'Administration', path: '/workflow/work-administration' },
      { label: title, path: './' }
    ]
  }

  private setTitle() {
    this.title = "Battery monitoring";
    return this.title;
  }

  protected async onGetData() {
    await this.fillDashboardData(false);
  }

  /**
   * Fills the data to use for the widgets in the dashboard
   * */
  private async fillDashboardData(set_title: boolean = true) {
    this.start_time = Date.now();
    this.initialized = false;
    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError : Expression has changed after it was checked.
    this.changeDetectorRef.detectChanges();
    if (set_title)
      this.setTitle();
    else
      this.error = "";
    try {
      let args = {
      }
      let cache: DashboardCache = new DashboardCache();
      cache.addCache("1", this.widget_counters, true && this.show_widget_counters);
      cache.addCache("2", this.widget_counters_months, true && this.show_widget_counters);

      cache.loadData("1", args);
      logicutils.logDuration("Dashboard Consumer", this.start_time);
    } catch (e) {
      console.error(e)
      if (e.status == 501 || e.status == 504)
        this.error = this.translation_service.translate("Too much data");
      else
        this.error = "Error : " + e.message;
    } finally {
      this.initialized = true;
    }
  }

  public onSelect(event) {
    this.router.navigate(["/forms/sensors/list", { come_from: "dashboard_battery" }]);
  }
}

<div style="padding:8px;">

  <h2>Navigation</h2>

  <div><span class="msg-error">{{message}}</span></div>

  <div *ngIf="!find_mode">

    <div style="width:400px;">
      <app-select-editor label="Bâtiment" [records]="selected_buildings" [empty]="''" [(ngModel)]="current_building_id" (Changed)="OnBuildingChanged()"></app-select-editor>
      <app-select-editor label="Etages" [records]="selected_floors" [empty]="''" [(ngModel)]="current_floor_id" (Changed)="OnFloorChanged()"></app-select-editor>
    </div>

    <div style="display: flex;">

      <div *ngIf="selected_spots">
        <label>POIs</label>
        <table class="table compact" style="min-width: 100px; width:400px; margin:0px;">
          <tr>
            <th *ngIf="!current_building_id">Bâtiment</th>
            <th *ngIf="!current_floor_id">Etage</th>
            <th>Point</th>
          </tr>
          <tr *ngFor="let poi of selected_spots; let id = index" (click)="OnChangePoi(id)" [ngClass]="id==current_spot_index ? 'selected' : ''">
            <td *ngIf="!current_building_id">{{poi.building_name}}</td>
            <td *ngIf="!current_floor_id">{{poi.floor_name}}</td>
            <td>{{poi.name}}</td>
          </tr>
        </table>
      </div>

      <div style="width:400px; margin-left:32px;">
        <label>Trajet</label>
        <table class="table compact" style="min-width: 100px; width:400px; margin:0px;">
          <tr>
            <th></th>
            <th>Bâtiment</th>
            <th>Etage</th>
            <th>Point</th>
            <th></th>
          </tr>
          <tr>
            <th>Départ :</th>
            <td style="font-weight:bold">{{start_poi?.building_name}}</td>
            <td style="font-weight:bold">{{start_poi?.floor_name}}</td>
            <td style="font-weight:bold">{{start_poi?.name}}</td>
            <td><mat-icon (click)="start_poi = null" class="red-icon">delete</mat-icon></td>
          </tr>
          <tr>
            <th>Destination :</th>
            <td style="font-weight:bold">{{target_poi?.building_name}}</td>
            <td style="font-weight:bold">{{target_poi?.floor_name}}</td>
            <td style="font-weight:bold">{{target_poi?.name}}</td>
            <td><mat-icon (click)="target_poi = null" class="red-icon">delete</mat-icon></td>
          </tr>
          <tr>
            <th>Recherche :</th>
            <td colspan="3">
              <button style="margin-left:60px" (click)="OnFind()" [disabled]="!start_poi || !target_poi">Rechercher</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div [ngStyle]="{ 'display': find_mode ? 'flex' : 'none' }">
    <div class="panel" style="width:400px;margin-right:16px;">
      <table class="table compact" style="min-width: 300px; width:100%; margin:0px; font-size:14px;">
        <tr *ngFor="let message of route_message; let id = index" (click)="OnChangePoint(id)" [ngClass]="id==current_point_index ? 'selected' : ''">
          <td style="font-size: 14px;">  <i class="fas fa-{{message.icon}}"></i></td>
          <td style="font-size: 14px;">{{message.message}}</td>
        </tr>
      </table>
      <button (click)="OnResetFind()" [disabled]="!start_poi || !target_poi">Recommencer</button>
    </div>
    <div class="panel" style="width:400px;margin-right:16px;">
      <app-plan #plan2></app-plan>
      <div class="msg info">{{label}}</div>
      <div>
        <button (click)="OnPrevPoint()" [disabled]="!start_poi || !target_poi">Précédent</button>
        <button (click)="OnNextPoint()" [disabled]="!start_poi || !target_poi">Suivant</button>
      </div>
    </div>
    <!--<div class="panel" style="width: 400px; margin-right: 16px;">
      <app-plan #plan4></app-plan>
      <div class="msg info">{{label}}</div>
    </div>-->
    <div class="panel" style="width:400px;margin-right:16px;">
      <app-plan #plan></app-plan>
      <div class="msg info">{{label}}</div>
    </div>
    <div class="panel" style="width:400px;margin-right:16px;">
      <app-plan #plan3></app-plan>
      <div class="msg info">{{label}}</div>
    </div>
  </div>
</div>

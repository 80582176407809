import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class EquipmentService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "equipment";

  /**
   * Initialize a new intance of EquipmentService
   * @param http
   * @param token
   */
  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * Gets all the eqipments of the project
   * */
  getEquipments() {
    return this.getData("getEquipments", {}, (response) => { return response.data; });
  }

  getEquipmentsForConsumers() {
    return this.getData("getEquipmentsForConsumers", {}, (response) => { return response.data; });
  }

  /**
   * Gets the equipment matching with the given id
   * @param equipmentId
   */
  getEquipment(equipmentId: string) {
    return this.getData("getEquipment", { equipmentId: equipmentId }, (response) => { return response.data; });
  }

  /**
  * Gets the equipment including the equipment kind matching with the given id
  * @param equipmentId
  */
  getEquipmentWithEquipmentKind(equipmentId: string) {
    return this.getData("getEquipmentWithEquipmentKind", { equipmentId: equipmentId }, (response) => { return response.data; });
  }

  getEquipmentsForConsumer(consumer_id: string) {
    return this.getData("getEquipmentsForConsumer", { consumer_id: consumer_id }, (response) => { return response.data; });
  }

  /**
   * Adds a new equipment to the database
   * @param equipment
   */
  addEquipment(equipment: any, subscribeMethod) {
    return this.updData("addEquipment", { equipment: equipment }, subscribeMethod);
  }

  /**
  * Adds a new equipment and attach it to the sensor matching with the given id.
  * USED BY THE MOBILE APPLICATION
  * @param equipment the json document containing the data of the equipment to add
  * @param sensor_id the id of the sensor to attach to the new equipment.
  **/
  addEquipmentAndAttach(equipment: any, sensor_id: string, subscribeMethod) {
    return this.updData("addEquipmentAndAttach", { equipment: equipment, sensor_id: sensor_id }, subscribeMethod);
  }

  /**
   * Updates the given equipment to the database
   * @param equipment
   */
  updEquipment(equipment: any, subscribeMethod) {
    return this.updData("updEquipment", { equipment: equipment }, subscribeMethod);
  }

  /**
   * Reserves the given equipment for the duration specified in the equipment kind
   * @param equipment
   */
  reserveEquipment(equipment_id: string, subscribeMethod) {
    return this.updData("reserveEquipment", { equipment_id: equipment_id }, subscribeMethod);
  }

  maintenanceEquipment(equipment_id: string, subscribeMethod) {
    return this.updData("maintenanceEquipment", { equipment_id: equipment_id }, subscribeMethod);
  }

  /**
   * Deletyes the equipment matching with the given id
   * @param equipmentId
   */
  delEquipment(equipmentId: string, subscribeMethod) {
    return this.updData("delEquipment", { equipmentId: equipmentId }, subscribeMethod);
  }

  /**
   * Updates the equipments in the database
   * @param equipments
   */
  updEquipments(equipments: any, subscribeMethod) {
    return this.updData("updEquipments", { equipments: equipments }, subscribeMethod);
  }

  /**
  * Updates the equipments custom fields in the database
  * @param equipments
  */
  updEquipmentCustoms(equipment_kind_id: string, equipments: any, subscribeMethod) {
    return this.updData("updEquipmentCustoms", { equipment_kind_id: equipment_kind_id, equipments: equipments }, subscribeMethod);
  }

  /**
  * Checks if the equipment name already exists.
  * @param name the equipment name to check.
  */
  checkNameExist(id: string, name: string) {
    return this.getData("checkNameExist", { id: id, name: name }, (response) => { return response.data; });
  }
}





<div>

  <table mat-table [dataSource]="data_source" matSort>
    <!-- Sensor Name Column -->
    <ng-container matColumnDef="sensor_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Sensor' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">
        <!--<img *ngIf="row.sensor_image" class="list-icon" [src]="row.sensor_image | safe: 'img'" />-->{{row.sensor_name}}
      </td>
    </ng-container>

    <!-- Sensor State Column -->
    <ng-container matColumnDef="sensor_state_label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'State' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.sensor_state_label}}</td>
    </ng-container>

    <!-- Equipment Name Column -->
    <ng-container matColumnDef="equipment_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ '$Equipment$' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">
        <!--<img *ngIf="row.equipment_image" class="list-icon" [src]="row.equipment_image | safe: 'img'" />-->{{row.equipment_name}}
      </td>
    </ng-container>

    <!-- Equipment Kind Name Column -->
    <ng-container matColumnDef="equipment_kind_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ '$Equipment kind$' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.equipment_kind_name}}</td>
    </ng-container>

    <ng-container matColumnDef="equipment_department_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Assignment' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.equipment_department_name}}</td>
    </ng-container>

    <!-- Equipment Status Column -->
    <ng-container matColumnDef="equipment_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ '$Equipment$ status' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color"> <div [style.backgroundColor]=row.equipment_status_color class="image-circle"></div>{{row.equipment_status}}</td>
    </ng-container>

    <!-- Building Name Column -->
    <ng-container matColumnDef="building_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px !important;">{{ 'Building' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.building_name}}</td>
    </ng-container>


    <!-- Floor Name Column -->
    <ng-container matColumnDef="floor_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ '$Floor$' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.floor_name}}</td>
    </ng-container>

    <!-- Department Name Column -->
    <ng-container matColumnDef="department_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Department' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.department_name}}</td>
    </ng-container>

    <!-- Room Name Column -->
    <ng-container matColumnDef="room_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Next to' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.room_name}}</td>
    </ng-container>

    <!-- Sensor Last Update Column -->
    <ng-container matColumnDef="sensor_last_update">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Update' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color">{{row.sensor_last_update | date:'dd/MM/yyyy HH:mm:ss'}}</td>
    </ng-container>

    <!-- Sensor Last Update Column -->
    <ng-container matColumnDef="precision_text">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Precision' | locale }}</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.row_color"><div [style.backgroundColor]=row.precision_color class="image-circle"></div>{{row.precision_text}}</td>
    </ng-container>


    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center; padding-left: 10px !important;">{{ 'Actions' | locale }}</th>
      <td mat-cell *matCellDef="let row" style="width: 120px; text-align: center">
        <app-list-button kind="Ctm" (click)="onNavigatedLocation(row)" icon="map-marker" tooltip="Locate" *ngIf="rights['right_location']"></app-list-button>
        <app-list-button kind="Ctm" (click)="onNavigatedHistory(row)" icon="history" tooltip="Show the history" *ngIf="rights['right_history']"></app-list-button>
      </td>
    </ng-container>

    <!-- Header row first group -->
    <ng-container matColumnDef="header-row-first-group">
      <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="header1_cols" style="border-right: 1px solid #F2F7FD !important; font-weight: bold;">
        {{ '$Equipments$' | locale }}
      </th>
    </ng-container>

    <!-- Header row second group -->
    <ng-container matColumnDef="header-row-second-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="header2_cols" style="padding-left: 10px !important; border-right: 1px solid #F2F7FD !important; font-weight: bold;"> {{ 'Location' | locale }}  </th>
    </ng-container>

    <!-- Header row third group -->
    <ng-container matColumnDef="header-row-third-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="padding-left: 10px !important; font-weight: bold;"></th>
    </ng-container>

    <tr mat-header-row style="background-color: #E2E7ED;" *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"></tr>
    <tr mat-header-row *matHeaderRowDef="displayed_columns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayed_columns;"></tr>

  </table>

  <mat-paginator [showFirstLastButtons]=true [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

</div>
import { Component, OnInit, Input, Output, Injectable, OnChanges, SimpleChange } from "@angular/core";


@Component({
  selector: "app-form-error",
  templateUrl: './form-error.component.html',
  styles: []
})
export class FormErrorComponent implements OnInit {

  @Input() field: any;
  @Input() submitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
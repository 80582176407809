import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Point } from '../../models/types';
import { PlanComponent } from './plan.component';
import { ZoomPoint } from './PlanPoint';


@Component({
  selector: 'app-plan-command',
  templateUrl: './plan-command.component.html',
})
export class PlanCommandComponent implements OnInit, AfterViewInit {

  @ViewChild("plan_thumb_canvas", { static: true }) public canvas!: ElementRef<HTMLCanvasElement>;

  private context: CanvasRenderingContext2D;
  protected plan: PlanComponent | undefined;

  public can_zoom: boolean = true;
  public no_image: boolean = false;
  public show_tool_bar: boolean = false;

  private move_point_thumb: Point;


  constructor() {
  }

  public get ThumbCanvas(): HTMLCanvasElement {
    return this.canvas.nativeElement;
  }

  public get ThumbContext(): CanvasRenderingContext2D | null {
    return this.context;
  }

  async ngOnInit(): Promise<void> {
  }

  async ngAfterViewInit() {
    let context = this.ThumbCanvas.getContext('2d');
    if (context)
      this.context = context;
  }

  public Initialize(plan: PlanComponent) {
    this.plan = plan;
  }

  public toggleToolBar(show: boolean) {
    this.show_tool_bar = show;
  }

  public doZoom(grow: boolean) {
    this.plan?.ChangeZoom(grow);
  }

  public doShift(direction: number) {
    this.plan?.ChangeShift(direction);
  }

  public RefreshThumb() {
    if (!this.plan)
      return;
    if (!this.can_zoom)
      return;
    let main_canvas: HTMLCanvasElement = this.plan.Canvas;
    let thumb_canvas: HTMLCanvasElement = this.ThumbCanvas;
    if (!thumb_canvas || !main_canvas)
      return;

    thumb_canvas.height = 64;
    thumb_canvas.width = main_canvas.width / main_canvas.height * thumb_canvas.height;
    let main_thumb_ratio = main_canvas.height / thumb_canvas.height;

    let zoom_ratio: number = this.plan.layout.zoom_ratio;
    let zoom_shift: ZoomPoint = this.plan.layout.zoom_shift;
    var context = this.ThumbContext;
    if (!context)
      return;
    context.beginPath();
    context.drawImage(this.plan.Image, 0, 0, (thumb_canvas.width), (thumb_canvas.height));
    context.lineCap = 'round';
    context.rect(-zoom_shift.X / main_thumb_ratio / zoom_ratio, -zoom_shift.Y / main_thumb_ratio / zoom_ratio - 1, thumb_canvas.width / zoom_ratio + 1, thumb_canvas.height / zoom_ratio + 2);

    context.lineWidth = 1;
    context.strokeStyle = "#2D6C9B";
    context.stroke();
  }

  public onMouseDownThumb(event: any) {
    if (!this.can_zoom)
      return;
    this.move_point_thumb = new Point(event.clientX, event.clientY);
  }

  public onMouseUpThumb(event: any) {
    if (!this.plan)
      return;
    if (!this.can_zoom)
      return;
    if (event.button != 0)
      return;
    let main_canvas: HTMLCanvasElement = this.plan.Canvas;
    let zoom_canvas: HTMLCanvasElement = this.ThumbCanvas;
    if (!zoom_canvas || !main_canvas)
      return;
    zoom_canvas.height = 64;
    zoom_canvas.width = main_canvas.width / main_canvas.height * zoom_canvas.height;
    let main_thumb_ratio = main_canvas.height / zoom_canvas.height;
    let zoom_ratio: number = this.plan.layout.zoom_ratio;
    let shift = new Point((event.clientX - this.move_point_thumb.x), (event.clientY - this.move_point_thumb.y));
    let move_shift = new ZoomPoint(shift.x * main_thumb_ratio, shift.y * main_thumb_ratio);
    // move the plan to the cursor point
    this.plan.layout.SetZoom(this.plan.layout.zoom_shift.X - move_shift.X * zoom_ratio, this.plan.layout.zoom_shift.Y - move_shift.Y * zoom_ratio);
    this.plan.Redraw();
  }

  public onWheelThumb(event: any) {
    if (!this.plan)
      return;
    if (!this.can_zoom)
      return;
    let main_canvas: HTMLCanvasElement = this.plan.Canvas;
    let zoom_canvas: HTMLCanvasElement = this.ThumbCanvas;
    if (!zoom_canvas || !main_canvas)
      return;
    event.preventDefault();
    event.stopPropagation();
    let grow = event.wheelDelta > 0;
    let main_thumb_ratio = main_canvas.height / zoom_canvas.height;
    let current_shift = new ZoomPoint(event.offsetX * main_thumb_ratio, event.offsetY * main_thumb_ratio);
    this.plan.layout.UpdateZoom(grow, current_shift);
    this.plan.Redraw();
  }

}

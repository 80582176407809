import { Component, Input } from "@angular/core";
import { FormEditorBase } from './FormEditorBase';


/**
 * Input editor to use in forms to bind values and manage validation.
 * @code  <app-form-select [field]="form.district_data" [list]="districts" id="_id" text="name" [submitted]="submitted"></app-form-select>
 * */
@Component({
  selector: "app-form-select",
  templateUrl: './form-select.component.html',
  styles: []
})
export class FormSelectComponent extends FormEditorBase {

  /**
   * The list used to fill the select options,
   * each record must contain a field for the id and a field for the text
   */
  @Input() list: any[];

  /*
   * The name of the field to use for the value (id)
   * @default "_id"
   * */
  @Input() id: string = "_id";

  /*
   * The name of the field to use for the text
   * @default "name"
   * */
  @Input() text: string = "name";

  constructor() {
    super();
  }

  getValue(record) {
    return record[this.id];
  }

  getText(record) {
    return record[this.text];
  }
}
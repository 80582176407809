
<div class="login-body">

  <img src="assets/img/login/background.jpg" class="login-img-background" alt="">

  <div class="login-container animated fadeInDown">
    <div class="login-row row">
      <div class="col-md-6 login-col-left">
        <img src="assets/img/login/left-col.svg" alt="">
      </div>
      <div class="col-md-6 login-col-right">
        <form role="form" [formGroup]="form_group">
          <div class="login-logo">
            <img src="assets/img/login/logo.png" alt="">
          </div>

          <p class="login-text">{{ 'Connect with your account' | translate }}</p>

          <div class="login-input-username">
            <img src="assets/img/login/username.png" alt="" class="login-input-icon">
            <input type="text" class="login-input" id="user_name" formControlName="username" placeholder="{{ 'Identifier' | translate }}" autocomplete="username" required>
          </div>
          <div class="login-pwd-username">
            <img src="assets/img/login/password.png" alt="" class="login-input-icon">
            <input type="password" class="login-input" id="password" formControlName="password" placeholder="{{ 'Password' | translate }}" autocomplete="current-password" required>
          </div>

          <div style="display: flex;">
            <div style="float:left"><input type="checkbox" class="form-control" formControlName="remember_me"></div>
            <div style="float:left; color:#fff; font-size:14px; margin-left:12px;">{{ 'Remember me' | translate }}</div>
          </div>

          <div *ngIf="enable_ad" style="display: flex; padding-top: 20px;">
            <div style="float:left"><input type="checkbox" class="form-control" formControlName="use_ad"></div>
            <div style="float:left; color:#fff; font-size:14px; margin-left:12px;">{{ 'Use active directory' | translate }}</div>
          </div>

          <button type="submit" id="login_button" (click)="login()" class="btn-rounded login-btn-submit">{{ 'Submit' | translate }}</button>
          <button class="btn login-link-forgot" id="forgot_password_button" (click)="forgotPassword()">{{ 'Forgot password ?' | translate }}</button>

          <div class="login-div-error">
            <em id="error_message" class="login-i-error"> {{ error | translate }}</em>
          </div>

        </form>
      </div>
    </div>
  </div>

</div>
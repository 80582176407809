import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { SettingsService } from './settings.service';
import { IFrontSettings } from '../models/front-settings-interface';
import { ICompanySettings } from '../models/company-settings-interface';
import { IBackSettings } from '../models/back-settings-interface';
import { StorageService } from './storage.service';
import { StorageFields } from '../common/enums';


const delay = ms => new Promise(res => setTimeout(res, ms));


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  userUrl = AppConfig.api_url + '/user';
  authenticationUrl = AppConfig.api_url + '/authentication';
  token;

  constructor(private http: HttpClient,
    private storage_service: StorageService,
    private settingsService: SettingsService) { }

  /**
   * Post username and password to API and return response
   * if a user is returned put it in localStorage
   * @param username username of user to fetch
   * @param password password of user to fetch
   */
  public async login(username: string, password: string, use_ad: boolean, remember_me: boolean) {

    let data = await this.http.post<any>(this.authenticationUrl + '/login', { username: username, password: password, use_ad: use_ad, iss: "webapp", origin: location.origin, remember_me: remember_me }).toPromise()
    this.storage_service.initialize(remember_me, data);
    if (data.user !== null) {
      // front settings
      let front_settings = await this.settingsService.getSetting("Front Settings").toPromise();
      AppConfig.front_settings = <IFrontSettings>this.getSettings(front_settings, AppConfig.getDefaultFrontSettings(), data.user.rights);
      this.storage_service.setValue(StorageFields.FrontSettings, JSON.stringify(AppConfig.front_settings));

      // company settings
      let company_settings = await this.settingsService.getSetting("Company Settings").toPromise();
      AppConfig.company_settings = <ICompanySettings>this.getSettings(company_settings, AppConfig.getDefaultCompanySettings(), data.user.rights);
      this.storage_service.setValue(StorageFields.CompanySettings, JSON.stringify(AppConfig.company_settings));

      // back settings
      let back_settings = await this.settingsService.getSetting("Back Settings").toPromise();
      AppConfig.back_settings = <IBackSettings>this.getSettings(back_settings, AppConfig.getDefaultBackSettings(), data.user.rights);
      this.storage_service.setValue(StorageFields.BackSettings, JSON.stringify(AppConfig.back_settings));
    }
    return data;
  }

  private getSettings(settings: any, default_settings: any, rights) {
    if (settings == null) {
      settings = { data: {} };
    }
    let change = this.ensureSettings(settings.data, default_settings.data);
    if (change && rights['right_supervisor'])
      this.settingsService.updSetting(settings, null);
    return settings.data;
  }

  private ensureSettings(settings, defaults) {
    let change = false;
    if (typeof defaults === 'string' || defaults instanceof String)
      return false;
    for (var key of Object.keys(defaults)) {
      if (!(key in settings)) {
        change = true;
        // if not exist adds the default values
        settings[key] = defaults[key];
      } else {
        // continue recursivly
        change = change || this.ensureSettings(settings[key], defaults[key])
      }
    }
    return change;
  }

  /**
   * Destroy user in localStorage
   */
  public logout() {
    this.storage_service.reset();
  }

  /**
   * To reset a user's password with his username and mail
   * @param username
   * @param mail
   */
  forgotPassword(mail: string, lang: string) {
    return this.http.post<any>(this.authenticationUrl + '/init', { mail: mail, lang: lang.toLowerCase() }).pipe(map(data => {
      return data.status === 200;
    }));
  }

  getUserId(): string {
    return this.storage_service.getValue(StorageFields.CurrentUserId);
  }

  getUserRights(): object {
    return JSON.parse(this.storage_service.getValue(StorageFields.CurrentUserRights));
  }

  public isInstaller(): boolean {
    return this.storage_service.getValue(StorageFields.CurrentUserIsInstaller) == "true";
  }

  public hasRight(right: string): boolean {
    let rights = this.getUserRights();
    return (rights[right]) ? true : false;
  }
}
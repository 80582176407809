
<div class="dialog-header">
  <app-page-title title="{{title}}" mode="popup_mode"></app-page-title>
</div>

<div class="dialog-body">

  <form [formGroup]="editForm">

    <input type="hidden" formControlName="_id">
    <input type="hidden" formControlName="reservation_date">

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ '$Equipment kind$' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-select [field]="form.equipment_kind_data" [list]="equipment_kinds" id="_id" text="name" (change)="onKindChange($event)" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Department' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-select [field]="form.department_data" [list]="departments" id="_id" text="name" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Consumer' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-select [field]="form.consumer_data" [list]="consumers" id="_id" text="identifier" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Name' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-input [field]="form.name" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Status' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-select [field]="form.status" [list]="statuses" id="key" text="name" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Activation date' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-date [field]="form.activation_date" no_time="true" [submitted]="submitted"></app-form-date>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Maintenance date' | locale }} :</label></div>
        <div class="col-md-5">
          <app-form-date [field]="form.maintenance_date" no_time="true" [submitted]="submitted"></app-form-date>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Maintenance' | locale }} :</label></div>
        <div class="col-md-5">
          <button class="btn-rounded btn-add" style="width:220px;" (click)="addMaintenance()">{{ 'Add maintenance' | locale }}</button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Maintenance history' | locale }} :</label></div>
        <div class="col-md-5">

          <app-panel title="{{ 'Maintenances' | locale }}" [opened]="false">
            <table class="table compact debug" style="width:100%;">
              <tbody>
                <tr align="center">
                  <th width="80px">{{'Date' | locale }}</th>
                  <th width="200px">{{'Comment' | locale }}</th>
                  <th width="80px">{{'Actions' | locale }}</th>
                </tr>
                <tr *ngFor="let maintenance of maintenances">
                  <td>{{ maintenance.maintenance_date | appdate }}</td>
                  <td>{{ maintenance.comment }}</td>
                  <td style="text-align: center">
                    <app-list-button kind="Upd" (click)="updMaintenance(maintenance._id)"></app-list-button>
                    <app-list-button kind="Del" (click)="delMaintenance(maintenance._id)"></app-list-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </app-panel>

        </div>
      </div>
    </div>

    <div *ngIf="customConfig">
      <dynamic-form [fields]="customConfig"></dynamic-form>
    </div>

    <div class="form-group" *ngIf="!is_create">
      <div class="row row-center">
        <div class="col-md-8"><label>{{ 'Usage rate' | locale }} :</label></div>
      </div>
      <div class="row row-center">
        <div class="col-md-8 dashboard">
          <app-dashboard-usage-rate #chart></app-dashboard-usage-rate>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="rights['right_supervisor']">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Attached sensors' | locale }} :</label></div>
        <div class="col-md-5">
          <input class="form-control" readonly value="{{attached_sensor_count}}">
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="rights['right_supervisor']">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Equipment histories' | locale }} :</label></div>
        <div class="col-md-5">
          <input class="form-control" readonly value="{{equipment_histories_count}}">
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="rights['right_supervisor']">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Maintenance histories' | locale }} :</label></div>
        <div class="col-md-5">
          <input class="form-control" readonly value="{{equipment_maintenances_count}}">
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="rights['right_supervisor']">
      <div class="row row-center">
        <div class="col-md-3"><label>{{ 'Clear' | locale }} :</label></div>
        <div class="col-md-5">
          <app-panel title="{{ 'Advanced (Use with Care)' | locale }}" [opened]="false">
            <div class="legend" style="height:20px; padding:4px; color:darkred">{{'Warning : this will definitely deletes equipment history' | locale}}</div>
            <app-form-button (click)="delEquipmentHistories()" text="Clear Equipment History" icon="trash" type="warn" width="200"></app-form-button>
            <div class="legend" style="height:20px; padding:4px; color:darkred">{{'Warning : this will definitely deletes maintenance history' | locale}}</div>
            <app-form-button (click)="delMaintenanceHistories()" text="Clear Maintenance History" icon="trash" type="warn" width="200"></app-form-button>
          </app-panel>
        </div>
      </div>
    </div>

  </form>

</div>

<div class="dialog-footer">
  <button class="btn-rounded btn-add" (click)="onSubmit()">{{ 'Save' | locale }}</button>
  <button class="btn-rounded btn-close" (click)="close(false)">{{ 'Close' | locale }}</button>
</div>
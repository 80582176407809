import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from "@angular/forms";
import { DynamicFormComponent } from "../../../../common/entries/dynamic-form.component";
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardUsageRateComponent } from '../../../dashboard/dashboard/Components/dashboard-usage-rate.component';
import { MaintenanceUpdComponent } from './maintenance-upd.component';
import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';

import { CommonService } from '../../../../services/common.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { EquipmentHistoryService } from '../../../../services/equipment-history.service';
import { SensorService } from '../../../../services/sensor.service';
import { MaintenanceService } from '../../../../services/maintenance.service';
import { ConsumerService } from '../../../../services/consumer.service';

import { FieldType, FieldConfig } from '../../../../models/common';
import { navutils } from '../../../../common/navutils';
import { workutils } from '../../../../common/workutils';
import { logicutils } from '../../../../common/logicutils';


@Component({
  selector: 'app-equipment-upd',
  templateUrl: './equipment-upd.component.html',
})
export class EquipmentUpdComponent extends BaseCrudUpdComponent {

  public static openDialog(dialog: MatDialog, equipment_id: string, closed_callback) {
    navutils.openModalDialog(dialog, EquipmentUpdComponent, "60%", "730px", { equipment_id: equipment_id }, closed_callback);
  }

  @ViewChild(DynamicFormComponent) dynform: DynamicFormComponent;
  @ViewChild(DashboardUsageRateComponent) chart: DashboardUsageRateComponent;

  public fieldTypes = FieldType
  public fieldTypeKeys;
  public equipment_kinds: any[];
  public departments: any[];
  public statuses: any;
  public customConfig: FieldConfig[];

  public maintenances: any[] = [];
  public consumers: any[] = [];
  public attached_sensor_count;
  public equipment_histories_count;
  public equipment_maintenances_count;

  /**
   * Initialize a new instance of EquipmentUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router,
    protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private commonService: CommonService,
    private sensorService: SensorService,
    private equipmentService: EquipmentService,
    private consumer_service: ConsumerService,
    private maintenance_service: MaintenanceService,
    private equipmentHistoryService: EquipmentHistoryService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EquipmentUpdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { equipment_id: any; popup_mode: boolean; force_create: boolean },
  ) {
    super(router, route, formBuilder, authenticationService);
    this.fieldTypeKeys = Object.keys(this.fieldTypes).filter(k => !isNaN(Number(k))).map(v => Number(v));
  }

  protected getRecordId(): string {
    return this.data.equipment_id;
  }


  protected onGetTitle(): string {
    return '$Equipment$';
  }

  /**
   * Initialize the form model
   * */
  protected async initializeForm() {
    this.editForm = this.formBuilder.group({
      _id: [],
      equipment_kind_data: [null, Validators.required],
      department_data: null,
      consumer_data: null,
      name: ['', Validators.required],
      status: ['', Validators.required],
      activation_date: [],
      reservation_date: [{ value: null, disabled: true }],
      maintenance_date: [{ value: null, disabled: true }],
      customs: this.formBuilder.array([]),
    });
    if (!this.is_create)
      this.editForm.controls['consumer_data'].disable();
    this.consumers = await this.consumer_service.getConsumers().toPromise();
  }

  /**
   * Gets the equipmment for the required id
   * */
  protected async fillData(record_id: string) {
    // update path can accept a boolean force_create even if the id is given.
    let force_create = this.data.force_create;
    this.is_create = this.is_create || (force_create);

    let collections_names = ["buildings", "departments", "equipment_kinds"];

    await this.commonService.fillLookups(collections_names, null);
    this.equipment_kinds = this.commonService.getFormLookup("equipment_kinds", "name", null);
    this.departments = this.commonService.getFormLookup("departments", "name", null);
    if (!this.is_create) {

      let data = await this.equipmentService.getEquipmentWithEquipmentKind(record_id).toPromise();
      this.setFormValues(data);
      // fills the statuses
      this.fillCustomData(data);
      let maintenances = await this.maintenance_service.getMaintenances(record_id).toPromise();
      maintenances = maintenances.sort((a, b) => new Date(b.maintenance_date).getTime() - new Date(a.maintenance_date).getTime());
      this.maintenances = maintenances;
    } else if (force_create) {
      this.setFormValuesForCreate(record_id);
    }
    if (this.is_create)
      this.customConfig = [];
    if (this.record_id && !this.is_create) {
      await this.fillStatisticData();
      this.fillEquipmentInfos();
    }

    this.statuses = workutils.getAllowedStatuses(true, "key", "name");
  }

  private async fillStatisticData() {
    this.chart.initialize();
    this.chart.loadData({ "equipment_id": this.record_id })
  }

  private async fillEquipmentInfos() {
    this.attached_sensor_count = await this.sensorService.getAttachedSensorCount(this.record_id).toPromise();
    this.equipment_histories_count = await this.equipmentHistoryService.getEquipmentHistoryCount(this.record_id).toPromise();

    this.equipment_maintenances_count = await this.maintenance_service.getMaintenancesCount(this.record_id).toPromise();
  }

  private fillCustomData(data: any) {
    // fills the custom fields
    this.customConfig = [];
    if (data.customs == null)
      data.customs = {};
    var dataCustoms = data.customs;
    var customFields = data.equipment_kind_data.customs;
    for (var i = 0; i < customFields.length; i++) {
      var customField = customFields[i];
      if (customField.name in dataCustoms) {
        var value = dataCustoms[customField.name];
      } else {
        var value = null;
        data.customs[customField.name] = null;
      }
      var field = logicutils.getCustomField(customField.name, value, customField.values, customField.type);
      this.customConfig.push(field);
    }
  }

  /**
   * Fills the data of the equipment on the form
   * @param equipment
   */
  setFormValues(equipment: any) {
    this.editForm.patchValue({
      _id: equipment._id,
      equipment_kind_data: (equipment.equipment_kind_data == null) ? null : equipment.equipment_kind_data._id,
      department_data: equipment.department_data,
      consumer_data: equipment.consumer_data,
      name: equipment.name,
      status: equipment.status + "",
      activation_date: equipment.activation_date,
      maintenance_date: equipment.maintenance_date,
      reservation_date: equipment.reservation_date,
    });
  }

  setFormValuesForCreate(id: string) {
    this.editForm.patchValue({
      _id: id,
    });
  }

  /**
   * refills the statuses and custom fields when the equipment kind has changed
   * @param event
   */
  public onKindChange(event) {
    let selected_id = event.target.value.split(":")[1].trim();
    let equipment_kinds = this.commonService.getLookupCollection("equipment_kinds");
    let selected_equipmentKind = equipment_kinds.filter(k => k._id == selected_id);
    let customConfig = [];
    var customFields = selected_equipmentKind[0].customs;
    for (var i = 0; i < customFields.length; i++) {
      var customField = customFields[i];
      var field = logicutils.getCustomField(customField.name, null, customField.values, customField.type);
      customConfig.push(field);
    }
    this.customConfig = customConfig;
    this.dynform.updateControls(customConfig);
  }

  /**
   * Submit the form and saves the equipment in the database
   * */
  public async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid)
      return;

    let json = this.editForm.value;
    if (this.dynform != null) {
      let dyn = this.dynform.value;
      json["customs"] = dyn;
    }
    json["status"] = parseInt(json["status"]);
    let existName = await this.equipmentService.checkNameExist(this.form._id.value, this.form.name.value).toPromise();
    if (existName)
      return this.setError('name', 'used');
    if (this.is_create)
      this.equipmentService.addEquipment(json, null);
    else
      this.equipmentService.updEquipment(json, null);

    this.close(true);
  }

  public navigateMaintenance() {
    navutils.NavigateToMaintenanceList(this.router, null, null);
  }

  public returnList() {
    this.router.navigate(['forms/equipments/list']);
  }

  public close(response: boolean) {
    this.dialogRef.close(response);
  }

  public addMaintenance() {
    MaintenanceUpdComponent.openDialogMaintenance(this.dialog, null, this.record_id, () => this.fillData(this.record_id));
  }

  public updMaintenance(maintenance_id) {
    MaintenanceUpdComponent.openDialogMaintenance(this.dialog, maintenance_id, null, () => this.fillData(this.record_id));
  }

  public async delMaintenance(maintenance_id) {
    if (await navutils.confirmDelete(this.dialog))
      this.maintenance_service.delMaintenance(maintenance_id, this.record_id, (res) => this.fillData(this.record_id));
  }

  public async delEquipmentHistories(): Promise<void> {
    if (await navutils.confirmDelete(this.dialog))
      this.equipmentHistoryService.delEquipmentHistories(this.record_id, (res) => this.fillEquipmentInfos());
  };

  public async delMaintenanceHistories(): Promise<void> {
    if (await navutils.confirmDelete(this.dialog))
      this.maintenance_service.delMaintenanceHistories(this.record_id, (res) => this.fillData(this.record_id));
  }
}

<div class="dashboard" style="background-color:#2B526F; margin:0px;">

  <app-breadcrumb [links]="links"></app-breadcrumb>
  <app-page-title title="{{title}}" [initialized]="initialized" style="background-color:#2B526F"></app-page-title>

  <div *ngIf="rights" style="margin: 0px auto;" [ngStyle]="{'width':bar_width+'px' }">
    <button *ngIf="canEquipments()" id="equipments_button" class="btn-rounded btn-close" (click)="navigateDashboard()">{{'Equipments'|locale}}</button>
    <button *ngIf="canMaintenance()" id="maintenance_button" class="btn-rounded btn-add" (click)="navigateMaintenanceDashboard()">{{'Maintenance'|locale}}</button>
    <button *ngIf="canBattery()" id="battery_button" class="btn-rounded btn-add" (click)="navigateBatteryDashboard()">{{'Battery'|locale}}</button>
  </div>

  <div class="msg-error">{{error}}</div>

  <div class="row col-md-12 dashboard-container" style="display:inline-block;  margin-top:0px; width:100%; height:40px; color:white;">
    <div class="centered" style="max-width:1024px; margin-top:8px;">
      <div style="float:left;">
        <span style="padding-left: 10px; padding-right: 5px; color:white;">{{ '$Equipment family$' | locale }} :</span>
        <select id="equipment_family_id_select" class="search-select-single" [ngModel]="equipment_family_id" (change)="onChangeEquipmentFamily($event)" [attr.disabled]="initialized ? null : ''">
          <option *ngFor="let equipment_family of equipment_families" value={{equipment_family._id}}>{{equipment_family.name}}</option>
        </select>
      </div>
      <div style="float:left;">
        <span style="padding-left: 40px; padding-right: 5px; color:white;">{{ 'Assignment' | locale }} :</span>
        <select id="department_id_select" class="search-select-single" [ngModel]="department_id" (change)="onChangeDepartmentAssignment($event)" [attr.disabled]="initialized ? null : ''">
          <option *ngFor="let department of departments" value={{department._id}}>{{department.name}}</option>
        </select>
      </div>
      <div style="float:left;">
        <span style="padding-left: 40px; padding-right:5px; color:white;">{{ 'Location' | locale }} :</span>
        <select id="department_location_id_select" class="search-select-single" [ngModel]="department_location_id" (change)="onChangeDepartmentLocation($event)" [attr.disabled]="initialized ? null : ''">
          <option *ngFor="let department of departments" value={{department._id}}>{{department.name}}</option>
        </select>
      </div>
      <span class="current_date" style="color:white">{{ 'Update time' | locale }} : {{current_date | localizedDate:'medium'}}</span>
    </div>
  </div>

  <div *ngIf="show_widget_counters" class="row col-md-12 dashboard-container">
    <div class="row col-md-12 nopadding">
      <div class="centered" style="width: 1024px;">
        <app-dashboard-counters id="widget_counters" #widget_counters (Select)="onSelectStatus($event)"></app-dashboard-counters>
      </div>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="centered">
      <div class="row col-md-12 nopadding">
        <app-dashboard-availability *ngIf="building_view && show_widget_availability" id="widget_availability" #widget_availability></app-dashboard-availability>
        <app-dashboard-availability-status *ngIf="!building_view && show_widget_availability_status" id="widget_availability_status" #widget_availability_status (Select)="onSelectStatus($event)"></app-dashboard-availability-status>
      </div>
    </div>
  </div>

  <div class="row col-md-12 dashboard-container">
    <div class="centered" style="max-width:1024px;">
      <div class="row col-md-12 nopadding">
        <app-dashboard-usage-rate *ngIf="show_widget_usage_rate" id="widget_usage_rate" #widget_usage_rate (Select)="onSelectStatus($event)"></app-dashboard-usage-rate>
        <app-dashboard-distribution-kind *ngIf="show_widget_distribution_kind && building_view" id="widget_distribution_kind" #widget_distribution_kind (Select)="onSelectEquipmentFamily($event)"></app-dashboard-distribution-kind>
        <app-dashboard-distribution-status *ngIf="show_widget_distribution_status && !building_view" id="widget_distribution_status" #widget_distribution_status (Select)="onSelectStatus($event)"></app-dashboard-distribution-status>
        <app-dashboard-distribution-department *ngIf="show_widget_distribution_department" id="widget_distribution_department" #widget_distribution_department (Select)="onSelectDepartment($event)"></app-dashboard-distribution-department>
        <app-dashboard-sleeping-equipments *ngIf="show_widget_sleeping_equipments" id="widget_sleeping_equipments" #widget_sleeping_equipments (Select)="onSelectDepartmentSleeping($event)"></app-dashboard-sleeping-equipments>

        <app-dashboard-park-age *ngIf="show_widget_park_age" id="widget_park_age" #widget_park_age></app-dashboard-park-age>
        <app-dashboard-input-output-monitoring *ngIf="show_widget_input_output_monitoring" id="widget_input_output_monitoring" #widget_input_output_monitoring></app-dashboard-input-output-monitoring>
      </div>
    </div>
  </div>
</div>
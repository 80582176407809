import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import ObjectID from 'bson-objectid';

import { CoreService } from './core_service';

@Injectable({ providedIn: 'root' })
export class CommonService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "common";

  // lookup collections
  private lookups: any = {};

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
  * Gets all the collection names of disruptive database
  * @return a string array containig the collection names
  **/

  getCollectionNames() {
    return this.getData("getCollectionNames", {}, (response) => { return response.data; });
  }

  getSystemIfo() {
    return this.getData("getSystemIfo", {}, (response) => { return response.data; });
  }

  getProcesses() {
    return this.getData("getProcesses", {}, (response) => { return response.data; });
  }

  getVersions() {
    return this.getData("getVersions", {}, (response) => { return response.data; });
  }

  getDatabaseStats() {
    return this.getData("getDatabaseStats", {}, (response) => { return response.data; });
  }

  /**
  * Gets all the data matching with the filter int the given collection
  * @param (string) collection_name : the name of the collection to get
  * @param (any) filter : the filter to apply to the collection ex : {"_id" : current_id}
  * @return all the data matching with the filter int the given collection
  **/
  getCollection(collection_name: string, filter: any) {
    return this.getData("getCollection", { collection_name: collection_name, filter: filter }, (response) => { return response.data; });
  }

  /**
  * Gets all the data matching with the filter int the given collection
  * @param (string) collection_name : the name of the collection to get
  * @param (any) filter : the filter to apply to the collection ex : {"_id" : current_id}
  * @return csv file containing all the data matching with the filter in the given collection
  **/
  getCollectionCsvStream(collection_name: string, filter: any) {
    return this.getData("getCollectionCsvStream", { collection_name: collection_name, filter: filter }, (response) => { return response.data; });
  }

  /**
  * Gets the lookup collections to use in select or to search values
  * the images are not included in the results
  * @param {string[]} collections the names of the collections to fill
  * @param {string[]} building_id the id of the owner buiding to filter floors rooms and departments
  * - buildings - floors - departments - rooms - equipment_kinds - equipments
  * */
  getLookups(collections_names: string[], building_id: string, with_image: boolean = false) {
    return this.getData("getLookups", { collections_names: collections_names, building_id: building_id, with_image: with_image }, (response) => { return response.data; });
  }


  clearCollection(collection_name: string, subscription) {
    return this.updData("clearCollection", { collection_name: collection_name }, subscription);
  }

  updCollection(collection_name: string, data: any, is_json: boolean, subscription) {
    return this.updData("updCollection", { collection_name: collection_name, data: data, is_json: is_json ? 1 : 0 }, subscription);
  }

  getLog(filename) {
    const headers = this.token.buildBlobHeaders();
    const baseUrl = this.getUrl("getLog");
    let params = new HttpParams();
    params = params.set('filename', filename);
    this.http.post(baseUrl, {}, { headers: headers, params: params, responseType: 'blob' })
      .subscribe((resp: any) => {
        saveAs(resp, filename)
      });
  }

  getLogs() {
    return this.getData("getLogs", {}, (response) => { return response.data; });
  }

  public saveJson(json: any, filename: string) {
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
    saveAs(blob, filename)
  }

  /**
  * Gets the lookup collections to use in select or to search values
  * the images are not included in the results
  * @param {string[]} collections the names of the collections to fill
  * @param {string[]} building_id the id of the owner buiding to filter floors rooms and departments
  * - buildings - floors - departments - rooms - equipment_kinds - equipments
  * */
  public async fillLookups(collections_names: string[], building_id: string, with_image: boolean = false) {
    this.lookups = await this.getLookups(collections_names, building_id, with_image).toPromise();
  }

  /**
   * Get a lookup collections
   * @param collections_name the name of the collection to get
   */
  public getLookupCollection(collections_name: string) {
    return this.lookups[collections_name];
  }

  /**
   * Get the name of the specified record
   * @param collections_name the lookup collection containing the record
   * @param record_id the id of the record to find name for
   */
  public getLookupName(collections_name: string, record_id: ObjectID) {
    let record = this.lookups[collections_name].find(r => r._id == record_id);
    return record ? record["name"] : "";
  }

  /**
   * Gets the list of records to use in a select in a non form context (allow using second value for cascade selects)
   * the item will be formated like that : { id: 0, name: "" }
   * @param collections_name the name of the collection containing the records
   * @param name_column the name column containing the text of the entries of the select
   * @param first_item optionnaly add a first item such as "All" which id will be 0
   * @param second_column optionnaly add a second column containing other fields of the entries of the select
   */
  public getSelectLookup(collections_name: string, name_column: string = "name", first_item: string = null, second_column: string = null, image_column = null): any[] {
    let selects = [];
    if (first_item) {
      let select = { _id: 0, name: first_item };
      if (second_column)
        select["second"] = null;
      if (image_column)
        select["icon"] = null;
      selects.push(select);
    }
    this.lookups[collections_name].sort((a, b) => a.name > b.name ? 1 : -1);
    for (let record of this.lookups[collections_name]) {
      let select = { _id: record["_id"], name: record[name_column] }
      if (second_column)
        select["second"] = record[second_column];
      if (image_column)
        select["icon"] = record[image_column];
      selects.push(select);
    }
    return selects;
  }

  /**
  * Gets the list of records to use in a select of a form
  * the item will be formated like that : { id: null, name: "" }
  * @param collections_name the name of the collection containing the records
  * @param name_column the name column containing the text of the entries of the select
  * @param first_item optionnaly add a first item such as "All" which id will be null
  */
  public getFormLookup(collections_name: string, name_column: string = "name", first_item: string = null, first_item_data: any = null): any[] {
    let selects = [];
    if (first_item) {
      let select = { _id: first_item_data, name: first_item };
      selects.push(select);
    }
    this.lookups[collections_name].sort((a, b) => a.name > b.name ? 1 : -1);
    for (let record of this.lookups[collections_name]) {
      let select = { _id: record["_id"], name: record[name_column] }
      selects.push(select);
    }
    return selects;
  }
}
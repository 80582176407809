import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateutils } from "../dateutils";
import { DateKind } from "../enums";
import { EditorBase } from "./EditorBase";

/**
 * @example <app-datetime-editor label="From" [datekind]="datekind" [(ngModel)]="date_from" width="100px" disabled></app-datetime-editor>
 */
@Component({
  selector: 'app-datetime-editor',
  templateUrl: 'datetime.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeEditor),
      multi: true
    }
  ]
})
export class DateTimeEditor extends EditorBase<Date>{

  @Input() datekind: DateKind = DateKind.Datetime;

  protected years: number[] = [];
  protected months: any[] = [];

  get year() {
    return this.value?.getFullYear();
  }
  @Input() set year(val) {
    let month = (this.datekind == DateKind.Month) ? this.value?.getMonth() || 0 : 0;
    this.value = new Date(val || 2010, month, 1);
  }

  get month() {
    return (this.value?.getMonth() || 0) + 1;
  }
  @Input() set month(val) {
    this.value = new Date(this.value?.getFullYear() || 2010, val - 1, 1);
  }

  get date() {
    return this.value;
  }
  @Input() set date(val) {
    this.value = val ? dateutils.GetMidnight(val) : null;
  }

  constructor() {
    super();
    let current = new Date().getFullYear();
    for (var i = current; i >= 2010; i--)
      this.years.push(i);
    for (var i = 1; i <= 12; i++)
      this.months.push({ id: i, name: dateutils.GetMonthName(i - 1) });
  }

  protected GetDate(eventDate: string): Date {
    return new Date(eventDate);
  }
}

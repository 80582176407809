import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import ObjectID from "bson-objectid";

import { AppConfig } from "../../app.config";
import { ItemKinds, LocationModes, LocationModesText } from "../../models/common";
import { CommonService } from "../../services/common.service";
import { SettingsService } from "../../services/settings.service";
import { TranslationService } from "../../services/translation.service";
import { SensorUpdComponent } from "../../views/forms/sensor/sensor-upd/sensor-upd.component";
import { RedirectKind } from "../enums";
import { logicutils } from "../logicutils";
import { navutils } from "../navutils";
import { BaseWorkComponent } from "./base.work.component";
import { HistoryLocationComponent } from "./history-location.component";


@Component({
  selector: "app-filter-ribbon",
  templateUrl: './filter-ribbon.component.html',
})
export class FilterRibbonComponent extends BaseWorkComponent {

  @Output() onDateRangeApply = new EventEmitter();
  @Output() onDateRangePlay = new EventEmitter();
  @Output() onLocationModeChange = new EventEmitter();
  @Output() onFilterChange = new EventEmitter();
  @Output() onSelectEvent = new EventEmitter();

  @ViewChild(HistoryLocationComponent) list: HistoryLocationComponent;

  public disable_refresh = false;
  public isloading = true;

  public is_admin: boolean;
  public rights: any;

  public is_location_view: boolean;
  public start_date: Date;
  public end_date: Date;
  public location_modes: LocationModes[];
  public location_mode: LocationModes;

  public date_range = 1;//365;
  public show_states: boolean = false;
  public show_statuses: boolean = true;
  public show_customs: boolean = false;

  public current_event: any;

  public position_point: any;
  public collect_point: any;

  get loading(): boolean { return this.isloading; }
  @Input() set loading(value: boolean) { this.isloading = value; }

  constructor(
    public dialog: MatDialog,
    protected router: Router,
    protected domSanitizer: DomSanitizer,
    protected translation_service: TranslationService,
    private common_service: CommonService,
    private setting_service: SettingsService,
  ) {
    super(router, domSanitizer, translation_service);
  }

  ngOnInit() {
    this.location_modes = logicutils.getEnumNames(LocationModes, LocationModesText);
    this.location_mode = LocationModes.Sensors;
    if (AppConfig.front_settings && AppConfig.front_settings.views.default_location_mode)
      this.location_mode = AppConfig.front_settings.views.default_location_mode;
    this.onLocationModeChange.emit({ "location_mode": this.location_mode });
  }

  public initialize(is_admin: boolean, rights: any, is_location_view: boolean, item_kind: ItemKinds) {
    this.is_location_view = is_location_view;
    this.item_kind = item_kind;
    this.is_admin = is_admin;
    this.rights = rights;
    this.list.initialize(this.is_admin, this.rights);
    this.resetDateRange();
    this.filterChanged();
  }

  public setEvent(current_event: any) {
    this.current_event = current_event;
  }

  // User events and routing
  // ***********************

  public filterChanged() {
    this.onFilterChange.emit({ "show_states": this.show_states, "show_statuses": this.show_statuses, "show_customs": this.show_customs });
  }

  public navigateSensor() {
    SensorUpdComponent.openDialog(this.dialog, this.current_event.sensor_id, () => { });
  }

  public navigateLocation() {
    navutils.NavigateToRedirect(this.router, RedirectKind.LocationFromSensorCloudId, this.current_event.sensor_cloud_id);
  }

  // Date range methods
  // ***********************

  public resetDateRange() {
    var today = new Date();
    var minDate = new Date();
    minDate.setDate(today.getDate() - this.date_range);
    minDate.setHours(today.getHours());
    this.start_date = minDate;
    this.end_date = today;
    this.fillHistory();
  }

  public fillHistory() {
    this.onDateRangeApply.emit({ "start_date": this.start_date, "end_date": this.end_date });
  }

  public playHistory() {
    this.onDateRangePlay.emit({ "start_date": this.start_date, "end_date": this.end_date });
  }

  public setDisabledRefresh(disable_refresh: boolean) {
    this.disable_refresh = disable_refresh;
  }

  public addCollectPoint() {
    this.collect_point = {
      "collect_time": new Date(),
      "initial_collect": false,
      "floor_id": this.position_point.floor_id,
      "x": this.position_point.x,
      "y": this.position_point.y,
      "reception_level": this.current_event.reception_level,
      "events": []
    }
  }

  public updateCollectPoint() {
    if (!this.collect_point)
      return;
    this.collect_point["floor_id"] = this.position_point.floor_id;
    this.collect_point["x"] = this.position_point.x;
    this.collect_point["y"] = this.position_point.y;
    this.collect_point = this.collect_point;
  }

  public delCollectPoint() {
    this.collect_point = null;
  }

  public addCollectEvent() {
    if (!this.collect_point)
      return;
    for (let event of this.collect_point.events)
      if (event.update_time == this.current_event.sensor_last_update)
        navutils.openDialogMessage(this.dialog, "Collect Point", "An event with the same date has already been added.");
    let record = {
      "update_time": this.current_event.sensor_last_update,
      "gateways": []
    }
    for (let gateway of this.current_event.all_gateways) {
      let gtw = {
        "gateway_cloud_id": gateway.cloud_id,
        "rssi": gateway.rssi,
      };
      record.gateways.push(gtw);
    }
    this.collect_point.events.push(record);
  }

  public delCollectEvent() {
    this.collect_point.events.pop();
  }

  public saveCollectPoint() {
    this.collect_point["_id"] = new ObjectID();
    this.setting_service.addCollectPoint(this.collect_point);
    // saves the json in an array to a file
    this.common_service.saveJson([this.collect_point], "Export_calibration_data_finger_print.json");
    navutils.openDialogMessage(this.dialog, "Collect Point", "The new point has been added and saved in a file.");
    this.collect_point = null;
  }

  public selectEvent(event) {
    this.onSelectEvent.emit(event);
  }

  public setItems(events: any[]) {
    this.list.setItems(events);
  }
}
import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBase } from "./EditorBase";

/**
 * @example <app-select-editor label="Enregistrements" [records]="list" [empty]="''" [(ngModel)]="record_id" (Changed)="OnChanged()" width="100px" disabled></app-select-editor>
 */
@Component({
  selector: 'app-textarea-editor',
  templateUrl: 'textarea.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaEditor),
      multi: true
    }
  ]
})
export class TextAreaEditor extends EditorBase<string>{

  @Input()
  public height: string | null = null;

  constructor() {
    super();
  }
}



<div class="editor" [ngStyle]="{ 'width': width ? width : '' }">

  <div *ngIf="label" [ngStyle]="{ 'width': label_width ?? '' }">
    <label>{{label}} : </label>
  </div>

  <select type="text" [(ngModel)]="value"
          [disabled]="_disabled" [required]="required"
          [ngClass]="required_error ? 'invalid' : ''">
    <option *ngIf="empty || empty==''" [ngValue]=null>{{empty}}</option>
    <option *ngFor="let record of records" [ngValue]="record.id">{{ record.name }}</option>
  </select>
</div>

<div class="msg err" *ngIf="form?.submitted && required_error">Le champ {{label}} est obligatoire.</div>

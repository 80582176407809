import { Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateutils } from "../dateutils";
import { EditorBase } from "./EditorBase";

/**
 * @example <app-datetime-editor label="From" [datekind]="datekind" [(ngModel)]="date_from" width="100px" disabled></app-datetime-editor>
 */
@Component({
  selector: 'app-month-editor',
  templateUrl: 'month.editor.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthEditor),
      multi: true
    }
  ]
})
export class MonthEditor extends EditorBase<number>{

  protected months: any[] = [];

  constructor() {
    super();
    for (var i = 1; i <= 12; i++)
      this.months.push({ id: i, name: dateutils.GetMonthName(i - 1) });
  }
}
